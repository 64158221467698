import { useNavigate } from 'react-router-dom';
import Icons from "../../assets/images/wallet025-logo-grey.png"

export const FooterBar = () => {
  const navigate = useNavigate();

  return (
    <footer className="landing-footer-two">
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <img src={Icons} />
          <p>Wallet025 is on-the-fly fiat to crypto converter and vice versa, Wallet025 is non-custodial payment gateway backed by crypto, more than that Wallet025 is your passport to freedom.</p>
          <ul className="social-icon">
            <li><a href="#"><i className="icon ion-logo-facebook"></i></a></li>
            <li><a href="#"><i className="icon ion-logo-twitter"></i></a></li>
            <li><a href="#"><i className="icon ion-logo-linkedin"></i></a></li>
            <li><a href="#"><i className="icon ion-logo-pinterest"></i></a></li>
            <li><a href="#"><i className="icon ion-logo-github"></i></a></li>
          </ul>
        </div>
        <div className="col-md-2">
          <h3>Company</h3>
          <ul>
            <li><a href="#">About</a></li>
            <li><a href="#">Careers</a></li>
            <li><a href="#">Affiliates</a></li>
            <li><a href="#">Investors</a></li>
            <li><a href="#">Legal &amp; privacy</a></li>
            <li><a href="#">Cookie policy</a></li>
          </ul>
        </div>
        <div className="col-md-2">
          <h3>Individuals</h3>
          <ul>
            <li><a href="#">Buy &amp; sell</a></li>
            <li><a href="#">Earn free crypto</a></li>
            <li><a href="#">Wallet</a></li>
            <li><a href="#">Card</a></li>
            <li><a href="#">Payment methods</a></li>
            <li><a href="#">Account access</a></li>
          </ul>
        </div>
        <div className="col-md-2">
          <h3>Support</h3>
          <ul>
            <li><a href="#">Help center</a></li>
            <li><a href="#">Contact us</a></li>
            <li><a href="#">Create account</a></li>
            <li><a href="#">ID verification</a></li>
            <li><a href="#">Account information</a></li>
            <li><a href="#">Supported crypto</a></li>
          </ul>
        </div>
        <div className="col-md-2">
          <h3>Learn</h3>
          <ul>
            <li><a href="#">Browse crypto prices</a></li>
            <li><a href="#">Crypto basics</a></li>
            <li><a href="#">Tips &amp; tutorials</a></li>
            <li><a href="#">Market updates</a></li>
            <li><a href="#">How to send crypto</a></li>
            <li><a href="#">What is a blockchain?</a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
  );
};