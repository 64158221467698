import React, { useState, useEffect, useRef } from "react"; 
import { connect } from 'react-redux';

import { listApiKey, deleteApiKey, toggleApiKey, generateApiKey } from '../../redux/actions/developer';
import Switch from "react-switch";
import SvgIcon from "../../components/svgicon";

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

const ApikeyPage = ({ 
  listApiKeyFunction, 
  deleteApiKeyFunction,
  generateApiKeyFunction,
  toggleApiKeyFunction }) => {
    const [dataObj, setDataObj] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const newKeyRef = useRef(null);
    const [newKey, setNewKeyInput] = useState();
    dayjs.extend(utc)
    dayjs.extend(tz)

    const toggleSwitch = async (id) => {
      const res = await toggleApiKeyFunction(id);
      if(res.ok){
        setDataLoading(true);
      }
      return false;
	  }

    const genNewKey = async () => {
      const res = await generateApiKeyFunction(newKey);
      if(res.ok){
        setDataLoading(true);
      }
      newKeyRef.current.value = '';
      setNewKeyInput('');
      return false;
	  }


    const deleteSwitch = async (id) => {
      const res = await deleteApiKeyFunction(id);
      if(res.ok){
        setDataLoading(true);
      }
      return false;
	  }

    useEffect(() => {
        if(dataLoading) {
          listApiKeyFunction()
            .then(dataList => {
                if(dataList.ok){
                  setDataObj(dataList.payload);
                }
            })
            setDataLoading(false);
        }
    } , [dataLoading])
    
    const renderData = (item) => {
      return (
        <tr key={item?.objectId}>
          <th className="d-none d-md-table-cell" scope="row">{item?.keyName}</th>
          <td>{item?.key}</td>
          <td className="d-none d-md-table-cell">{dayjs(item.dateExpired.date).format('DD/MM/YYYY')}</td>
          <td>
            <Switch 
                onChange={() => toggleSwitch(item?.objectId)} 
                checked={item?.enable}
                offColor={"#FF5959"} 
                onColor={"#8BCA65"}
                uncheckedIcon={false}
                checkedIcon={false}
              />
          </td>
          <td>
            <div onClick={() => deleteSwitch(item?.objectId)}>
              <SvgIcon name="icon-delete" className="table-icon svg-red"/>
            </div>
          </td>
        </tr>
      );
    }

    return (
      <>
        <div className="modal-font modal fade" id="modalBox" data-backdrop="static" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title font-b" id="modalBoxLabel">Create New Key</h5>
                <div className="close" data-dismiss="modal">
                  <SvgIcon name="icon-xmark-solid" className="table-icon"/>
                </div>
              </div>
              <div className="modal-body">
                <label>Key Name</label>
                <input
                  ref={newKeyRef}
                  type="text"
                  className="data-form-control"
                  onChange={(e) => setNewKeyInput(e.target.value)} />
              </div>
              <div className="modal-footer">
                <div className="round-button pink" data-dismiss="modal" onClick={genNewKey}>Save</div>
              </div>
            </div>
          </div>
        </div>
        <center-container>
        <div className="max-width-900 content-block-full">
          <div className="table-control">
            <div className="left"></div>
            <div className="right">
              <div className="round-button low-pink" data-toggle="modal" data-target="#modalBox">New Key</div>
            </div>
          </div>
          <div className="table-defined">
          <table className="table">
            <thead>
              <tr>
                <th className="d-none d-md-table-cell" scope="col">Key Name</th>
                <th scope="col">Key</th>
                <th className="d-none d-md-table-cell" scope="col">Expire Date</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
            {dataObj.length == 0 ? (
              <tr><td>No Key</td></tr>
            ) : (
              dataObj.map((item) => {
                return renderData(item)
              })
            )}
            </tbody>
          </table>
          </div>
        </div>
        </center-container>
      </>  
    )
}

const mapStateToProps = (state) => ({
	authentication: state.authentication
});

const mapDispatchToProps = {
	listApiKeyFunction: listApiKey,
  deleteApiKeyFunction: deleteApiKey,
  toggleApiKeyFunction: toggleApiKey,
  generateApiKeyFunction: generateApiKey
	// checkSecureFunction: checkSecure
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(ApikeyPage);