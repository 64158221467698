import React, { useRef, useState } from "react"; 
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FILE_HOST } from '../../../utils/constants';
import SvgIcon from '../../../components/svgicon';
import _ from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

import { fileUpload } from "../../../redux/actions/app";

const RankList = ({ authentication, dataList, setSearchText, handleConfirm, fileUploadFn }) => {
  const searchTextRef = useRef(null);
  const [shareId, setShareId] = useState();
  const [addressText, setAddressText] = useState('');
  const [fileObj, setFileObj] = useState({});
  dayjs.extend(utc)
  dayjs.extend(tz)

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: authentication.settingObject.currency,
  });
  

  const navigate = useNavigate();
  const handleNavigate = (path) => {
    navigate(path);
  };

  const preparedList = (data) => {
    return (
      Object.keys(data).map((item) =>  {
        return renderTransaction(data[item])
      })
    );
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setSearchText(event.target.value);
    }
  }

  const renderResponseData = (data) => {
    return (
        <>
        {Object.keys(data).map((key) =>  {
            if(key == "card" || key == "verify"){
                return <><a href={data[key]} className="title" target="_blank">Image: {key}</a><br /></>;
            }
            return <><span className="title">{key}: </span>{data[key]}<br /></>;
        })}
        </>
    );
  }

  const confirmId = (d) => {
    setAddressText(renderResponseData(d.detail));
    setShareId(d.id);
  }

  const actionCtrl = () => {
    handleConfirm('confirm', {id: shareId})
  }

  const handleAction = (action, idx) => {
    handleConfirm(action, idx);
  }

  const uploadPicture = async (e) => {
    const files = Array.from(e.target.files)
    const datafile = await fileUploadFn(files[0]);
    setFileObj(datafile?.payload);
    // console.log(datafile?.payload+"/");
    return false;
  }

  const renderShareModal = () => {
    return (
      <div className="modal-font modal fade" id="paymentShareModalBox" data-backdrop="static" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title font-b" id="modalBoxLabel">Role Request</h5>
              <div className="close" data-dismiss="modal">
                <SvgIcon name="icon-xmark-solid" className="table-icon"/>
              </div>
            </div>
            <div className="modal-body">
              <div className="text-box">{addressText}</div>
            </div>
            <div className="modal-footer">
              <div className="round-button pink" data-dismiss="modal" onClick={() => actionCtrl()}>Confirm</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderTransaction = (item) => {
    let iconbg = "icon-40-bg-orange";
    let icons = "icon-next";
    let paystatus = "Waiting";
    let payaction = true;

    if(item.status=="confirm"){
        iconbg = "icon-40-bg-green";
        icons = "icon-down";
        paystatus = "Confirm";
        payaction = false;
    } else if (item.status=="reject"){
        iconbg = "icon-40-bg-red";
        icons = "icon-up";
        paystatus = "Reject";
        payaction = false;
    }

    return (
      <tr key={item.id+"coin9372b"} className="cur-pointer">
        <td style={{width: "25%"}}>
          <div className="table-currency">
            <div className={iconbg}>
              <SvgIcon name={icons} className="svg" />
            </div>
            <div className="ml-10">
              <div className="currency-name">{paystatus}</div>
              <div className="currency-full">
                {dayjs(item.createdAt.date).tz(authentication?.settingObject.dateZone).local().format('HH:mm')}
              </div>
            </div>
          </div>
        </td>
        <td style={{width: "25%"}} className="d-none d-md-table-cell">
           <div className="table-currency">
            <div className={iconbg}>
              <img src={item?.user?.avatar} />
            </div>
            <div className="ml-10">
              <div className="currency-name">{item?.user?.username}</div>
              <div className="currency-full">{item?.user?.id}</div>
            </div>
          </div>
        </td>
        <td style={{width: "25%"}}>
          <div className="table-currency">
            <div className={iconbg}>{item?.reqRoleNumber}</div>
            <div className="ml-10">
              <div className="currency-name">{item?.reqRole?.badge?.name}</div>
              <div className="currency-full"></div>
            </div>
          </div>
        </td>
        <td style={{width: "25%"}}>
          {payaction && (
            <>
              <div 
                className="round-button green wrap-button"
                onClick={() => confirmId(item)}
                data-toggle="modal" data-target="#paymentShareModalBox">Confirm
              </div>
              {' '}
              <div 
                className="round-button pink wrap-button" 
                onClick={() => handleAction('reject', {id: item.id})}
              >
                  Reject
              </div>
            </>
          )}
          {item.option?.url &&(
              <>
                <div onClick={() => window.open(FILE_HOST+item.option.url)}>
                  <SvgIcon name="icon-image" className="table-icon low-grey"/>
                </div>
              </>
            )}
        </td>
      </tr>
    );
  }

  return (
    <div className="max-width-900 data-block">
      {renderShareModal()}
        <div className="zone-header">
          <div className="left">
            <input
              className="zone-header-input"
              placeholder="Keyword eg. name:susan"
              ref={searchTextRef}
              type="text"
              onKeyDown={handleKeyDown} />
          </div>
        </div>
      <div className="table-defined">
        <table className="clean-table">
          <tbody>
            {_.isEmpty(dataList) ? (
              <tr key={"nodata"} className="divide">
                <td colSpan="4">No Invoice</td>
              </tr> 
            ) : (
              Object.keys(dataList).map((key) =>  {
                  return (
                    <React.Fragment key={key+"as842nsb"}>
                    <tr key={key+"as842nb"} className="divide">
                      <td colSpan="4">{key}</td>
                    </tr>
                    {preparedList(dataList[key])}
                    </React.Fragment >
                  )
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

RankList.propTypes = {
  header: PropTypes.string,
  dataList: PropTypes.object.isRequired,
  handleConfirm: PropTypes.func
};

const mapDispatchToProps = {
  fileUploadFn: fileUpload
};

const mapStateToProps = (state) => ({
	authentication: state.authentication
});

export default connect(
mapStateToProps,
mapDispatchToProps
)(RankList);