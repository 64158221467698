import { loading } from './loading';
import {
    getAllApiKey,
    deleteKey,
    toggleKey,
    newKey
} from "../../api/developer";

export const generateApiKey = (target) => {
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      const sessiontoken = window.localStorage.getItem('sessiontoken');  
      const response = await newKey(sessiontoken, target);
      if (!response?.ok) {
        throw response?.message;
      }
      dispatch(loading(false));
      return { ok: true };
    } catch (error) {
      dispatch(loading(false));
      return { ok: false };
    }
  };
};

export const listApiKey = () => {
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      const sessiontoken = window.localStorage.getItem('sessiontoken');  
      const response = await getAllApiKey(sessiontoken);
      if (!response?.ok) {
        throw response?.message;
      }
      dispatch(loading(false));
      return response;
    } catch (error) {
      dispatch(loading(false));
      return { ok: false };
    }
  };
};

export const deleteApiKey = (target) => {
    return async (dispatch) => {
      dispatch(loading(true));
      try {
        const sessiontoken = window.localStorage.getItem('sessiontoken');  
        const response = await deleteKey(sessiontoken, target);
        if (!response?.ok) {
          throw response?.message;
        }
        dispatch(loading(false));
        return { ok: true };
      } catch (error) {
        dispatch(loading(false));
        return { ok: false };
      }
    };
};

export const toggleApiKey = (target) => {
    return async (dispatch) => {
      dispatch(loading(true));
      try {
        const sessiontoken = window.localStorage.getItem('sessiontoken');  
        const response = await toggleKey(sessiontoken, target);
        if (!response?.ok) {
          throw response?.message;
        }
        dispatch(loading(false));
        return { ok: true };
      } catch (error) {
        dispatch(loading(false));
        return { ok: false };
      }
    };
  };