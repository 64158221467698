import React, { useState, useCallback, useEffect } from "react"; 
import { connect } from 'react-redux';
import Switch from "react-switch";
import { changeTwoFAConfig } from '../../redux/actions/authentication';
import { UI_ICON } from '../../assets/uiicon';
// import { checkSecure } from '../../redux/actions/secure';

const TwoFAScreen = ({ authentication, changeTwoFAConfigFunction }) => {
	// checkSecureFunction();
	const [mfa, setMfa] = useState();
	const [statusPending, setStatusPending] = useState(false);
	const [qrCode, setQrCode] = useState(false);
	const [dataStatus, setDataStatus] = useState(authentication.twoFA);

	const handleChange = (event) => {
        setMfa(event.target.value);
    }

	const toggleSwitch = useCallback(async (mfa) => {
		setStatusPending(true);
		const response = await changeTwoFAConfigFunction(authentication.twoFA, mfa);
		setQrCode(response.payload.qrcode);
		setDataStatus(response.twofa);
		setStatusPending(false);
	}, [dataStatus])

	useEffect(() => {
		setDataStatus(authentication.twoFA);
	});

	return (
    <center-container>
		<div className="max-width-600 content-block pb">
		<screen-style>
		<img src={UI_ICON.twofa_color_256} className="iconImg" />
		<div className="titleHeader">2 Factor Authentication</div>
		<div className="titleSubHeader">For your assets security, you can enable 2FA for your accout, so you can use Google Authenticator to be second factor for your account access.</div>
		</screen-style>

		<div className="list-style-container mt-30">
			<div className="item">
				<div className="iconContainer">
					<div className="iconBox">
						<img src={UI_ICON.twofa_color} className="iconImg" />
					</div>
				</div>
				<div className="leftContainer">
					<span className="titleText">Status</span>
				</div>
				<div className="rightContainer">
					{statusPending ? (
						<span>Loading</span>
					) : (
						<Switch 
							onChange={() => toggleSwitch(mfa)} 
							checked={dataStatus} 
							offColor={"#FF5959"} 
							onColor={"#8BCA65"}
							uncheckedIcon={false}
							checkedIcon={false}
						/>
					)}
					{}
				</div>
			</div>
			{dataStatus ? (
				<div className="item">
					<input type="number" className="data-form-control" onChange={handleChange} />
				</div>
			) : null }
		</div>
		<div className="configDesc">
			{dataStatus ? (
				'To disabled 2 Factor Authentication you need to put authentication code before toggle status button'
			) : 'If you enable 2 Factor Authentication you need to capture QR Code followed and keep it securely' }
		</div>
		{qrCode ? (
			<div className="list-style-container p-20 mt-20 text-center">
				<img src={qrCode} />
			</div>
		) : null }
		</div>
	</center-container>
	);	
}

const mapStateToProps = (state) => ({
	authentication: state.authentication
});

const mapDispatchToProps = {
	changeTwoFAConfigFunction: changeTwoFAConfig,
	// checkSecureFunction: checkSecure
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(TwoFAScreen);