import React, { createContext, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import store from '../redux/store';
import { setCurrentUser, clearCurrentUser } from '../redux/actions/authentication';
import { getAllNotification, clearAllNotification } from '../redux/actions/notification';
import { parseLogin, parseLogout, parseSignUp, wsLogout } from '../api/authentication';
import { loading } from '../redux/actions/loading';

import { useLocalStorage } from './useLocalStorage';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [sessiontoken, setsessiontoken] = useLocalStorage('sessiontoken', null);
  const navigate = useNavigate();

  (() => {
    if (sessiontoken) {
      store.dispatch(setCurrentUser(sessiontoken));
      store.dispatch(getAllNotification());
    }
  })();

  const login = async (data) => {
    store.dispatch(loading(true));
    const payload = {username: data?.username, password: data?.password, otp: data?.otp};
    const res = await parseLogin(payload);
    const token = res?.sessiontoken;
    if (!token) {
      logout();
      store.dispatch(loading(false));
      return res;
    }
    setsessiontoken(token);
    store.dispatch(setCurrentUser(token));
    store.dispatch(getAllNotification());
    store.dispatch(loading(false));
    return res;
    // navigate(HOME_PATH, { replace: true });
    // navigate("/home", { replace: true });
  };

  const fetchprofile = async () => {
    // store.dispatch(loading(true));
    store.dispatch(setCurrentUser(sessiontoken));
    // store.dispatch(loading(false));
    return true;
  };

  const logout = async () => {
    if (!sessiontoken) {
      return null;
      // await logUserOut(sessiontoken);
    }
    store.dispatch(loading(true));
    const res = await parseLogout(sessiontoken);
    await wsLogout();
    setsessiontoken(null);
    store.dispatch(clearCurrentUser());
    store.dispatch(clearAllNotification());
    store.dispatch(loading(false));
    navigate("/home", { replace: true });
    return res;
  };
  
  const signup = async (userRegister) => {
    if (!userRegister) {
      return null;
    }
    const res = await parseSignUp(userRegister);
    if (!res?.ok) {
      store.dispatch(loading(false));
      return res;
    }
    const token = res?.sessiontoken;
    if(!token) {
      store.dispatch(loading(false));
      return res;
    }
    setsessiontoken(token);
    store.dispatch(setCurrentUser(token));
    store.dispatch(loading(false));
    return res;
    // navigate('/', { replace: true });
  };

  const value = useMemo(
    () => ({
      sessiontoken,
      fetchprofile,
      login,
      logout,
      signup
    }),
    [sessiontoken]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

AuthProvider.propTypes = {
  children: PropTypes.element
};