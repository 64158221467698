import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loading } from '../../redux/actions/loading';

const TradingViewRenderer = ({ productId, theme }) => {
    const [ chartSrc, setChartSrc ] = useState(`<html><body><style>html, body{padding: 0; margin: 0;}</style></div></body></html>`);

    const iframe = useRef(null);
    const get = useRef(() => {});

    const graphTheme = theme ? theme : 'light';

    get.current = (chartSrc) => {
        const frame =
            iframe.current.contentWindow ||
            iframe.current.contentDocument.document ||
            iframe.current.contentDocument;
        frame.document.open();
        frame.document.write(chartSrc);
        frame.document.close();
    };

    const mapType = () => {
        let newType = "BINANCE:BTCUSDT";
        if(productId == "BTC-USDT"){      newType = "BINANCE:BTCUSDT"; }
        else if(productId == "BTC-THB"){      newType = "BITKUB:BTCTHB"; }
        else if(productId == "ETH-USDT"){ newType = "BINANCE:ETHUSDT"; }
        else if(productId == "ETH-THB"){ newType = "BITKUB:ETHTHB"; }

        const tempHtml = `
        <html><body><style>html, body{padding: 0; margin: 0;}</style>
        <div class="tradingview-widget-container">
            <div id="tradingview_e3cb6"></div>
            <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span class="blue-text">Track all markets on TradingView</span></a></div>
            <script type="text/javascript" src="https://s3.tradingview.com/tv.js"></script>
            <script type="text/javascript">
            new TradingView.widget(
            {
            "autosize": true,
            "symbol": "${newType}",
            "interval": "5",
            "timezone": "Asia/Bangkok",
            "theme": "${graphTheme}",
            "style": "1",
            "locale": "en",
            "enable_publishing": false,
            "hide_side_toolbar": false,
            "allow_symbol_change": true,
            "container_id": "tradingview_bfea6"
            }
            );
            window.scrollTo(0, document.body.scrollHeight);
            </script>
        </div></body></html>`;
        // setChartSrc(tempHtml);
        get.current(tempHtml);
    }

    useEffect(() => {
        mapType();
        // get.current();
    }, [productId, theme])

    useEffect(() => {
        mapType();
        // get.current();
    }, []);

    const frameLoad = () => {
        // loadingFunction(false);
    };

    const renderFrame = () => {
        return (
        <iframe
            className="full-page-iframe"
            ref={iframe}
            onLoad={frameLoad}></iframe>
        );
    };

    return renderFrame();
};

TradingViewRenderer.propTypes = {
  productId: PropTypes.string
};

const mapStateToProps = (state) => ({
//   authentication: state.authentication
});

const mapDispatchToProps = {
  loadingFunction: loading
};

export default connect(mapStateToProps, mapDispatchToProps)(TradingViewRenderer);
