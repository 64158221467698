import React, { useState, useEffect } from "react"; 
import { connect } from 'react-redux';
import _ from 'lodash';
// import '../styles/styles.css';
import { fetchAPIConnect } from '../../../redux/actions/app';
import { getFiatInvoice } from "../../../redux/actions/fiat";
import RankList from '../components/RankList';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

const AdminRankList = ({ authentication, getFiatInvoiceFn, fetchAPIConnectFn }) => {
    const [dataObj, setDataObj] = useState({});
    const [dataLoading, setDataLoading] = useState(true);
    const [searchText, setSearchText] = useState();

    dayjs.extend(utc)
    dayjs.extend(tz)

    useEffect(() => {
        setDataLoading(true);
    } , [searchText])

    useEffect(() => {
        if(dataLoading) {
            fetchAPIConnectFn('/rank/request/list', {keyword: searchText})
            .then(dataList => {
                if(dataList.ok){
                  // console.log(dataList.payload);
                  setDataObj(groupDate(dataList.payload));
                }
            })
            setDataLoading(false);
        }
    } , [dataLoading])

    const handleConfirm = (action, obx) => {
      let url = '/rank/request/reject';
      if(action == 'confirm'){
        url = '/rank/request/confirm';
      }
      // alert(action+obx.id+obx?.image);
      fetchAPIConnectFn(url, obx)
        .then(dataList => {
          if(dataList.ok){
            setDataLoading(true);
          }
        })
    }

    const groupDate = (data) => {
        var groups = _.groupBy(data, function (intv) {
            return dayjs(intv.createdAt.date).tz(authentication?.settingObject.dateZone).local().format('DD/MM/YYYY');
        });
        return groups;
    }

    return (
        <center-container>
            <RankList 
                dataList={dataObj} 
                setSearchText={setSearchText} 
                handleConfirm={handleConfirm}
            />
        </center-container>
    );

};

const mapStateToProps = (state) => ({
	authentication: state.authentication
});

const mapDispatchToProps = {
	getFiatInvoiceFn: getFiatInvoice,
  fetchAPIConnectFn: fetchAPIConnect
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(AdminRankList);