import React, { useState, useEffect } from "react"; 
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { CURRENCY, CRYPTO } from '../utils/crypto';

const WalletTypeBlock = ({ handleOnClick, authentication }) => {

    const handleClick = (data, type) => {
        handleOnClick(data, type);
    }
    const renderIcon = (data, type) => {
        return (
            <div key={data.typeid} className="item" onClick={() => handleClick(data, type)}>
                <div className="icon">
                    <img src={data.icon} alt="" />
                    <div className="title">{data.name}</div>
                    <div className="subtitle">{type}</div>
                </div>
            </div> 
        );
    }

    return (
        <div className="wallet-type-block">
            {Object.keys(CURRENCY).map((item) => {
                if(!CURRENCY[item].enable){
                    return null;
                }
                return renderIcon(CURRENCY[item], "fiat");
            })}
            {Object.keys(CRYPTO).map((item) => {
                if(!CRYPTO[item].enable){
                    return null;
                }
                return renderIcon(CRYPTO[item], "crypto");
            })}
        </div>
    );
};

WalletTypeBlock.propTypes = {
    handleOnClick: PropTypes.func
};

const mapStateToProps = (state) => ({
	authentication: state.authentication
});

export default connect(
    mapStateToProps,
    null
)(WalletTypeBlock);