export const WalletMenu = [
    {
        type: 'menu',
        label: 'Assets',
        target: '/wallet/list',
        icon: 'icon-wallet',
        auth: "wallet"
    },
    {
        type: 'menu',
        label: 'Tranaction',
        target: '/wallet/transaction',
        icon: 'icon-wallet',
        auth: "wallet"
    },
    {
        type: 'menu',
        label: 'Swap',
        target: '/wallet/swap',
        icon: 'icon-next',
        auth: "wallet"
    },
    {
        type: 'menu',
        label: 'Invoice',
        target: '/wallet/fiat/invoice/list',
        icon: 'icon-wallet',
        auth: "wallet"
    }
];

export const SettingMenu = [
    {
        type: 'menu',
        label: 'Profile',
        target: '/setting/profile',
        icon: 'icon-patient',
        auth: "setting"
    },
    {
        type: 'menu',
        label: 'Notification',
        target: '/setting/notification',
        icon: 'icon-noti',
        auth: "setting"
    },
    {
        type: 'menu',
        label: 'Asset',
        target: '/setting/wallet',
        icon: 'icon-wallet',
        auth: "setting"
    },
    {
        type: 'menu',
        label: 'Rank',
        target: '/setting/rank',
        icon: 'icon-star',
        auth: "setting"
    },
    {
        type: 'header',
        label: 'SECURITY'
    },
    {
        type: 'menu',
        label: 'Two Factor Authentication',
        target: '/setting/twofa',
        icon: 'icon-tele',
        auth: "setting"
    },
    {
        type: 'menu',
        label: 'Change Password',
        target: '/setting/changepassword',
        icon: 'icon-lock',
        auth: "setting"
    },
    {
        type: 'menu',
        label: 'Session Management',
        target: '/setting/session',
        icon: 'icon-monitor',
        auth: "setting"
    },
    {
        type: 'header',
        label: 'DEVELOPER'
    },
    {
        type: 'menu',
        label: 'Api Key',
        target: '/setting/apikey',
        icon: 'icon-paper-clip',
        auth: "setting"
    }
];

export const AdminMenu = [
    {
        type: 'header',
        label: 'USER'
    },
    {
        type: 'menu',
        label: 'User',
        target: '/admin/user/list',
        icon: 'icon-patient',
        auth: "admin"
    },
    {
        type: 'menu',
        label: 'Rank Request',
        target: '/admin/rank/list',
        icon: 'icon-patient',
        auth: "admin"
    },
    {
        type: 'header',
        label: 'TRANSACTION'
    },
    {
        type: 'menu',
        label: 'Tranaction',
        target: '/admin/transaction',
        icon: 'icon-wallet',
        auth: "admin"
    },
    {
        type: 'menu',
        label: 'Withdrawn',
        target: '/admin/withdrawn',
        icon: 'icon-wallet',
        auth: "admin"
    },
    {
        type: 'header',
        label: 'SYSTEM ASSETS'
    },
    {
        type: 'menu',
        label: 'ETH Wallet',
        target: '/admin/eth/wallet',
        icon: 'icon-wallet',
        auth: "admin"
    }
];
// export const MENU_LIST = {
//     wallet: WalletMenu
// }