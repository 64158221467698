import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Switch from "react-switch";

import { arrayFilterIt } from '../../utils/function';
import ProductList from './components/ProductList';
import OrderListLarge from './components/OrderListLarge';
import OrderBook from './components/OrderBook';
import OrderControl from "./components/OrderControl";
import TradeList from './components/TradeList';
import MarketPriceGraph from './components/MarketPriceGraph';
import WalletList from "./components/WalletList";

import { fetchExchangeWallet } from "../../redux/actions/wallet";
import { fetchWSAPIConnect, changeExchangeConfig } from '../../redux/actions/app';
import { wsSubscribe, wsUnSubscribe } from "../../redux/actions/socket";
import { isEmpty } from "lodash";

import './styles/styles-dark.css';
import './styles/styles-light.css';

const ExchangeMain = ({
  authentication, socket, latestMsg,
  fetchExchangeWalletFn, changeExchangeConfigFn, 
  wsSubscribeFn, wsUnSubscribeFn, fetchWSAPIConnectFn
}) => {
  let { prodId } = useParams();
  const [ productId, setProductId ] = useState('');
  const [ productList, setProductList ] = useState([]);
  const [ productDetail, setProductDetail ] = useState({});
  const [ order, setOrder ] = useState([]);
  const [ orderAsks, setOrderAsks ] = useState([]);
  const [ orderBids, setOrderBids ] = useState([]);
  const [ trade, setTrade ] = useState([]);
  const [ ticker, setTicker ] = useState({});
  const [ trigger, setTrigger ] = useState(0);
  const [ buyInputData, setBuyInputData ] = useState('');
  const [ sellInputData, setSellInputData ] = useState('');
  const navigate = useNavigate();
  // const mainTheme = "dark-exchange";
  // const mainTheme = "light-exchange";
  // const graphTheme = (mainTheme == "dark-exchange" ? "dark" : "light"); 
  const [ mainTheme, setMainTheme ] = useState(authentication?.exchangeSetting?.theme);
  const [ darkTheme, setDarkTheme ] = useState((mainTheme == "dark-exchange" ? true : false ));
  const [ graphTheme, setGraphTheme ] = useState((mainTheme == "dark-exchange" ? "dark" : "light"));

  const toggleThemeSwitch = () => {
    if(darkTheme == true){
      changeTheme("light-exchange");
      changeExchangeConfigFn('theme', "light-exchange");
      setDarkTheme(false);
    } else {
      changeTheme("dark-exchange");
      changeExchangeConfigFn('theme', "dark-exchange");
      setDarkTheme(true);
    }
  }
  const changeTheme = (mode) => {
    setMainTheme(mode);
    setGraphTheme((mode == "dark-exchange" ? "dark" : "light"));
  }

  useEffect(() => {
    if(productId != ''){
      selectProduct();
    }
  } , [productId])

  const changeProduct = (id) => {
    setOrderAsks([]);
    setOrderBids([]);
    setOrder([]);
    setProductId(id);
  }

  const fetchProductList = async () => {
    await fetchExchangeWalletFn();
    wsSubscribeFn('funds', {
      'type': 'subscribe',
      'currency_ids': ['BTC', 'ETH', 'THB', 'USD', 'USDT'],
      'channels': ['funds']
    });
    let res = await fetchWSAPIConnectFn(`/api/products`, 'GET', null);
    let pl = res?.payload || [];
    let productArr = [];
    pl.map((v,index) => {
      productArr.push(v.id);
      pl[index]['ticker'] = {};
      pl[index]['ticker'].open24h = 0;
      pl[index]['ticker'].price = 0;})
    setProductList(pl);
    const selectProduct = arrayFilterIt(pl, (prodId ? prodId : 'BTC-THB') , 'id');
    setProductDetail(selectProduct);
    setProductId((prodId ? prodId : 'BTC-THB'));

    let unSubTemp = socket?.subscribe?.ticker;
    if(!isEmpty(unSubTemp)){
      unSubTemp.type = 'unsubscribe';
      wsUnSubscribeFn('trade', unSubTemp);
    }

    const tickerTemp = {
      'type': 'subscribe',
      'product_ids': ["BTC-USDT", "ETH-USDT", "BTC-THB", "ETH-THB", "BTC-USD", "ETH-USD"],
      'channels': ['ticker']
    };
    wsSubscribeFn('ticker', tickerTemp);
  }

  const buyInputHandler = async (input) => {
    let res = await fetchWSAPIConnectFn(`/api/orders`, 'POST', input);
  }
  const askInputHandler = async (input) => {
    let res = await fetchWSAPIConnectFn(`/api/orders`, 'POST', input);
  }

  const updateProductList = async (msg) => {
    let updateList = productList;
    updateList.map((v,index) => {
      if(msg.productId === v.id){
        updateList[index].ticker = msg;
      }
    })
    setProductList(updateList);
  }

  const selectProduct = async () => {
    const selectProduct = arrayFilterIt(productList, productId, 'id');
    setProductDetail(selectProduct);
    let tradeRes = await fetchWSAPIConnectFn(`/api/products/${productId}/trades`, 'GET', null);
    if(!isEmpty(tradeRes)){setTrade(tradeRes?.payload)};
    let res = await fetchWSAPIConnectFn(`/api/orders?productId=${productId}&page=1&pageSize=20`, 'GET', null);
    if (!isEmpty(res?.payload?.items)){setOrder(res?.payload?.items);}
    let unSubTemp = socket?.subscribe?.trade;
    if(!isEmpty(unSubTemp)){
      unSubTemp.type = 'unsubscribe';
      wsUnSubscribeFn('trade', unSubTemp);
    }
    const subTemp = {
      'type': 'subscribe',
      'product_ids': productId,
      'channels': ['candles', 'level2', 'snapshot', 'order', 'match']
    };
    wsSubscribeFn('trade', subTemp);
  };

  const updateOrder = (msg) => {
    setTrigger(trigger + 1);
    let newTrigger = true;
    let orderTemp = order;
    let indexk = false;
    orderTemp?.map((odx, index) => {
      if(odx.id === msg.id){
        indexk = index;
        newTrigger = false;
      }
    });
    if(newTrigger){
      orderTemp.unshift(msg);
    } else {
      orderTemp[indexk] = msg;
    }
    setOrder(orderTemp);
    setTrigger(trigger + 1);
  }

  const updateBook = (m) => {
    try {
      if(typeof m === 'undefined'){ throw "Not m"; } 
      let askTemp = orderAsks;
      let bidTemp = orderBids;
      let newTrigger = true;
      setTrigger(trigger + 1);
      m.map((o) => {
        if(o[0] == "sell"){
          newTrigger = true;
          askTemp?.map((ix, index) => {
            if(o[1] == ix[0]) {
              askTemp[index] = [o[1], o[2], 0[2]];
              newTrigger = false;
            }
          })
          if(newTrigger){
            askTemp.unshift([o[1], o[2], 0[2]]);
          }
        } else if (o[0] == "buy"){
          newTrigger = true;
          bidTemp?.map((ix, index) => {
            if(o[1] == ix[0]) {
              bidTemp[index] = [o[1], o[2], 0[2]];
              newTrigger = false;
            }
          })
          if(newTrigger){
            bidTemp.unshift([o[1], o[2], 0[2]]);
          }
        }
      });
      setOrderAsks(askTemp);
      setOrderBids(bidTemp);
      setTrigger(trigger + 1);
    } catch (error) {
      setTrigger(trigger + 1);
    }
  }

  const sortDescFunction= (a, b) => { if (a[0] === b[0]) { return 0; } else { return (a[0] < b[0]) ? -1 : 1; }}
  const sortAscFunction = (a, b) => { if (a[0] === b[0]) { return 0; } else { return (b[0] < a[0]) ? -1 : 1; }}

  useEffect(() => {
    if(latestMsg.type == "order"){
      updateOrder(latestMsg);
    } else if(latestMsg.type == "l2update"){
      updateBook(latestMsg?.changes);
    } else if(latestMsg.type == "snapshot"){
      const askTemp = latestMsg?.asks?.sort(sortDescFunction);
      const bidTemp = latestMsg?.bids?.sort(sortAscFunction);
      setOrderAsks(askTemp);
      setOrderBids(bidTemp);
    } else if(latestMsg.type == "ticker"){
      updateProductList(latestMsg);
      setTicker(latestMsg);
    } else if(latestMsg.type == "match"){
      let tradeTemp = trade;
      tradeTemp.unshift(latestMsg);
      setTrade(tradeTemp);
      setTrigger(trigger + 1);
    }
  }, [latestMsg])

  // useEffect(() => {
  // }, [wallet])

  useEffect(() => {
    fetchProductList();
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      let url = '/exchange/'+productId;
      window.location.href = url;
    }, 600000);
    return () => clearInterval(intervalId);
  }, [productId]);

  return (
    <div className={mainTheme}>
      <div className="control-top-exchange">
        <div className="left-092d">
          <div className="symbol-title">{productDetail?.baseCurrency}/{productDetail?.quoteCurrency}</div>
          <div className="price-title"><span className={(productDetail?.ticker?.price >= productDetail?.ticker?.open24h ? "green" : "red")}>{parseFloat(productDetail?.ticker?.price).toFixed(2)}</span></div>
          <div className="data-block-912d"><span className="title">24h Change</span>
            <span className={"data red"}>
              {(productDetail?.ticker?.price >= productDetail?.ticker?.open24h ? "+" : "-")}{
              isNaN(parseFloat(100-((productDetail?.ticker?.price/productDetail?.ticker?.open24h)*100)).toFixed(2)) ? '0.0' :
              parseFloat(100-((productDetail?.ticker?.price/productDetail?.ticker?.open24h)*100)).toFixed(2)
              }%
            </span>
          </div>
          <div className="data-block-912d"><span className="title">24h High</span><span className="data">{!isNaN(parseFloat(productDetail?.ticker?.high24h).toFixed(2)) ? parseFloat(productDetail?.ticker?.high24h).toFixed(2) : 0}</span></div>
          <div className="data-block-912d"><span className="title">24h Low</span><span className="data">{!isNaN(parseFloat(productDetail?.ticker?.low24h).toFixed(2)) ? parseFloat(productDetail?.ticker?.low24h).toFixed(2) : 0}</span></div>
          <div className="data-block-912d"><span className="title">24h Volume</span><span className="data">{!isNaN(parseFloat(productDetail?.ticker?.volume24h).toFixed(2)) ? parseFloat(productDetail?.ticker?.volume24h).toFixed(2) : 0}</span></div>
        </div>
        <div className="right-092d">
          <div className="text cur-pointer" onClick={() => navigate('/exchange/simple/'+productId, {replace: true})}>Simple</div>
          <div className="text">Dark Theme</div>
          <Switch 
            onChange={() => toggleThemeSwitch()} 
            checked={darkTheme}
            offColor={"#6f6f6f"} 
            onColor={"#6f6f6f"}
            className="switch-long"
            uncheckedIcon={<div className="switch-icon">Off</div>}
            checkedIcon={<div className="switch-icon">On</div>}
          />
        </div>
      </div>
      <div className="container-fluid p-0">
      <div className="row no-gutters trade-container full-style">
        <div className="col-md-3 full-height bg-color">
          <div className="h50-height"><ProductList data={productList} currentId={productId} handleFunction={changeProduct} trigger={trigger}/></div>
          <div className="h50-height trade-tb"><WalletList trigger={trigger} /></div>
        </div>
        <div className="col-md-6 full-height flex-blx bg-color">
          <div className="main-chart">
            <MarketPriceGraph theme={graphTheme} product={productDetail} trigger={trigger} />
          </div>
          <div className="market-trade">
          <ul className="nav nav-pills" role="tablist">
            <li className="nav-item"><a className="nav-link active" data-toggle="pill" href="#pills-trade-limit" role="tab" aria-selected="true">Limit</a></li>
            <li className="nav-item"><a className="nav-link" data-toggle="pill" href="#pills-market" role="tab" aria-selected="false">Market</a></li>
            <li className="nav-item"><a className="nav-link" data-toggle="pill" href="#pills-stop-limit" role="tab" aria-selected="false">Stop Limit</a></li>
            <li className="nav-item"><a className="nav-link" data-toggle="pill" href="#pills-stop-market" role="tab" aria-selected="false">Stop Market</a></li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane fade show active" id="pills-trade-limit" role="tabpanel">
              <div className="d-flex justify-content-between">
                <OrderControl side="buy" product={productDetail} inputHandler={buyInputHandler} preSetInput={sellInputData} />
                <OrderControl side="sell" product={productDetail} inputHandler={askInputHandler} preSetInput={buyInputData} />
              </div>
            </div>
            <div className="tab-pane fade" id="pills-market" role="tabpanel">Market Pane</div>
            <div className="tab-pane fade" id="pills-stop-limit" role="tabpanel">Stop Limit</div>
            <div className="tab-pane fade" id="pills-stop-market" role="tabpanel">Stop Market</div>
          </div>
        </div>
        {/* <div className="order-block-sm"><OrderListLarge product={productDetail} data={order} trigger={trigger}/></div> */}
        </div>
        <div className="col-md-3 full-height bg-color">
          <div className="h30-height"><TradeList product={productDetail} data={trade} trigger={trigger}/></div>
          <div className="h70-height trade-tb"><OrderBook product={productDetail} ask={orderAsks} bid={orderBids} setBuy={setBuyInputData} setSell={setSellInputData} trigger={trigger}/></div>
        </div>
        <div className="col-md-12 order-bottom-block bg-color"><OrderListLarge product={productDetail} data={order} trigger={trigger}/></div>
      </div>
      
      </div>
    </div>
  );

};

const mapStateToProps = (state) => ({
  authentication: state.authentication,
  socket: state.socket,
  latestMsg: state.socket.latestMsg
});

const mapDispatchToProps = {
  fetchWSAPIConnectFn: fetchWSAPIConnect,
  fetchExchangeWalletFn: fetchExchangeWallet,
  changeExchangeConfigFn: changeExchangeConfig,
  wsSubscribeFn: wsSubscribe,
  wsUnSubscribeFn: wsUnSubscribe
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(ExchangeMain);