import React, { useRef, useEffect, useState } from "react"; 
import PropTypes from 'prop-types';
import { makeid } from '../../utils/function';
import './flatTable.css';
import _ from "lodash";

const FlatTable = ({ headerList, dataList }) => {
    const searchTextRef = useRef(null);
    const [ showData, setShowData ] = useState();

    useEffect(() => {
        setShowData(dataList);
    }, [dataList])

    const renderCol = () => {
        return headerList.map((item) => {
            return <th scope="col" key={makeid(5)}>{item.title}</th>
        })
    }
    const renderRow = (item) => {
        return (
        <tr key={makeid(5)}>
        {headerList.map((hx) => {
            return <td key={makeid(5)}>{item[hx.objId]}</td>
        })}
        </tr>
        );
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch(event.target.value);
        }
    }

    const handleSearch = (text) => {
        if(_.isEmpty(dataList)){
            return null;
        }
        let finalData = [];
        dataList.map((item) => {
            if(item.servefield.includes(text)){
                finalData.push(item);
            }
        });
        setShowData(finalData);
    }

    return (
    <div className="content-block-full">
    <div className="table-control">
        <div className="left">
        <input
              className="zone-header-input"
              placeholder="Search"
              ref={searchTextRef}
              type="text"
              onKeyDown={handleKeyDown} />
        </div>
        <div className="right">
            {/* <div className="round-button low-pink" data-toggle="modal" data-target="#modalBox">New Key</div> */}
        </div>
    </div>
    <div className="table-defined">
        <table className="clean-table">
          <thead className="line-head">
            <tr>{renderCol()}</tr>
          </thead>
          <tbody>
            {showData ? showData.map((item) => {
              return renderRow(item)
            }): <></>}
          </tbody>
        </table>
    </div>
    </div>
    )
};

FlatTable.propTypes = {
    headerList: PropTypes.array,
    dataList: PropTypes.array
};

export default FlatTable;