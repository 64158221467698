import { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../assets/images/logo-white@512.png';
import signin_cover from '../../assets/images/signin-cover.jpg';

const ExternalMain = () => {
  const [ errorText, setErrorText ] = useState(null);
  const [ inputText, setInputText ] = useState(null);
  const param = useParams();
  let navigate = useNavigate();

  const handleSearch = () => {
    navigate(`/external/invoice/find/${param?.key}/${inputText}`);
  }

  const handleChange = (event) => {
    setInputText(event.target.value);
  }

  return (
    <>
      <login-box>
        <img className="herobox-logo" src={logo}/>
        <img className="herobox" src={signin_cover}/>
        <box-content>
        <text-title>Find Invoice</text-title>
        <text-subtitle>Find your invoice using {param?.key}</text-subtitle>
        <text-subtitle className="error">{errorText}</text-subtitle>
        <box-input>
          <input
            id="querytext"
            type="text"
            placeholder={param?.key}
            onChange={handleChange}
          />
        </box-input>
        <button-block>
            <circle-button onClick={handleSearch}>Search</circle-button>
        </button-block>
        </box-content>
      </login-box>
    </>
  );

};

export default ExternalMain;