import React, { useState, useEffect, useRef } from "react"; 
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Lottie from "lottie-react";
import { apiConnect } from "../../utils/fetch";
import { fileUpload } from "../../redux/actions/app";
import { FILE_HOST } from "../../utils/constants";
import SvgIcon from "../../components/svgicon";
import completeAnimation from "../../assets/animation/complete.json";
import './styles/rank.css';

const verifyImage = require('../../assets/images/verify12.png');
const cardImage = require('../../assets/images/thaiidcard.jpg');

const RankPage = ({fileUploadFn, lang}) => {
    const navigate = useNavigate();
    const [ rankUploaded, setRankUploaded ] = useState(false);
    const [ verifyimg, setVerifyimg ] = useState(verifyImage);
    const [ upVerifyimg, setUpVerifyimg ] = useState(false);
    const [ cardimg, setCardimg ] = useState(cardImage);
    const [ upCardimg, setUpCardimg ] = useState(false);
    const [ dataObj, setDataObj ] = useState([]);

    useEffect(() => {
        apiConnect('/rank/fetch', null)
          .then(dataList => {
            // console.log(dataList.payload);
            if(dataList.ok){
                setDataObj(dataList.payload);
            }
        })
    },[])

    const _verifyFormUpload = (idx, type) => {
      if(type == "verify-form"){
        if(upVerifyimg == false || upCardimg == false ){ alert("Please upload documents"); return null; }
      }
      // console.log(idx);
      apiConnect('/rank/request/create', JSON.stringify({id: idx, card: cardimg, verify: verifyimg}))
          .then(dataList => {
            if(dataList.ok){
              setRankUploaded(true);
            }
        })
    }

    const renderCreated = () => {
      return (
        <div className="max-width-600 content-block">
          <screen-style>
              <Lottie animationData={completeAnimation} style={{height: 300}}/>
              <div className="centerTitleHeader mt-20">Documents Sended</div>
              <div className="centerSubTitleHeader mb-20">Your verification documents has been send please wait system processed.</div>
              {/* <div className="impotant-data-zone">{renderResponseData()}</div> */}
              <button-block>
                  <circle-button onClick={() => navigate('/wallet/list')}>Next</circle-button>
              </button-block>
          </screen-style>
          <div style={{height: "20px"}} />
        </div>
      )
    }

    const _uploadFile = async (e) => {
      const files = Array.from(e.target.files)
      const datafile = await fileUploadFn(files[0]);
      if(e.target.id == "verify"){
        setUpVerifyimg(true);
        setVerifyimg(FILE_HOST+datafile?.payload.url);
      } else {
        setUpCardimg(true);
        setCardimg(FILE_HOST+datafile?.payload.url);
      }
    }

    const renderNextHeader = (i) => {
      return (
        <div key={i.id+"mfx"}  className="header next black">
        <div className="content">
          <div className="topTitle">Next Rank</div>
          <div className="title">{i?.systemObject?.badge?.name}</div>
          <div className="subtitle">{i?.formObject?.detail[lang]}</div>
          <div className="subtitle-x">{i?.formObject?.toactivate[lang]}</div>
        </div>
        </div>
      )
    }

    const renderNextBlock = (i) => {
      return (
        <>
        <div className="medium-icon">
          <SvgIcon name="icon-down" className="icon grey-filter"/>
          <div className="text blink-me">Upgrade Now!!</div>
        </div>
        <div key={i.id+"mcx"} className="rank-block">
          {renderNextHeader(i)}
          <div className="form">
          <button-block>
            <circle-button onClick={() => _verifyFormUpload(i.id, "button")}>Activate</circle-button>
          </button-block>
          </div>
        </div>
        <div style={{height: "40px"}} />
        </>
      )
    }

    const renderIdFormBlock = (i) => {
      return (
        <>
        <div className="medium-icon">
          <SvgIcon name="icon-down" className="icon grey-filter"/>
          <div className="text blink-me">Upgrade Now!!</div>
        </div>
        <div key={i.id} className="rank-block">
          {renderNextHeader(i)}
          <div className="form">
          <div className="image-edit-block">
          <div className="verify-image-width">
          <image-edit-box>
            <edit-button><SvgIcon name="icon-edit" className="icons"/></edit-button>
            <img src={verifyimg}/>
            <input type="file" onChange={_uploadFile} id="verify" />
          </image-edit-box>
          </div>
          <div className="image-label">Please upload your identify image by click top box</div>
          </div>

          <div className="image-edit-block">
          <div className="verify-image-width">
          <image-edit-box>
            <edit-button><SvgIcon name="icon-edit" className="icons"/></edit-button>
            <img src={cardimg}/>
            <input type="file" onChange={_uploadFile} id="card" />
          </image-edit-box>
          </div>
          <div className="image-label">Please upload your Thai ID Card image by click top box</div>
          </div>

          <button-block>
            <circle-button onClick={() => _verifyFormUpload(i.id, "verify-form")}>Save</circle-button>
          </button-block>
          </div>
        </div>
        <div style={{height: "40px"}} />
        </>
      )
    }

    const renderBlock = (i) => {
      return (
        <>
        <div key={i.id} className="rank-block">
          <div className="header current">
            <div className="content">
              <div className="topTitle">Current Rank</div>
              <div className="title">{i?.systemObject?.badge?.name}</div>
              <div className="subtitle">{i?.formObject?.detail[lang]}</div>
            </div>
          </div>
        </div>
        </>
      )
    }

    const renderRankBlock = () => {
      return (
        <>
        {dataObj.map((item) => {
          if(item.current == true) { return renderBlock(item); }
          if(item.next == true) {
            if(item?.formObject?.type == "id-verify-form"){
              return renderIdFormBlock(item); 
            } else {
              return renderNextBlock(item); 
            }
          }
          // return renderBlock(item);
          return <></>;
        })}
        </>
      )
    }

    return (
      <center-container>
        <div className="max-width-600">
        {(dataObj && (rankUploaded == false )) ? renderRankBlock(): <></>}
        {rankUploaded ? renderCreated() : <></>}
        </div>
      </center-container>
    )
}

const mapStateToProps = (state) => ({
	authentication: state.authentication,
  lang: state?.authentication?.settingObject?.language?.system
});

const mapDispatchToProps = {
  fileUploadFn: fileUpload
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(RankPage);