import { useState, useCallback, useEffect } from "react";
import { connect } from 'react-redux';
import { listSession, removeSession } from '../../redux/actions/authentication';
import { UI_ICON } from '../../assets/uiicon';
import SvgIcon from '../../components/svgicon';
// import { useParams } from "react-router-dom";

const SessionMgnt = ({ listSessionFunction, removeSessionFunction}) => {
    const [dataObj, setDataObj] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);

    const toggleSwitch = useCallback(async (id) => {
        await removeSessionFunction(id);
        setDataLoading(true);
        return false;
	}, [])

    useEffect(() => {
        if(dataLoading) {
            listSessionFunction()
            .then(dataList => {
                if(dataList.ok){
                    setDataObj(dataList.payload);
                }
            })
            setDataLoading(false);
        }
    } , [dataLoading])

    const renderData = (item) => {
        let iconImg = UI_ICON.session_pc_color;
        if(item.installation.type==='ios'){
            iconImg = UI_ICON.session_ios_color;
        } else if(item.installation.type==='android'){
            iconImg = UI_ICON.session_android_color;
        }
        return (
            <div key={item.id} className="item">
				<div className="iconContainer">
					<div className="iconBox">
						<img src={iconImg} className="iconImg" />
					</div>
				</div>
				<div className="leftContainer">
					<div className="titleText">{item.installation.os}</div>
                    <div className="subTitleText"><span className="font-b">Created At:</span> {item.createdAt}</div>
                    <div className="subTitleText"><span className="font-b">Id:</span> {item.installation.id}</div>
				</div>
                <div className="rightContainer">
                {item.self ? (
						<div className="simpleChip">This Session</div>
					) : (
						<i className="icon ion-md-remove-circle red font-29 mr-2 cur-pointer" onClick={()=>toggleSwitch(item.id)}></i>
					)}
				</div>
			</div>
        );
    }

    return (
        <center-container>
            <div className="max-width-600 content-block">
            <screen-style>
            <SvgIcon name="icon-guard" className="iconImg blue-filter"/>
            <div className="titleHeader">Session Management</div>
            <div className="titleSubHeader">Below is where your account logged in, you can carefully check these session, if you found it seem unfamilar with, you should delete it asap.</div>
            </screen-style>
            <div className="list-style-container mt-30">
            {dataObj.map((item) => {
                return renderData(item)
            })}
            </div>
            </div>
        </center-container>
    );
};
  
const mapStateToProps = (state) => ({
	// authentication: state.authentication
});

const mapDispatchToProps = {
	listSessionFunction: listSession,
    removeSessionFunction: removeSession
	// checkSecureFunction: checkSecure
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(SessionMgnt);
  