export const APP_HOST = "https://www.wallet025.com";
// export const SERVER_HOST = "http://localhost:8181";
export const SERVER_HOST = "https://api.wallet025.com";
export const FILE_HOST = "http://file.wallet025.com";
export const LEGACY_HOST = "http://localhost:8089";
export const API_KEY = 'Io5rR3ngWsB0u3F1TOQz7wDsKWObkAX8Izm1pGhr';
export const WS_API_HOST = "https://ex.wallet025.com";
// export const WS_API_HOST = "http://enzo.wallet025.com:8123";
// export const WS_API_HOST = "http://k1.wallet025.com:8123";
export const WS_HOST = "wss://ex.wallet025.com/ws";
// export const WS_HOST = "ws://ex.wallet025.com:8123/ws";
// export const WS_HOST = "ws://enzo.wallet025.com:8123/ws";
// export const ADMIN_SITE = true;

export const ADMIN_RANK = ["HXVcy3OcV8"];

export const HOME_PATH = '/';

export const toastConfig = {
  position: 'bottom-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
};