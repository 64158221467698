import React, { useState, useEffect, useCallback } from "react"; 
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { CRYPTO, CURRENCY } from '../../../utils/crypto'
import NumberInput from '../../../components/numberInput';
import SvgIcon from '../../../components/svgicon';
import WalletRadioInput from "../../../components/walletRadioinput";
import { fetchUserWallet, fetchSwapable } from '../../../redux/actions/wallet';
import { formatNumber } from '../../../utils/function';

import '../styles/swap.css';

const WalletSwapMain = ({ fetchUserWalletFn, fetchSwapableFn, authentication, wallet }) => {

    const params = useParams();

    const [newAmount, setNewAmountInput] = useState();
    const [toAmount, setToAmountInput] = useState();

    // const [fromType, setWalletList] = useState({});
    const [walletList, setWalletList] = useState({});
    const [swapList, setSwapList] = useState({});
    const [fromAddress, setFromAddress] = useState({});
    const [toAddress, setToAddress] = useState({});

    const navigate = useNavigate();

    const fromHandle = (data) => { setNewAmountInput(0); setToAmountInput(0); setFromAddress(data); setToAddress({}); fetchSwapable(data); };
    const toHandle = (data) => { setNewAmountInput(0); setToAmountInput(0); setToAddress(data)};

    const showTotal = () => {
      const total = parseFloat(toAddress?.swapFee) + parseFloat(toAddress?.serviceCharge) + parseFloat(newAmount);
      if(!total){
        return 0;
      }
      return formatNumber(total, {maxFrac: 2, crypMaxFrac: 8, type: toAddress?.type});
    }

    const createNewSwap = async () => {
      const payload = {
        from: fromAddress?.id,
        fromAddress: fromAddress?.address,
        to: toAddress?.id,
        toAddress: toAddress?.address,
        amount: parseFloat(newAmount),
        swapId: toAddress?.swapId,
        fromType: fromAddress?.typeid,
        toType: toAddress?.typeid
      };
      navigate('/wallet/swap/confirm', {state: payload});
    }

    const handleToAmountChange = (value) => {
      if(_.isEmpty(toAddress)){
        setNewAmountInput(0);
        return null;
      }

      const tempValue = value / toAddress?.value;
      setToAmountInput(value);
      setNewAmountInput(tempValue);
    }

    const handleAmountChange = (value) => {
      if(_.isEmpty(toAddress)){
        setNewAmountInput(0);
        return null;
      }

      const tempValue = value * toAddress?.value;
      setToAmountInput(tempValue);
      setNewAmountInput(value);
    }

    const fetchSwapable = async (data) => {
      if(_.isEmpty(data)){ setSwapList({}); return null; }
      let resp = await fetchSwapableFn(data?.id);
      let walletTemp = {fiat: [], crypto: []};
      if(_.isEmpty(resp?.payload?.to)){ setSwapList({}); return null; }
      resp.payload.to.map((item) => {
        let key = CURRENCY[item?.typeid] || CRYPTO[item?.typeid];
        walletTemp.fiat.push({
          value: item?.value,
          address: item?.address,
          typeid: key?.typeid,
          title: key?.name,
          symbol: key?.symbol,
          swapId: item?.swapId,
          id: item?.id,
          max: item?.max,
          min: item?.min,
          icon: key?.icon,
          swapFee: (item?.system?.swapFee ? item?.system?.swapFee: 0),
          serviceCharge: (item?.system?.serviceCharge ? item?.system?.serviceCharge : 0)
        });
      });
      setSwapList(walletTemp);
    }

    useEffect(() => {
        // console.log(wallet);
        let walletTemp = {fiat: [], crypto: []};
        wallet?.fiat?.map((item) => {
          let key = CURRENCY[item?.typeid];
          walletTemp.fiat.push({
            value: item?.amount,
            typeid: item?.typeid,
            // usdValue: usdValue,
            address: item?.address,
            title: key?.name,
            symbol: key?.symbol,
            id: item?.id,
            icon: key?.icon
          });
          if(item?.id === params?.id){
            setFromAddress({
                address: item?.address,
                title: key?.name,
                symbol: key?.symbol,
                id: item?.id,
                icon: key?.icon
            });
          }
        })
        wallet?.crypto?.map((item) => {
          let key = CRYPTO[item?.typeid];
          walletTemp.crypto.push({
            value: item?.amount,
            typeid: item?.typeid,
            address: item?.address,
            title: key?.name,
            symbol: key?.symbol,
            id: item?.id,
            icon: key?.icon
          });
        })
        if(_.isEmpty(fromAddress)){
            fromHandle(walletTemp.fiat[0]);
        }
        setWalletList(walletTemp);
    } , [wallet])
    
    useEffect(() => {
        fetchUserWalletFn();
    } , [])

    const renderFromModal = () => {
        return (
        <div className="modal-font modal fade" id="fromModal" data-backdrop="static" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title font-b" id="modalBoxLabel">From Wallet</h5>
                <div className="close" data-dismiss="modal">
                  <SvgIcon name="icon-xmark-solid" className="table-icon"/>
                </div>
              </div>
              <div className="modal-body">
                <WalletRadioInput 
                    inputData={walletList} 
                    inputHandle={fromHandle}
                    inputDefault={fromAddress?.id}
                />
              </div>
            </div>
          </div>
        </div>
        )
    }

    const renderToModal = () => {
      return (
      <div className="modal-font modal fade" id="toModal" data-backdrop="static" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title font-b" id="modalBoxLabel">To Wallet</h5>
              <div className="close" data-dismiss="modal">
                <SvgIcon name="icon-xmark-solid" className="table-icon"/>
              </div>
            </div>
            <div className="modal-body">
              <WalletRadioInput 
                  inputData={swapList} 
                  inputHandle={toHandle}
                  inputDefault={toAddress?.id}
              />
            </div>
          </div>
        </div>
      </div>
      )
  }

  return (
    <center-container>
      {renderFromModal()}
      {renderToModal()}
      {/* <div className="max-width-600 content-block pb"> */}
      <div className="max-width-600 pb">
        <div className="swap-header-text mt-20">From</div>
        {!_.isEmpty(fromAddress) ? (
          <div className="swap-curr-box">
          <div className="list-blank-container">
          <div className="item" key={fromAddress?.id+"-Bgvf12s"}>
            <div className="iconContainer"><img src={fromAddress?.icon} className="iconBox"/></div>
            <div className="leftContainer">
              <div className="titleText">{fromAddress?.title}</div>
              <div className="subTitleText">{fromAddress?.address}</div>
            </div>
            <div className="rightContainer">
              <div className="round-button grey" data-toggle="modal" data-target="#fromModal">Change</div> 
            </div>
          </div>
          <div className="p-15">
            <NumberInput
              label={"Amount"}
              symbol={fromAddress?.symbol}
              inputHandle={handleAmountChange} 
              inputData={newAmount} />
          </div>
          </div>
          </div>
        ) : (
          <div className="select-blank-container" data-toggle="modal" data-target="#fromModal">Please Select Convert Wallet</div>
        )}

      <div className="medium-icon"><SvgIcon name="icon-down" className="icon grey-filter"/></div>
      
        <div className="swap-header-text">To</div>
        {!_.isEmpty(toAddress) ? (
          <div className="swap-curr-box">
          <div className="list-blank-container">
          <div className="item" key={toAddress?.id+"-Bgvf12s"}>
            <div className="iconContainer"><img src={toAddress?.icon} className="iconBox"/></div>
            <div className="leftContainer">
              <div className="titleText">{toAddress?.title}</div>
              <div className="subTitleText">{toAddress?.address}</div>
            </div>
            <div className="rightContainer">
              <div className="round-button grey" data-toggle="modal" data-target="#toModal">Change</div>  
            </div>
          </div>
          <div className="p-15">
            <NumberInput
              label={"Amount"}
              symbol={toAddress?.symbol}
              inputHandle={handleToAmountChange} 
              inputData={toAmount} />
          </div>
          </div>
          </div>
        ) : (
          <div className="select-blank-container" data-toggle="modal" data-target="#toModal">
            <SvgIcon name="icon-wallet" className="select-blank-container-icon grey-filter"/>
            <div>Please Select Reciever Wallet</div>
          </div>
        )}
        
        {!_.isEmpty(toAddress) ? (
        <div className="additional-detail">
          <div className="item">
            <span className="font-b">Swap Fee:</span> {formatNumber(toAddress?.swapFee, {maxFrac: 2, crypMaxFrac: 8, type: toAddress?.type})} <span className="font-b">{fromAddress?.symbol}</span> 
          </div>
          <div className="item">
            <span className="font-b">Service Charge:</span> {formatNumber(toAddress?.serviceCharge, {maxFrac: 2, crypMaxFrac: 8, type: toAddress?.type})} <span className="font-b">{fromAddress?.symbol}</span> 
          </div>
          <div className="item">
            <span className="font-b">Total Amount:</span> {showTotal()} <span className="font-b">{fromAddress?.symbol}</span> 
          </div>
        </div>
        ) : <></>}

        <button-block>
          <circle-button onClick={createNewSwap}>Add</circle-button>
        </button-block>
      </div>
    </center-container>
  )

};

const mapStateToProps = (state) => ({
	authentication: state.authentication,
  wallet: state.wallet
});

const mapDispatchToProps = {
  fetchUserWalletFn: fetchUserWallet,
  fetchSwapableFn: fetchSwapable
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(WalletSwapMain);