// import { useOutlet } from 'react-router-dom';
import { useOutlet, useNavigate } from 'react-router-dom';
import SvgIcon from '../../components/svgicon';
import '../../assets/styles/login.css';

export const LoginLayout = () => {
  const outlet = useOutlet();
  let navigate = useNavigate();
  return (
    <div>
      <back-icon onClick={()=> navigate('/home')}>
        <SvgIcon name="icon-back" className="login-page-icon"/>
      </back-icon>
      {outlet}
    </div>
  );
};