import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { LoginLayout } from './components/layout/LoginLayout';
import DefaultLayout from './components/layout/DefaultLayout';
import MobileLayout from './components/layout/MobileLayout';
import SidebarLayout from './components/layout/SidebarLayout';
import BlankLayout from './components/layout/BlankLayout';

import LoginPage from './pages/authentication/Login';
import ResetPassword from './pages/authentication/ResetPassword';
import HomePage from './pages/home/Home';
import ProfilePage from './pages/setting/Profile';
import SessionMgnt from './pages/setting/SessionMgnt';
import TwoFaScreen from './pages/setting/TwoFaScreen';
import ChangePassword from './pages/setting/ChangePassword';
import NotiSetting from './pages/setting/NotiSetting';
import ApikeyPage from './pages/setting/Apikey';
import WalletSettingPage from './pages/setting/WalletSetting';
import RankPage from './pages/setting/Rank';

import ExchangeMain from './pages/exchange/main';
import ExchangeMainSimple from './pages/exchange/main-simple';

import WalletList from './pages/wallet/list';
import WalletTransaction from './pages/wallet/transaction';
import FiatInvoiceList from './pages/wallet/fiat/invoicelist';
import FiatInvoiceCreate from './pages/wallet/fiat/invoicecreate';
import FiatDepositCreate from './pages/wallet/fiat/depositcreate';
import FiatWithdrawnCreate from './pages/wallet/fiat/withdrawncreate';
import FiatInvoiceView from './pages/wallet/fiat/invoiceview';
import FiatDirectTxView from './pages/wallet/fiat/directtxview';
import CryptoWithdrawnCreate from './pages/wallet/crypto/withdrawncreate';
import CryptoDepositCreate from './pages/wallet/crypto/depositcreate';
import ExchangeDepositCreate from './pages/wallet/exchange/depositcreate';
import ExchangeWithdrawnCreate from './pages/wallet/exchange/withdrawncreate';
import WalletOverview from './pages/wallet/overview';
import WalletCreateMain from './pages/wallet/create/main';
import WalletCreateType from './pages/wallet/create/type';
import WalletSwapMain from './pages/wallet/swap/main';
import WalletSwapConfirm from './pages/wallet/swap/confirm';

import AdminUserTransaction from './pages/admin/transaction';
import AdminUserWithdrawnList from './pages/admin/withdrawn/list';
import AdminUserList from './pages/admin/user/list';
import AdminRankList from './pages/admin/rank/list';
import AdminETHWallet from './pages/admin/ethwallet';

import ExternalMain from './pages/external/main';
import ExternalFiatInvoiceList from './pages/external/fiat/invoicelist';

import NoAccess from './pages/noaccess';

import { WalletMenu, SettingMenu, AdminMenu } from './components/layout/config/SidebarList';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/base.css';
import './assets/styles/utils.css';
import './assets/styles/loading.css';

const App = ({authPermission})  => {
  const ph = (pageObj,authString) => {
    if(authPermission?.includes(authString) || authString=="all"){
      return pageObj;
    }  
    return <></>
    // return <NoAccess />
  }

  return (
    <Routes>

      <Route element={<DefaultLayout />}>
        <Route path="/" element={ph(<HomePage />, "all")} />
        <Route path="/home" element={ph(<HomePage />, "all")} /> 
      </Route>

      <Route path="/exchange" element={<DefaultLayout />}>
        <Route path="" element={ph(<ExchangeMain />, "exchange")} />
        <Route path=":prodId" element={ph(<ExchangeMain />, "exchange")} />
        <Route path="simple" element={ph(<ExchangeMainSimple />, "exchange")} />
        <Route path="simple/:prodId" element={ph(<ExchangeMainSimple />, "exchange")} />
      </Route>

      <Route path="/setting" element={<SidebarLayout menuList={SettingMenu} />}>
        <Route path="session" element={ph(<SessionMgnt />, "setting")} />
        <Route path="twofa" element={ph(<TwoFaScreen />, "setting")} />
        <Route path="changepassword" element={ph(<ChangePassword />, "setting")} />
        <Route path="notification" element={ph(<NotiSetting />, "setting")} />
        <Route path="apikey" element={ph(<ApikeyPage />, "setting")} />
        <Route path="profile" element={ph(<ProfilePage />, "setting")} />
        <Route path="wallet" element={ph(<WalletSettingPage />, "setting")} />
        <Route path="rank" element={ph(<RankPage />, "setting")} />
        <Route path="" element={ph(<ProfilePage />, "setting")} />
      </Route>

      <Route path="/admin" element={<SidebarLayout menuList={AdminMenu}/>}>
        <Route path="" element={ph(<AdminUserTransaction />, "admin")} />
        <Route path="withdrawn" element={ph(<AdminUserWithdrawnList />, "admin")} />
        <Route path="transaction" element={ph(<AdminUserTransaction />, "admin")} />
        <Route path="user/list" element={ph(<AdminUserList />, "admin")} />
        <Route path="rank/list" element={ph(<AdminRankList />, "admin")} />
        <Route path="eth/wallet" element={ph(<AdminETHWallet />, "admin")} />
      </Route>

      <Route path="/wallet" element={<SidebarLayout menuList={WalletMenu} />}>
        <Route path="list" element={ph(<WalletList />, "wallet")} />
        <Route path="transaction" element={ph(<WalletTransaction />, "wallet")} />
        <Route path="fiat/invoice/list" element={ph(<FiatInvoiceList />, "wallet")} />
        <Route path="fiat/invoice/create" element={ph(<FiatInvoiceCreate />, "wallet")} />
        <Route path="fiat/invoice/:id" element={ph(<FiatInvoiceView />, "wallet")} />
        <Route path="fiat/directtx/:id" element={ph(<FiatDirectTxView />, "wallet")} />
        <Route path="fiat/deposit/:id/create" element={ph(<FiatDepositCreate />, "wallet")} />
        <Route path="fiat/withdrawn/:id/create" element={ph(<FiatWithdrawnCreate />, "wallet")} />
        <Route path="crypto/deposit/:id/create" element={ph(<CryptoDepositCreate />, "wallet")} />
        <Route path="crypto/withdrawn/:id/create" element={ph(<CryptoWithdrawnCreate />, "wallet")} />
        <Route path="exchange/deposit/:typeid" element={ph(<ExchangeDepositCreate />, "wallet")} />
        <Route path="exchange/withdrawn/:typeid" element={ph(<ExchangeWithdrawnCreate />, "wallet")} />
        <Route path="create" element={ph(<WalletCreateMain />, "wallet")} />
        <Route path="create/:maintype/:type" element={ph(<WalletCreateType />, "wallet")} />
        <Route path="swap" element={ph(<WalletSwapMain />, "wallet")} />
        <Route path="swap/confirm" element={ph(<WalletSwapConfirm />, "wallet")} />
        <Route path="overview" element={ph(<WalletOverview />, "wallet")} />
        <Route path="" element={ph(<WalletList />, "wallet")} />
      </Route>

      <Route path="/mobile" element={<MobileLayout />}>
        <Route path="session" element={ph(<SessionMgnt />, "all")} />
      </Route>

      <Route path="/external" element={<BlankLayout />}>
        <Route path="main/:key" element={ph(<ExternalMain />, "all")} />
        <Route path="invoice/:id" element={ph(<FiatInvoiceView />, "all")} />
        <Route path="directtx/:id" element={ph(<FiatDirectTxView />, "all")} />
        <Route path="invoice/find/:key/:keyword" element={ph(<ExternalFiatInvoiceList />, "all")} />
      </Route>

      <Route  element={<LoginLayout />}>
        <Route path="/login" element={ph(<LoginPage />, "all")} />
        <Route path="/resetpassword" element={ph(<ResetPassword />, "all")} />
        <Route path="/resetpassword/:type" element={ph(<ResetPassword />, "all")} />
      </Route>

    </Routes>
  );
}

App.propTypes = {
  authPermission: PropTypes.array
  // loading: PropTypes.bool
};

const mapStateToProps = (state) => ({
  authPermission: state.authentication?.permission,
  // loading: state.loading
});

const mapDispatchToProps = {
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);