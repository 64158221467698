import store from '../redux/store';
import { SERVER_HOST, API_KEY, WS_API_HOST } from './constants';

export async function apiConnect(route, params) {
  try {
    const url = `${SERVER_HOST}${route}`;
    const options = {
      method: 'POST',
      headers: {
        'X-Api-Key': API_KEY
      }
    };
    
    if (store?.getState()?.authentication?.sessiontoken) {
      options.headers.authorization = store?.getState()?.authentication?.sessiontoken;
    } else {
      options.headers.authorization = window.localStorage.getItem('sessiontoken');
    }

    if (params) {
      options.body = params;
    }
    const response = await fetch(url, options);
    if (response?.status !== 200) {
      throw new Error('response not 200');
    }
    return await response.json();
  } catch (error) {
    return error;
  }
}

// export async function wsApiFetch (route,method,body) {
//   try {
//     const url = `${WS_API_HOST}${route}`;
//     const options = {
//       method: (method ? method : "GET"),
//       headers: {
//         // 'X-Api-Key': API_KEY
//       }
//     };
//     if(options.method !== "GET"){
//       options.body = JSON.stringify(body);
//     }
//     const response = await fetch(route, options);
//     // console.log(response);
//     // const res = await response.json();
//     return { ok: true, payload: response};
//   } catch (error) {
//     console.log(error);
//     return { ok: false, msg: error };
//   }
// }

export async function wsApiFetch (route,method,body) {
  try {
    const token = localStorage.getItem('access-token');
    // const jsession = localStorage.getItem('jsession-id');

    // var myHeaders = new Headers();
    // myHeaders.append("Cookie", "JSESSIONID="+jsession+";accessToken="+token);
    // myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Credentials", "include");
    // myHeaders.append("XSRF-TOKEN", token);
    // myHeaders.append("X-XSRF-TOKEN", token);

    let options = {
      method: (method ? method : "GET"),
      redirect: 'follow',
      headers: {
        "Content-Type": "application/json",
        'Accept': 'application/json',
        'Authorization': token
      },
      credentials: 'same-origin'
      // headers: myHeaders,
      // credentials: 'same-origin'
    };
    if(options.method !== "GET"){
      options.body = JSON.stringify(body);
    }
    const response = await fetch(WS_API_HOST + route, options);
    // console.log(response);
    // const res = await response.json();
    return { ok: true, payload: response};
  } catch (error) {
    return { ok: false, msg: error };
  }
}