import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CRYPTO, CURRENCY, ETH_POOL } from '../../utils/crypto';
// import { filterIt } from '../../utils/function';
// import './styles/styles.css';
import AssetList from './components/AssetList';
// import TransactionList from './components/TransactionList';
import { fetchUserWallet } from '../../redux/actions/wallet'

const AdminETHWallet = ({authentication, wallet, fetchUserWalletFn}) => {
  const [ AdminETHWallet, setAdminETHWallet ] = useState();
  const [ adminWallet, setAdminWallet ] = useState([]);
  // const navigate = useNavigate();

  useEffect(() => {
    let walletTemp = {fiat: [], crypto: [], total: 0};
    let total = 0;

    adminWallet?.map((item) => {
      let key = CRYPTO['eth'];
      let usdValue = (item?.balance/1000000000000000000) * key?.usdRatio;
      walletTemp.crypto.push({
        type: "crypto",
        value: (item?.balance/1000000000000000000),
        usdValue: usdValue,
        address: item?.account,
        title: key?.name,
        symbol: key?.symbol,
        id: 'eth',
        icon: key?.icon
      });
      total = total + usdValue;
    })

    walletTemp.total = total;
    setAdminETHWallet(walletTemp);
  } , [adminWallet])

  useEffect(() => {
    const MAIN_PART = "https://api-sepolia.etherscan.io/api?module=account&action=balancemulti&address=";
    const API_PART = "&tag=latest&apikey=KN72S86PA5DEX7DRYPNGRFQ6AXRQUK8ZJ8";
    let url = `${MAIN_PART}${ETH_POOL.toString()}${API_PART}`;
    // fetchUserWalletFn();
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
    fetch(url, requestOptions)
    .then(response => response.json())
    .then(result => setAdminWallet(result?.result))
    .catch(error => console.log('error', error));  
  } , [])

  // const tempdata = {};

  return (
    <center-container>
      <AssetList authentication={authentication} dataList={AdminETHWallet} header={"All Assets"} barButton={true} />
      {/* <TransactionList dataList={tempdata} header={"Latest Operation"} /> */}
    </center-container>
  );

};

const mapStateToProps = (state) => ({
	authentication: state.authentication,
  wallet: state.wallet
});

const mapDispatchToProps = {
	fetchUserWalletFn: fetchUserWallet,
	// checkSecureFunction: checkSecure
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(AdminETHWallet);