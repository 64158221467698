import { SERVER_HOST, FILE_HOST, API_KEY, WS_API_HOST } from "../utils/constants";
import { wsApiFetch } from "../utils/fetch";

export const checkExistingUsers = async (username) => {
  try {
    const url = `${SERVER_HOST}/auth/checkexistinguser`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-Key": API_KEY,
      },
      body: JSON.stringify({username: username}),
    };
    const response = await fetch(url, options);
    if (response.ok) {
      return await response.json();
    }
    return { ok: false };
  } catch (error) {
    return { ok: false };
  }
};

export const checkLoginMethod = async (username) => {
  try {
    const url = `${SERVER_HOST}/auth/checkuser`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-Key": API_KEY,
      },
      body: JSON.stringify({username: username}),
    };
    const response = await fetch(url, options);
    if (response.ok) {
      // const res = await response.json();
      // return res;
      return await response.json();
    }
    return { ok: false };
  } catch (error) {
    return { ok: false };
  }
};

export const setUserProfile = async (sessionToken, payload) => {
  try {
    const url = `${SERVER_HOST}/auth/profile/set`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
        "authorization": sessionToken
      },
      body: JSON.stringify(payload),
    };
    const response = await fetch(url, options);
    if (response.ok) {
      return await response.json();
    }
    return { ok: false };
  } catch (error) {
    return { ok: false };
  }
};

export const setSelfAvatar = async (sessionToken, payload) => {
  try {
    const url = `${SERVER_HOST}/auth/profile/avatar/upload`;
    // const url = `${FILE_HOST}/upload`;
    const formData = new FormData()
    // formData.append('files', payload);
    formData.append('image', payload);

    const options = {
      method: 'POST',
      headers: {
        "x-api-key": API_KEY,
        "authorization": sessionToken
      },
      body: formData,
    };
    const response = await fetch(url, options);
    // const backLoad = await response.json();
    if (response.ok) {
      // return backLoad;
      return await response.json();
    }
    return { ok: false };
  } catch (error) {
    return { ok: false };
  }
};

export const changePasswordApi = async (old, current, sessionToken) => {
  try {
    const url = `${SERVER_HOST}/auth/changepassword`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
        "authorization": sessionToken
      },
      body: JSON.stringify({oldpass: old, currentpass: current}),
    };
    const response = await fetch(url, options);
    const json = await response.json();
    if (json.ok) {
      let payload = json;
      return await payload;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getUserDetail = async (sessiontoken) => {
  try {
    const url = `${SERVER_HOST}/auth/userdetail`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
        "authorization": sessiontoken
      }
    };
    const response = await fetch(url, options);
    const json = await response.json();
    if (json.ok) {
      let payload = json;
      return await payload;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const parseLogin = async (payload) => {
  try {
    const url = `${SERVER_HOST}/auth/createsession/web`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": API_KEY,
      },
      body: JSON.stringify({username: payload?.username, password: payload?.password, otp: payload?.otp}),
    };
    const response = await fetch(url, options);
    if (!response.ok) {
      throw response.status;
    }
    return await response.json();
  } catch (error) {
    return { ok: false, payload: error };
  }
};

export const wsLogin = async (p) => {
  try {
    const response = await wsApiFetch("/api/users/accessToken", "POST", {email: p?.email, password: p?.password, code: "123456"});
    if (!response?.ok) {
      return null;
    }
    const json = await response?.payload.json();
    return json;
  } catch (error) {
    return { ok: false, payload: error };
  }
};


export const parseSignUp = async (payload) => {
  try {
    const url = `${SERVER_HOST}/auth/signup`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-Key": API_KEY,
      },
      body: JSON.stringify({otp: payload?.otp, username: payload?.username, password: payload?.password}),
    };
    const response = await fetch(url, options);
    if (!response?.ok) {
      return null;
    }
    const json = await response.json();
    return json;
  } catch (error) {
    return null;
  }
};

export const parseLogout = async (sessionToken) => {
  try {
    const url = `${SERVER_HOST}/auth/deletesession/web`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
        "authorization": sessionToken
      }
    };
    const response = await fetch(url, options);
    if (response.ok) {
      return await response.json();
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const wsLogout = async (p) => {
  try {
    const response = await wsApiFetch("/api/users/accessToken", "DELETE", null);
    if (!response?.ok) {
      return null;
    }
    return { ok: true };
  } catch (error) {
    return { ok: false, payload: error };
  }
};

export async function sendResetOTP (username) {
  try {
    const url = `${SERVER_HOST}/auth/createresetotp`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": API_KEY
      },
      body: JSON.stringify({ email: username }),
    };
    const response = await fetch(url, options);
    if (response.ok) {
      return await response.json();
    }
    return { ok: false };
  } catch (error) {
    return { ok: false };
  }
}

export async function resetPasswordByOTP (payload) {
  try {
    const url = `${SERVER_HOST}/auth/resetpassword`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": API_KEY
      },
      body: JSON.stringify({ 
        otp: payload?.otp,
        email: payload?.username,
        password: payload?.password,
        repassword: payload?.rePassword,
       }),
    };
    const response = await fetch(url, options);
    if (response.ok) {
      return await response.json();
    }
    return { ok: false };
  } catch (error) {
    return { ok: false };
  }
}

export const enable2FA = async (sessionToken) => {
  try {
    const url = `${SERVER_HOST}/auth/2fa/enable`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
        "authorization": sessionToken
      }
    };
    const response = await fetch(url, options);
    return await response.json();
  } catch (error) {
    return { code: 404, message: "Setting Error" };
  }
};

export const disable2FA = async (sessionToken, code) => {
  try {
    const url = `${SERVER_HOST}/auth/2fa/disable`;
    const formData = new FormData();
    formData.append("otp", code);
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
        "authorization": sessionToken
      },
      body: JSON.stringify({otp: code}),
    };
    const response = await fetch(url, options);
    if (response.ok) {
      return await response.json();
    }
    return { ok: false };
  } catch (error) {
    return { ok: false };
  }
};

export const changeNotiSetting = async (target, value, sessionToken) => {
  try {
    const url = `${SERVER_HOST}/notify/setting`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
        "authorization": sessionToken
      },
      body: JSON.stringify({target: target, value: value}),
    };
    const response = await fetch(url, options);
    const json = await response.json();
    return json;
  } catch (error) {
    return null;
  }
};

export const sessionFetch = async (sessionToken) => {
  try {
    const url = `${SERVER_HOST}/auth/listsession`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-Key": API_KEY,
        "authorization": sessionToken
      }
    };
    const response = await fetch(url, options);
    if (response.ok) {
      return await response.json();
    }
    return { ok: false };
  } catch (error) {
    return { ok: false };
  }
};

export const sessionDelete = async (sessionToken, id) => {
  try {
    const url = `${SERVER_HOST}/auth/removesession`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-Key": API_KEY,
        "authorization": sessionToken
      },
      body: JSON.stringify({id: id}),
    };
    const response = await fetch(url, options);
    if (response.ok) {
      return await response.json();
    }
    return { ok: false };
  } catch (error) {
    return { ok: false };
  }
};