import React, { useState, useEffect } from "react"; 
import { connect } from 'react-redux';
import avatar_placeholder from '../../assets/icon/ui/avatar.svg';
// import { useAuth } from '../../hooks/useAuth';
import SvgIcon from "../../components/svgicon";
import { setUserProfileAction, setUserAvatar } from '../../redux/actions/authentication';

const ProfilePage = ({ authentication, setUserProfileActionFn, setUserAvatarFn }) => {
  const [ avatar, setAvatar ] = useState(avatar_placeholder);
  const [ profileForm, setProfile ] = useState({
    firstname: null, lastname: null, tel: null, systemlang: null,
    currency: null
  });

  useEffect(() => {
    setAvatar(authentication?.avatar);
  } , [authentication])

  useEffect(() => {
    setProfile({
      firstname: authentication?.nameObject?.firstname, 
      lastname: authentication?.nameObject?.lastname, 
      tel: authentication?.dataObject?.tel, 
      systemlang: authentication?.systemObject?.language?.system,
      currency: authentication?.systemObject?.currency,
    });
    // setAvatar(authentication?.avatar);
  } , [])

  const handleChange = (event) => {
    let payload = profileForm;
    payload[event.target.id] = event.target.value;
    setProfile(payload);
  }
  const _updateData = async () => {
    await setUserProfileActionFn(profileForm);
    return false;
  }
  const _updateProfilePicture = async (e) => {
    const files = Array.from(e.target.files)
    await setUserAvatarFn(files[0]);
    return false;
  }

  return (
    <center-container>
      <div className="max-width-700 content-block pb">
        <div className="settings-profile mt-20">
          <avatar-edit-box>
            <edit-button><SvgIcon name="icon-edit" className="icons"/></edit-button>
            <img src={avatar} alt="avatar" />
            <input type="file" onChange={_updateProfilePicture} id="avatar" />
          </avatar-edit-box>

          <div className="form-row">
            <div className="col-md-6 mt-20">
              <label>Firstname</label>
              <input
                  defaultValue={profileForm.firstname}
                  id="firstname" 
                  type="text" 
                  className="data-form-control"
                  onChange={handleChange}
                  placeholder="Firstname" />
            </div>
            <div className="col-md-6 mt-20">
              <label>Lastname</label>
              <input
                  defaultValue={profileForm.lastname}
                  onChange={handleChange} 
                  id="lastname" 
                  type="text" 
                  className="data-form-control" 
                  placeholder="Lastname" />
            </div>
            <div className="col-md-6  mt-20">
              <label>Email <span className="subTitleText">(Can't change)</span></label>
              <input
                  disabled
                  defaultValue={authentication?.email}
                  onChange={handleChange} 
                  id="email"
                  type="text"
                  className="data-form-control"
                  placeholder="Enter your email" />
            </div>
            <div className="col-md-6  mt-20">
              <label>Phone</label>
              <input
                  defaultValue={profileForm.tel}
                  onChange={handleChange}
                  id="tel"
                  type="number"
                  className="data-form-control"
                  placeholder="Enter phone number" />
            </div>
            <div className="col-md-6 mt-20">
              <label>Language</label>
              <select id="systemlang" defaultValue={profileForm.language} 
                className="custom-select" onChange={handleChange}>
                <option value="en">English</option>
                <option value="th">Thai</option>
              </select>
            </div>
            <div className="col-md-6 mt-20">
              <label>Currency</label>
              <select id="currency" defaultValue={profileForm?.currency} 
                className="custom-select" onChange={handleChange}>
                <option value="thb">THB</option>  
                <option value="usd">USD</option>
                <option value="eur">EUR</option>
                <option value="gbp">GBP</option>
              </select>
            </div>
          </div>
        </div>
        <button-block>
          <circle-button onClick={() => _updateData()}>Save</circle-button>
        </button-block>
      </div>
    </center-container>
  )
}

const mapStateToProps = (state) => ({
	authentication: state.authentication
});

const mapDispatchToProps = {
	setUserProfileActionFn: setUserProfileAction,
  setUserAvatarFn: setUserAvatar
	// checkSecureFunction: checkSecure
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(ProfilePage);