import { SERVER_HOST, API_KEY } from "./constants";

export async function sendOTP (username, password) {
  try {
    const url = `${SERVER_HOST}/otp/create/signup`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": API_KEY
      },
      body: JSON.stringify({username: username, password: password}),
    };
    await fetch(url, options);
    return null;
  } catch (error) {
    return null;
  }
}

export async function verifyCodeOTP (payload, endPoint) {
  try {
    const res = await fetch(`${SERVER_HOST}/otp/verify/${endPoint}`, {
      method: 'POST',
      headers: {
        "x-api-key": API_KEY
      },
      body: JSON.stringify({otp: payload?.otp, username: payload?.username, password: payload?.password }),
    });
    const result = await res.json();
    console.log(result);
    if (result.ok) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}