// import { useOutlet } from 'react-router-dom';
import { useOutlet, useNavigate } from 'react-router-dom';
import SvgIcon from '../../components/svgicon';
import '../../assets/styles/login.css';

const BlankLayout = () => {
  const outlet = useOutlet();
  let navigate = useNavigate();
  return (
    <>
      <back-icon onClick={()=> navigate(-1)}>
        <SvgIcon name="icon-back" className="login-page-icon"/>
      </back-icon>
      <div className="blank-box">
      {outlet}
      </div>
    </>
  );
};

export default BlankLayout;