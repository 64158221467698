import { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { sendResetOTPMethod } from '../../redux/actions/authentication';

import { EmailBox } from './components/EmailBox';
import { ResetOtpBox } from './components/ResetOtpBox';
import { ResetPassBox } from './components/ResetPassBox';

import { HOME_PATH } from '../../utils/constants';

import PropTypes from 'prop-types';
import logo from '../../assets/images/logo-white@512.png';
import signin_cover from '../../assets/images/signin-cover.jpg';

export const ResetPassword = ({ sendResetOTPFunction }) => {
  let navigate = useNavigate();
  let { type } = useParams();
  const [ currentPage, setCurrentPage ] = useState('emailBox');
  const [ username, setUsername ] = useState(null);
  const [ otp, setOtp ] = useState(null);

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
        return re.test(email);
  };

  const checkUser = async () => {
    if(!validateEmail(username)){
      return null;
    }
    const response = await sendResetOTPFunction(username);
    if(response.ok){
        goNext('otpBox');
    }
    return null;
  };

  const goNext = (next) => {
    setCurrentPage(next);
  }

  const handleComplete = () => {
    if(type === "mobile"){
      postMessage(JSON.stringify({ mode: "loginPage" }));
      return true;
    }
    navigate(HOME_PATH, { replace: true });
    return true;
  }

  const renderComplete = () => {
    return (
        <>
        <text-title>Complete</text-title>
        <text-subtitle>Your Password Has Been Reset</text-subtitle>
        <button-block>
            <circle-button onClick={handleComplete}>Next</circle-button>
        </button-block>
      </>
    );
  }

  const renderContent = () => {
    switch (currentPage) {
      case 'emailBox':
        return <EmailBox username={username} setUsername={setUsername} checkUser={checkUser}/>
      case 'otpBox':
        return <ResetOtpBox setOtp={setOtp} goNext={goNext}/>
      case 'resetPass':
        return <ResetPassBox otp={otp} username={username} goNext={goNext}/>
      case 'completeScreen':
        return renderComplete();
      default:
        return <EmailBox username={username} setUsername={setUsername} checkUser={checkUser}/>
    }
  }

  return (
    <>
      <login-box>
        <img className="herobox-logo" src={logo}/>
        <img className="herobox" src={signin_cover}/>
        <box-content>
          {renderContent()}
        </box-content>
      </login-box>
    </>
  );

};

ResetPassword.propTypes = {
    sendResetOTPFunction: PropTypes.func
};

const mapDispatchToProps = {
    sendResetOTPFunction: sendResetOTPMethod
};

export default connect(
    null,
    mapDispatchToProps
)(ResetPassword);