import { SERVER_HOST, API_KEY } from "../utils/constants";

export const getFiatInvoiceApi = async (sessionToken, type, payload) => {
  try {
    const url = `${SERVER_HOST}/fiat/invoice/fetch/${type}`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-Key": API_KEY,
        "authorization": sessionToken
      },
      body: JSON.stringify(payload),
    };
    const response = await fetch(url, options);
    if (response.ok) {
      return await response.json();
    }
    return { ok: false };
  } catch (error) {
    return { ok: false };
  }
};

export const getFiatInvoiceByIdApi = async (id) => {
  try {
    const url = `${SERVER_HOST}/fiat/invoice/${id}`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-Key": API_KEY
      }
    };
    const response = await fetch(url, options);
    if (response.ok) {
      return await response.json();
    }
    return { ok: false };
  } catch (error) {
    return { ok: false };
  }
};

export const getFiatPaymentChannelApi = async () => {
  try {
    const url = `${SERVER_HOST}/fiat/channel/fetch`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-Key": API_KEY
      }
    };
    const response = await fetch(url, options);
    if (response.ok) {
      return await response.json();
    }
    return { ok: false };
  } catch (error) {
    return { ok: false };
  }
};

export const getFiatTransactionByIdApi = async (sessionToken, payload) => {
  try {
    const url = `${SERVER_HOST}/fiat/transaction`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-Key": API_KEY,
        "authorization": sessionToken
      },
      body: JSON.stringify(payload),
    };
    const response = await fetch(url, options);
    if (response.ok) {
      return await response.json();
    }
    return { ok: false };
  } catch (error) {
    return { ok: false };
  }
};

export const createFiatWithdrawal = async (sessionToken, payload) => {
  try {
    const url = `${SERVER_HOST}/fiat/withdrawal/create`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-Key": API_KEY,
        "authorization": sessionToken
      },
      body: JSON.stringify(payload),
    };
    const response = await fetch(url, options);
    // console.log(response);
    const data = await response.json();
    // console.log(data);
    if (response.ok) {
      // return await response.json();
      return data;
    }
    return { ok: false };
  } catch (error) {
    return { ok: false };
  }
};

export const createFiatInvoiceApi = async (sessionToken, payload, optionPayload, channelPayload, responsePayload) => {
    try {
      const url = `${SERVER_HOST}/fiat/invoice/create`;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-Key": API_KEY,
          "authorization": sessionToken
        },
        body: JSON.stringify({
          amount: payload.amount, 
          unit: payload.unit,
          walletObj: payload.walletObj,
          walletAdress: payload.walletAdress,
          option: optionPayload, 
          channel: channelPayload,
          acceptCallBack: responsePayload.acceptCallBack,
          rejectCallBack: responsePayload.rejectCallBack,
          acceptRedirect: responsePayload.acceptRedirect,
          rejectRedirect: responsePayload.rejectRedirect
        }),
      };
      const response = await fetch(url, options);
      console.log(response);
      if (response.ok) {
        return await response.json();
      }
      return { ok: false };
    } catch (error) {
      return { ok: false };
    }
};

export const createFiatTransactionApi = async (sessionToken, payload) => {
  try {
    const url = `${SERVER_HOST}/fiat/transaction/create`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-Key": API_KEY,
        "authorization": sessionToken
      },
      body: JSON.stringify(payload),
    };
    const response = await fetch(url, options);
    if (response.ok) {
      return await response.json();
    }
    return { ok: false };
  } catch (error) {
    return { ok: false };
  }
};