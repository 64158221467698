import { loading } from './loading';
import {
    getFiatInvoiceApi,
    getFiatInvoiceByIdApi,
    getFiatTransactionByIdApi,
    createFiatInvoiceApi,
    createFiatTransactionApi
} from "../../api/fiat";

export const getFiatInvoice = (type, payload) => {
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      const sessiontoken = window.localStorage.getItem('sessiontoken');
      const response = await getFiatInvoiceApi(sessiontoken, type, payload);
      if (!response?.ok) {
        throw response?.message;
      }
      const json = response;
      dispatch(loading(false));
      return json;
    } catch (error) {
      dispatch(loading(false));
      const payload = {ok: false};
      return payload;
    }
  };
};

export const getFiatInvoiceById = (id) => {
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      const response = await getFiatInvoiceByIdApi(id);
      if (!response?.ok) {
        throw response?.message;
      }
      const json = response;
      dispatch(loading(false));
      return json;
    } catch (error) {
      dispatch(loading(false));
      const payload = {ok: false};
      return payload;
    }
  };
};

export const getFiatTransactionById = (id, type) => {
  return async (dispatch) => {
    // dispatch(loading(true));
    try {
      const typeSelect = type ? type : "status";
      const payload = {
        mode: typeSelect,
        transactionId: id
      };
      const sessiontoken = window.localStorage.getItem('sessiontoken');
      const response = await getFiatTransactionByIdApi(sessiontoken, payload);
      if (!response?.ok) {
        throw response?.message;
      }
      const json = response;
      // dispatch(loading(false));
      return json;
    } catch (error) {
      // dispatch(loading(false));
      const payload = {ok: false};
      return payload;
    }
  };
};

export const createFiatInvoice = (payload, optionPayload, channelPayload, responsePayload) => {
    return async (dispatch) => {
        dispatch(loading(true));
        try {
          const sessiontoken = window.localStorage.getItem('sessiontoken');
          const response = await createFiatInvoiceApi(sessiontoken, payload, optionPayload, channelPayload, responsePayload);
          if (!response?.ok) {
            throw response?.message;
          }
          const json = response;
          dispatch(loading(false));
          return json;
        } catch (error) {
          dispatch(loading(false));
          const payload = {ok: false};
          return payload;
        }
    };
}

export const createFiatTransaction = (payload) => {
  return async (dispatch) => {
      dispatch(loading(true));
      try {
        const sessiontoken = window.localStorage.getItem('sessiontoken');
        const response = await createFiatTransactionApi(sessiontoken, payload);
        // console.log("createFiatTransactionApi", response);
        if (!response?.ok) {
          throw response?.message;
        }
        const json = response;
        dispatch(loading(false));
        return json;
      } catch (error) {
        dispatch(loading(false));
        const payload = {ok: false};
        return payload;
      }
  };
}
