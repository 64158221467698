export const CURRENCY_ICON = {
  usd: require("./icon/flag/etats-unis.png"),
  thb: require("./icon/flag/thailand.png"),
}

export const BANK_ICON = {
  bbl: require("./icon/fiat/bbl.png"),
  kbank: require("./icon/fiat/kbank.png"),
  ktb: require("./icon/fiat/ktb.png"),
  scb: require("./icon/fiat/scb.png"),
  thaiqr: require("./icon/fiat/thaiqr.png"),
}

export const CRYPTO_ICON = {
  Iinch: require("./icon/coin/1inch.png"),
  aave: require("./icon/coin/aave.png"),
  ada: require("./icon/coin/ada.png"),
  aergo: require("./icon/coin/aergo.png"),
  ant: require("./icon/coin/ant.png"),
  aoa: require("./icon/coin/aoa.png"),
  ast: require("./icon/coin/ast.png"),
  band: require("./icon/coin/band.png"),
  bat: require("./icon/coin/bat.png"),
  bch: require("./icon/coin/bch.png"),
  bnt: require("./icon/coin/bnt.png"),
  bsv: require("./icon/coin/bsv.png"),
  btc: require("./icon/coin/btc.png"),
  btcl: require("./icon/coin/btc.png"),
  btg: require("./icon/coin/btg.png"),
  chz: require("./icon/coin/chz.png"),
  crv: require("./icon/coin/crv.png"),
  cvc: require("./icon/coin/cvc.png"),
  dash: require("./icon/coin/dash.png"),
  data: require("./icon/coin/data.png"),
  doge: require("./icon/coin/doge.png"),
  elf: require("./icon/coin/elf.png"),
  enj: require("./icon/coin/enj.png"),
  eos: require("./icon/coin/eos.png"),
  eth: require("./icon/coin/eth.png"),
  eurs: require("./icon/coin/eurs.png"),
  fet: require("./icon/coin/fet.png"),
  ftm: require("./icon/coin/ftm.png"),
  gno: require("./icon/coin/gno.png"),
  grt: require("./icon/coin/grt.png"),
  gto: require("./icon/coin/gto.png"),
  hbar: require("./icon/coin/hbar.png"),
  hot: require("./icon/coin/hot.png"),
  key: require("./icon/coin/key.png"),
  knc: require("./icon/coin/knc.png"),
  lba: require("./icon/coin/lba.png"),
  link: require("./icon/coin/link.png"),
  loom: require("./icon/coin/loom.png"),
  lrc: require("./icon/coin/lrc.png"),
  ltc: require("./icon/coin/ltc.png"),
  matic: require("./icon/coin/matic.png"),
  mith: require("./icon/coin/mith.png"),
  mkr: require("./icon/coin/mkr.png"),
  mtl: require("./icon/coin/mtl.png"),
  nexo: require("./icon/coin/nexo.png"),
  ocean: require("./icon/coin/ocean.png"),
  omg: require("./icon/coin/omg.png"),
  poly: require("./icon/coin/poly.png"),
  powr: require("./icon/coin/powr.png"),
  ppt: require("./icon/coin/ppt.png"),
  rdn: require("./icon/coin/rdn.png"),
  rep: require("./icon/coin/rep.png"),
  rvn: require("./icon/coin/rvn.png"),
  sand: require("./icon/coin/sand.png"),
  snt: require("./icon/coin/snt.png"),
  storj: require("./icon/coin/storj.png"),
  storm: require("./icon/coin/storm.png"),
  sushi: require("./icon/coin/sushi.png"),
  sxp: require("./icon/coin/sxp.png"),
  tnt: require("./icon/coin/tnt.png"),
  uni: require("./icon/coin/uni.png"),
  usdc: require("./icon/coin/usdc.png"),
  usdt: require("./icon/coin/usdt.png"),
  wbtc: require("./icon/coin/wbtc.png"),
  xlm: require("./icon/coin/xlm.png"),
  xmr: require("./icon/coin/xmr.png"),
  xrp: require("./icon/coin/xrp.png"),
  yfi: require("./icon/coin/yfi.png"),
  zec: require("./icon/coin/zec.png"),
  zrx: require("./icon/coin/zrx.png")
};
