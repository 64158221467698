import React, { useState, useCallback, useEffect } from "react"; 
import { connect } from 'react-redux';
import Switch from "react-switch";
import { changeNotiConfig } from '../../redux/actions/authentication';
import SvgIcon from '../../components/svgicon';

const NotiSetting = ({ authentication, changeNotiConfigFunction }) => {
	// checkSecureFunction();
	// const { fetchprofile } = useAuth();
	// fetchprofile();
	const [dataLoad, setDataLoad] = useState(true);
	let preData = authentication.notiSetting;
	const settingEnable = [
		{key: 'login', icon: 'icon-noti', lable: 'Login'},
		{key: 'news', icon: 'icon-noti', lable: 'Newsletter'},
		{key: 'setting', icon: 'icon-noti', lable: 'Setting'},
		{key: 'profilesetting', icon: 'icon-noti', lable: 'Profile Setting'},
		{key: 'apikey', icon: 'icon-noti', lable: 'API Setting'}
	];
	const preStatusPending = {};
	const preNotiPreSetting = {};
	settingEnable.map(v => { preStatusPending[v.key] = false});
	settingEnable.map(v => { preNotiPreSetting[v.key] = (preData[v.key] === 'undefined') ? false : preData[v.key]});
	const [statusPending, setStatusPending] = useState(preStatusPending);
	const [notiSetting, setNotiSetting] = useState(preNotiPreSetting);

	const toggleSwitch = useCallback(async (type) => {
        const payload = notiSetting;
        payload[type] = payload[type] == true ? false : true;
        const response = await changeNotiConfigFunction(type, payload[type]);
        if(response.ok){
			let payloadTemp = {};
			settingEnable.map(v => { payloadTemp[v.key] = (payload[v.key] === 'undefined') ? false : payload[v.key]});
			setNotiSetting(payloadTemp);
        }
        return false;
	}, [ notiSetting ])

	useEffect(() => {
        if(authentication.username!='' && dataLoad) {
			const payload = authentication.notiSetting;
			let payloadTemp = {};
			settingEnable.map(v => { payloadTemp[v.key] = (payload[v.key] === 'undefined') ? false : payload[v.key]});
			setNotiSetting(payloadTemp);
            setDataLoad(false);
        }
    } , [authentication])

	return (
    <center-container>
		<div className="max-width-600 content-block">
		<screen-style>
		<SvgIcon name="icon-noti" className="iconImg green-filter"/>
		<div className="titleHeader">Notification Setting</div>
		<div className="titleSubHeader">We can send several notification to your account including login notification which tell about your account access and more, BTW if you doesn't want these notification, you can switch it off in this screen</div>
		</screen-style>

        <div className="list-style-container mt-30">
			{settingEnable.map(v => (
			<div key={v.key+'847de'} className="item">
				<div className="iconContainer">
					<div className="iconBox">
						<SvgIcon name={v.icon} className="iconImg green-filter"/>
					</div>
				</div>
				<div className="leftContainer">
					<span className="titleText">{v.lable}</span>
				</div>
				<div className="rightContainer">
					{statusPending[v.key] ? (
						<span>Loading</span>
					) : (
						<Switch 
							onChange={() => toggleSwitch(v.key)} 
							checked={notiSetting[v.key]} 
							offColor={"#FF5959"} 
							onColor={"#8BCA65"}
							uncheckedIcon={false}
							checkedIcon={false}
						/>
					)}
				</div>
			</div>
			))}
		</div>

		</div>
	</center-container>
	);	
}

const mapStateToProps = (state) => ({
	authentication: state.authentication
});

const mapDispatchToProps = {
	changeNotiConfigFunction: changeNotiConfig,
	// checkSecureFunction: checkSecure
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(NotiSetting);