import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CRYPTO, CURRENCY } from '../../utils/crypto';
// import { filterIt } from '../../utils/function';
import './styles/styles.css';
import AssetList from './components/AssetList';
// import TransactionList from './components/TransactionList';
import { fetchUserWallet, fetchExchangeWallet, fetchAllWallet } from '../../redux/actions/wallet';
import { wsSubscribe, wsUnSubscribe } from "../../redux/actions/socket";
import ExAssetList from "./components/ExAssetList";

const WalletList = ({authentication, wallet, fetchUserWalletFn, fetchExchangeWalletFn, wsSubscribeFn}) => {
  const [ walletList, setWalletList ] = useState();
  // const navigate = useNavigate();

  useEffect(() => {
    let walletTemp = {fiat: [], crypto: [], exchange: [], total: 0};
    let total = 0;
    wallet?.fiat?.map((item) => {
      let key = CURRENCY[item?.typeid];
      let usdValue = item?.amount * key?.usdRatio;
      walletTemp.fiat.push({
        type: "fiat",
        value: item?.amount,
        usdValue: usdValue,
        address: item?.address,
        title: key?.name,
        symbol: key?.symbol,
        id: item?.id,
        icon: key?.icon
      });
      total = total + usdValue;
    })

    wallet?.crypto?.map((item) => {
      let key = CRYPTO[item?.typeid];
      let usdValue = item?.amount * key?.usdRatio;
      walletTemp.crypto.push({
        type: "crypto",
        value: item?.amount,
        usdValue: usdValue,
        address: item?.address,
        title: key?.name,
        symbol: key?.symbol,
        id: item?.id,
        icon: key?.icon
      });
      total = total + usdValue;
    })

    wallet?.exchange?.map((item) => {
      let key = CRYPTO[item?.typeid] || CURRENCY[item?.typeid];
      let usdValue = item?.amount * key?.usdRatio;
      walletTemp.exchange.push({
        type: "exchange",
        value: item?.amount,
        usdValue: usdValue,
        address: item?.address,
        title: key?.name,
        symbol: key?.symbol,
        id: item?.id,
        icon: key?.icon
      });
      total = total + usdValue;
    })

    walletTemp.total = total;
    setWalletList(walletTemp);
  } , [wallet])

  const fetchPreData = async () => {
    await fetchUserWalletFn();
    // await fetchExchangeWalletFn();
    // wsSubscribeFn('funds', {
    //   'type': 'subscribe',
    //   'currency_ids': ['BTC', 'USDT', 'ETH', 'THB'],
    //   'channels': ['funds']
    // });
  }

  useEffect(() => {
    fetchPreData();
  } , [])

  // const tempdata = {};

  return (
    <center-container>
      <AssetList authentication={authentication} dataList={walletList} header={"Funding Assets"} barButton={true} />
      <ExAssetList authentication={authentication} dataList={walletList} header={"Exchange Assets"} />
      {/* <TransactionList dataList={tempdata} header={"Latest Operation"} /> */}
    </center-container>
  );

};

const mapStateToProps = (state) => ({
	authentication: state.authentication,
  wallet: state.wallet
});

const mapDispatchToProps = {
	fetchUserWalletFn: fetchUserWallet,
  fetchExchangeWalletFn: fetchExchangeWallet,
  wsSubscribeFn: wsSubscribe,
  // wsUnSubscribeFn: wsUnSubscribe
	// checkSecureFunction: checkSecure
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(WalletList);