import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
// import { UI_ICON } from '../../assets/uiicon';
import avatar_placeholder from '../../assets/icon/ui/avatar.svg';
import SvgIcon from '../../components/svgicon';

const UserMenu = ({ authentication }) => {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [ avatar, setAvatar ] = useState(avatar_placeholder);
    const [ showName, setName ] = useState(authentication.objectID);

    useEffect(() => {
      // console.log(authentication);
      if(!authentication.nameObject?.firstname){
        setName(authentication.objectID);
      } else {
        setName(authentication.nameObject?.firstname+" "+authentication.nameObject?.lastname);
      }
      setAvatar(authentication?.avatar);
      // setName(authentication.objectID);
    } , [authentication])

    return (
      <div className="nav-item dropdown header-img-icon">
        <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img src={avatar} alt="avatar" className="navlink-usermenu-avatar" />
        </a>
        <div className="dropdown-menu">
          <div className="dropdown-header d-flex flex-column align-items-center">
            <div className="figure mb-3">
              <img src={avatar} alt="" className="navlink-usermenu-bigavatar" />
            </div>
            <div className="info text-center pb-20">
              <p className="name font-weight-bold mb-0">{showName}</p>
              <p className="email text-muted">{authentication.email}</p>
              <div className={"rank-title all-center rank-color"+authentication?.role?.badge?.color}>
                {authentication?.role?.badge?.name}
              </div>
            </div>
          </div>
          <div className="dropdown-body">
            <ul className="profile-nav">
              <li className="nav-item">
                <a onClick={() => navigate('/setting')} className="nav-link">
                  <SvgIcon name="icon-setting" className="topbar-submenu-icon" />
                  <span>Settings</span>
                </a>
              </li>
              <li className="nav-item">
                <a onClick={logout} className="nav-link red font-b">
                  <SvgIcon name="icon-lock" className="topbar-submenu-icon-red" />
                  <span>Log Out</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
};

const mapStateToProps = (state) => ({
  authentication: state?.authentication
});

export default connect(mapStateToProps)(UserMenu);
