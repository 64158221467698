import { useNavigate } from 'react-router-dom';
import WalletTypeBlock from "../../../components/walletTypeBlock";
import SvgIcon from '../../../components/svgicon';

import './styles/create.css';

const WalletCreateMain = () => {
  const navigate = useNavigate();

  const handleClick = (data, maintype) => {
    navigate(`/wallet/create/${maintype}/${data?.typeid}`);
    // console.log(data);
  }
  return (
      <center-container>
        <div className="max-width-600 content-block">
        <screen-style>
        <SvgIcon name="icon-wallet" className="iconImg red-filter"/>
        <div className="titleHeader">Supported Assets</div>
        <div className="titleSubHeader">You can create new wallet for your new assets type, both FIAT or CRYPTO. by select from our supported list below</div>
        </screen-style>
          <div className="mt-30">
            <WalletTypeBlock handleOnClick={handleClick} />
          </div>
        </div>
      </center-container>
  );
};

export default WalletCreateMain;