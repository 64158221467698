import React from "react"; 
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash'
import { makeid } from "../../../utils/function";
import { CRYPTO, CURRENCY } from "../../../utils/crypto";

const WalletList = ({ wallet }) => {

  const renderRow = (item) => {
    // const dateString = dayjs(item.time).tz(authentication?.settingObject.dateZone).local().format('HH:mm');
    // const sideColor = (item.side == "sell") ? "red" : "green";
    return (
      <tr key={makeid(5)}>
        <td><img className="currency-icon" src={CRYPTO[item.typeid]?.icon || CURRENCY[item.typeid]?.icon } alt="" />{' '+item.symbol}</td>
        <td><p>{parseFloat(item.amount).toFixed(2)}</p><p className="hold-amount">{parseFloat(item.hold).toFixed(2)}</p></td>
      </tr>
    )
  }

  const renderAllRow = () => {
    if(isEmpty(wallet)){
      return <div className="no-data-content"><span className="no-data"><i className="icon ion-md-document"></i>No data</span></div>;
    }
    return (
      <div className="content">
      <table className="trade-table">
        <thead>
          <tr>
            <th>crypto</th>
            <th>amount</th>
          </tr>
        </thead>
        <tbody>
          {wallet.map((item) => {
            return renderRow(item)
          })}
        </tbody>
      </table>
      </div>
    );
  }

  return (
    <div className="trade-block-content">
      <div className="header">Wallet</div>
      {renderAllRow()}
    </div>
  );
};

WalletList.propTypes = {
  data: PropTypes.array,
  trigger: PropTypes.number,
  product: PropTypes.object
};

const mapStateToProps = (state) => ({
  wallet: state.wallet.exchange
});

export default connect(
mapStateToProps,
null
)(WalletList);