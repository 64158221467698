import React, { useState, useEffect } from "react"; 
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { BANK_ICON } from '../assets/cryptoicon';

const WalletRadioInput = ({ inputHandle, inputData, inputDefault, authentication }) => {
    const [radioSetting, setRadioSetting] = useState();

    const radioSelector = (item) => {
        setRadioSetting(item.id);
        inputHandle(item);
    }

    useEffect(() => {
        if(inputDefault){
            setRadioSetting(inputDefault);
        }
    },[inputDefault]);

    const renderData = (item) => {
        return (
            <div className="item cur-pointer" key={item.id+"-Bgvf12"} onClick={()=>radioSelector(item)} data-dismiss="modal">
				<div className="iconContainer">
                    <img src={item.icon} className="iconBox"/>
				</div>
				<div className="leftContainer">
					<div className="titleText">{item.title}</div>
                    <div className="subTitleText">{item.address}</div>
				</div>
                <div className="rightContainer">
                 {(radioSetting === item.id ) ? (
                    <div className="select-circle">
                        <div className="selected" />
                    </div>
                 ) : (
                    <div className="select-circle" />
                 )}   
				</div>
			</div>
        );
    }

    return (
        <div className="list-blank-container">
            {_.isEmpty(inputData.fiat) ? (
                null
            ) : (
                inputData.fiat.map((item) =>  {
                    return renderData(item)
                })
            )}

            {_.isEmpty(inputData.crypto) ? (
                null
            ) : (
                inputData.crypto.map((item) =>  {
                    return renderData(item)
                })
            )}
        </div>
    );
};

WalletRadioInput.propTypes = {
    label: PropTypes.string,
    inputHandle: PropTypes.func,
    inputDefault: PropTypes.string
};

const mapStateToProps = (state) => ({
	authentication: state.authentication
});

export default connect(
    mapStateToProps,
    null
)(WalletRadioInput);