export const filterIt = (dataList, value, key) => {
  // return array.filter(key
  //     ? a => a[key] === value
  //     : a => Object.keys(a).some(k => a[k] === value)
  // );
  let dataResponse = {};
  Object.keys(dataList).map((k) =>  {
    if(dataList[k][key]== value){
      dataResponse = dataList[k];
    }
  })
  return dataResponse;
}

export const arrayFilterIt = (dataList, value, key) => {
  let dataResponse = {};
  dataList.map((k) =>  {
    if(k[key]== value){
      dataResponse = k;
    }
  })
  return dataResponse;
}

export const makeid = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const formatNumber = (number, option) => {
  // console.log(option);
  let lenght = 8;
  // console.log("formatNumber", option);
  if (typeof number === 'undefined') { return ''}
  number = parseFloat(number);
  if (typeof option !== 'undefined') { 
    if(option?.type == "crypto"){
      lenght = option?.crypMaxFrac; 
    } else if(option?.type == "exchange"){  
      lenght = option?.crypMaxFrac; 
    } else {
      lenght = option?.maxFrac; 
    }
  }

  return number.toLocaleString('en-US', {maximumFractionDigits: lenght});
}

export const hiddenSomeText = (text) => {
  if (typeof text === 'undefined') { return ''}
  if(!text){return ''}
  try {
    const data = 'XXXX'+text.substr(text.length - 5);
    // return 'XXXX'+text.substr(text.length - 5);
    return data;
  } catch (err) {
    return '';
  }
}