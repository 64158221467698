import { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import UserMenu from '../menu/UserMenu';
import MobileUserMenu from '../menu/MobileUserMenu';
import MobileUserProfileMenu from '../menu/MobileUserProfileMenu';
import NotiMenu from '../menu/NotiMenu';
import SvgIcon from '../../components/svgicon';

export const AppBar = ({ authentication, pages, barStyle }) => {
  const [activeTab, setActiveTab] = useState(window.location.pathname.split('/')[1] || null);
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    if (!path) {
      setActiveTab(null);
      return null;
    }
    setActiveTab(path?.id);
    navigate(path?.path);
  };
  // <header className={( transparent.includes(activeTab) ? " transparent": "bgfilled")}></header>
  return (
    <header className={( barStyle ? barStyle: "bgfilled")}>
    <nav className="navbar navbar-expand-lg">
      <div className="d-block d-md-none full-width">
        <div
          className="mobile-menu-container"
          data-toggle="collapse" 
          data-target="#mobileHeaderMenu" 
          aria-controls="mobileHeaderMenu" 
          aria-expanded="false" aria-label="Toggle navigation">
            <div className="col-xs-3 col-sm-3 col-md-3 justify-content-start"><SvgIcon name="icon-menu-2" className="mobilenav-link-menu" /></div>
            <div className="col-xs-6 col-sm-6 col-md-6"><home-mobile-logo /></div>
            <div className="col-xs-3 col-sm-3 col-md-3"></div>
        </div>
      </div>
      <div className="collapse mobile-menu" id="mobileHeaderMenu">
        <a className="mobilenav-link-style" 
          key="mobile-close-m1" 
          data-toggle="collapse" 
          data-target="#mobileHeaderMenu" 
          aria-controls="mobileHeaderMenu" 
          aria-expanded="false" aria-label="Toggle navigation">
          <SvgIcon name="icon-xmark-solid" className="mobilenav-link-close" />
        </a>
        <MobileUserProfileMenu />
        <ul>
        {pages?.map((page) => {
          if(authentication?.permission?.includes(page?.auth) || page?.auth == "all"){
            return (
              <li key={page.label+"983kqd"}>
                <a key={page.label+"983kf"}
                  className="mobilenav-link-style" 
                  data-toggle="collapse"
                  data-target="#mobileHeaderMenu" 
                  aria-controls="mobileHeaderMenu" 
                  aria-expanded="false" aria-label="Toggle navigation"
                  onClick={() => handleNavigate(page)} 
                  role="button"
                  >
                  <SvgIcon name={page.icon} className="mobilenav-main-icon" />{page.label}
                </a>
              </li>
            )
          }
          })}
        </ul>
        {authentication?.objectID ? (
            <>
              <MobileUserMenu />
            </>
          ) : (
            <ul className="mobilenav-border">
              <li key={"983kfx"}>
                <a
                  onClick={() => handleNavigate({path: '/login'})}
                  className="mobilenav-link-style red" 
                  key="mobile-close-qeq" 
                  data-toggle="collapse" 
                  data-target="#mobileHeaderMenu" 
                  aria-controls="mobileHeaderMenu" 
                  aria-expanded="false" aria-label="Toggle navigation"
                  >
                  <SvgIcon name="icon-lock" className="mobilenav-main-icon-red" />
                  Sign Up
                </a>
              </li>
            </ul>
          )}
      </div>

      <div className="collapse navbar-collapse" id="headerMenu">
        <div className="col-md-3 justify-content-start"><home-logo /></div>  
        <div className="justify-content-center navbar-collapse center-menu">
        {pages?.map((page) => {
          if(authentication?.permission?.includes(page?.auth) || page?.auth == "all"){
            return (
            <a key={page.id+"83ddsf"} className={"nav-link"+(activeTab === page?.id ? ' nav-link-style-active' : ' nav-link-style')} 
              onClick={() => handleNavigate(page)} 
              role="button">
              {/* <SvgIcon name={page.icon}
                className={(activeTab === page?.id ? 'topbar-main-icon-active' : 'topbar-main-icon')} /> */}
                {page.label}
            </a>
            )
          }
        })}
        </div>
        <div className="col-md-3 navbar-nav justify-content-end">
          <div className="nav-item header-custom-icon">
            <a className="nav-link" href="#" role="button">
              <SvgIcon name="icon-menu-1" className="topbar-icon" />
            </a>
          </div>
          {authentication?.objectID ? (
            <>
               <div className="nav-item header-custom-icon">
                <a className="nav-link dropdown-toggle" href="#" role="button">
                  <SvgIcon name="icon-chat" className="topbar-icon" />
                </a>
              </div>
              <NotiMenu />
              <UserMenu />
            </>
          ) : (
            <div className="nav-link">
              <div className="round-button more-round-button pink" onClick={() => handleNavigate({path: '/login'})}>
                <SvgIcon name="icon-lock" className="topbar-main-icon-active" />
                Sign Up
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  </header>
  );
};

AppBar.propTypes = {
  authentication: PropTypes.object,
  pages: PropTypes.array,
  barStyle: PropTypes.string
  // transparent: PropTypes.array
};