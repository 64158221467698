import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _ from "lodash";
import { COINLIST } from '../../utils/crypto';
// import { filterIt } from '../../utils/function';
// import AssetList from './components/AssetList';
// import TransactionList from './components/TransactionList';
import FlatTable from "../../components/table/flatTable";
import { fetchAllSwapTranaction } from '../../redux/actions/wallet';
import { formatNumber, hiddenSomeText } from '../../utils/function';
import SvgIcon from '../../components/svgicon';
import './styles/styles.css';

const WalletTransaction = ({authentication, wallet, fetchAllSwapTranactionFn}) => {
  const [ dataList, setDataList ] = useState([]);
  const navigate = useNavigate();

  const headerList = [
    {title: "", objId: "showType", type: "one-line"}, 
    {title: "From", objId: "fromShow", type: "one-line"}, 
    {title: "To", objId: "toShow", type: "one-line"},
    {title: "RefID", objId: "refId", type: "one-line"}
    // {title: "", objId: "func", type: "one-line"}
  ];

  // const handleNavigate = (data) => {
  //   alert(data);
  // }

  const preparedData = (data) => {
    let finalData = data.payload;
    if(_.isEmpty(finalData)){return null; }
    finalData.map((item, key) => {
      finalData[key]["showType"] = (
        <div className="table-currency">
            <div className="icon-40-bg-green">
              <SvgIcon name="icon-up" className="svg" />
            </div>
            <div className="ml-10">
              <div className="currency-name">Swap</div>
              <div className="currency-full">{item.time}</div>
            </div>
          </div>
      );

      if(item?.fromTypeId) {
        finalData[key]["fromShow"] = (
          <div className="table-currency">
              <div className="icon-40-block">
                <img src={COINLIST[item.fromTypeId].icon}/>
              </div>
              <div className="ml-10">
                <div className="font-16 font-b"><span>{COINLIST[item.fromTypeId].symbol}</span> {formatNumber(item.fromAmount, {maxFrac: 2})}</div>
                <div className="font-12 darkgrey">{hiddenSomeText(item?.fromAddress)}</div>
              </div>
            </div>
        );
      } else {
        finalData[key]["fromShow"] = (
          <div className="table-currency">
              <div className="ml-10">
                <div className="font-16 font-b"><span>UNKNOWN</span></div>
                <div className="font-12 darkgrey">UNKNOWN</div>
              </div>
            </div>
        );
      }

      if(item?.toTypeId) {
        finalData[key]["toShow"] = (
          <div className="table-currency">
              <div className="icon-40-block">
                <img src={COINLIST[item.toTypeId].icon}/>
              </div>
              <div className="ml-10">
                <div className="font-16 font-b"><span>{COINLIST[item.toTypeId].symbol}</span> {formatNumber(item.toAmount, {maxFrac: 2, crypMaxFrac: 8, type: item?.type})}</div>
                <div className="font-12 darkgrey">{hiddenSomeText(item?.toAddress)}</div>
              </div>
            </div>
        );
      } else {
        finalData[key]["toShow"] = (
          <div className="table-currency">
              <div className="ml-10">
                <div className="font-16 font-b"><span>UNKNOWN</span></div>
                <div className="font-12 darkgrey">UNKNOWN</div>
              </div>
            </div>
        );
      }

      if(item?.toTypeId && item?.fromTypeId){
        finalData[key].servefield = COINLIST[item.fromTypeId].symbol + item.fromTypeId + item.toTypeId + COINLIST[item.toTypeId].symbol + item.fromAmount + item.toAmount + item.time;
      } else {
        finalData[key].servefield = item.fromAmount + item.toAmount + item.time;
      }
    })
    setDataList(finalData);
  }
  
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchAllSwapTranactionFn();
      // console.log("fetchData", data);
      preparedData(data);
    }
    fetchData();
  }, [])

  return (
    <center-container>
      <div className="max-width-900">
        <FlatTable dataList={dataList} headerList={headerList} />
      </div>
    </center-container>
  )

};

const mapStateToProps = (state) => ({
	authentication: state.authentication,
  wallet: state.wallet
});

const mapDispatchToProps = {
	fetchAllSwapTranactionFn: fetchAllSwapTranaction,
	// checkSecureFunction: checkSecure
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(WalletTransaction);