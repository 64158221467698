import { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoginBox } from './components/LoginBox';
import { PreLoginBox } from './components/PreLoginBox';
import { SignUpBox } from './components/SignUpBox';
import { SignUpOtpBox } from './components/SignUpOtpBox';
import { checkUserLoginMethod } from '../../redux/actions/authentication';
// import { HOME_PATH } from '../../../utils/constants';
import PropTypes from 'prop-types';
import logo from '../../assets/images/logo-white@512.png';
import signin_cover from '../../assets/images/signin-cover.jpg';

export const LoginPage = ({checkUserLoginMethodFunction}) => {
  let navigate = useNavigate();
  const [ currentPage, setCurrentPage ] = useState('preLogin');
  const [ otpEnable, setOtpEnable ] = useState(false);
  const [ username, setUsername ] = useState(null);
  const [ signUpForm, setSignUpForm ] = useState({});

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
        return re.test(email);
  };

  const checkUser = async () => {
    if(!validateEmail(username)){
      return null;
    }
    const response = await checkUserLoginMethodFunction(username);
    if(response.ok == true){
      if(response.type === "mfa"){
        setOtpEnable(true);
      }
      goNext('login');
    } else {
      goNext('signUp');
    }
  };

  const goNext = (next) => {
    setCurrentPage(next);
  }

  const handleComplete = () => {
    // navigate(HOME_PATH, { replace: true });
    navigate(-1, { replace: true });
  }

  const renderComplete = () => {
    return (
        <>
        <text-title>Ready</text-title>
        <text-subtitle>Your Account Has Been Create</text-subtitle>
        <button-block>
            <circle-button onClick={handleComplete}>Next</circle-button>
        </button-block>
      </>
    );
  }

  const renderContent = () => {
    switch (currentPage) {
      case 'login':
        return <LoginBox username={username} otpEnable={otpEnable}/>
      case 'preLogin':
        return <PreLoginBox setUsername={setUsername} checkUser={checkUser}/>;
      case 'signUp':
        return <SignUpBox username={username} setSignUpForm={setSignUpForm} goNext={goNext}/>; 
      case 'signUpOtp':
        return <SignUpOtpBox signUpForm= {signUpForm} goNext={goNext}/>;   
      case 'completeScreen':
        return renderComplete();
      default:
        return <PreLoginBox setUsername={setUsername} checkUser={checkUser}/>;
    }
  }

  return (
    <>
      <login-box>
        <img className="herobox-logo" src={logo}/>
        <img className="herobox" src={signin_cover}/>
        <box-content>
          {renderContent()}
        </box-content>
      </login-box>
    </>
  );

};

LoginPage.propTypes = {
  checkUserLoginMethodFunction: PropTypes.func
};

const mapDispatchToProps = {
  checkUserLoginMethodFunction: checkUserLoginMethod
};

export default connect(
null,
mapDispatchToProps
)(LoginPage);