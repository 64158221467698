import React, { useState, useEffect } from "react"; 
import { connect } from 'react-redux';
import _ from 'lodash';
// import { CRYPTO } from '../../../utils/crypto'
import '../styles/styles.css';
import { getFiatInvoice } from '../../../redux/actions/fiat';
import { fetchAPIConnect } from '../../../redux/actions/app';
import InvoiceList from '../components/InvoiceList';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

const FiatInvoiceList = ({ authentication, getFiatInvoiceFn, fetchAPIConnectFn }) => {
    const [dataObj, setDataObj] = useState({});
    const [dataLoading, setDataLoading] = useState(true);
    const [searchText, setSearchText] = useState();

    dayjs.extend(utc)
    dayjs.extend(tz)

    useEffect(() => {
        setDataLoading(true);
    } , [searchText])

    useEffect(() => {
        if(dataLoading) {
            getFiatInvoiceFn('self', {keyword: searchText})
            .then(dataList => {
                if(dataList.ok){
                    setDataObj(groupDate(dataList.payload));
                }
            })
            setDataLoading(false);
        }
    } , [dataLoading])

    const deleteCryptoHandler = (id) => {
        fetchAPIConnectFn('/crypto/deposit/'+id+'/delete', {})
          .then(res => {
            if(res.ok){
              setDataLoading(true);
            }
          })
    }

    const groupDate = (data) => {
        var groups = _.groupBy(data, function (intv) {
            return dayjs(intv.createdAt.date).tz(authentication?.settingObject.dateZone).local().format('DD/MM/YYYY');
        });
        return groups;
    }

    return (
        <center-container>
            <InvoiceList 
                dataList={dataObj} 
                setSearchText={setSearchText}
                deleteCryptoHandler={deleteCryptoHandler}
                createEnable={true} 
                payAction={'/wallet/fiat/invoice/'}
            />
        </center-container>
    );

};

const mapStateToProps = (state) => ({
	authentication: state.authentication
});

const mapDispatchToProps = {
	getFiatInvoiceFn: getFiatInvoice,
    fetchAPIConnectFn: fetchAPIConnect
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(FiatInvoiceList);