import { combineReducers } from 'redux';
import loadingReducer from './loading';
import authenticationReducer from './authentication';
import notificationReducer from './notification';
import walletReducer from './wallet';
import socketReducer from './socket';

export default combineReducers({
  loading: loadingReducer,
  authentication: authenticationReducer,
  notification: notificationReducer,
  wallet: walletReducer,
  socket: socketReducer
});
