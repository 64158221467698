// import { Navigate, useOutlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useOutlet, useLocation, useNavigate } from 'react-router-dom';
// import { useAuth } from '../../hooks/useAuth';
import { mainMenu, transparentWhiteMenu, transparentBlackMenu } from '../../components/layout/config/AppBarSetting';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AppBar } from './AppBar';
import SvgIcon from '../../components/svgicon';

const SidebarLayout = ({ authentication, menuList }) => {
  // const { sessiontoken } = useAuth();
  let history = useLocation();
  const [ pageAlign, setPageAlign ] = useState("main");
  const [ barStyle, setBarStyle ] = useState("bgfilled");
  const outlet = useOutlet();
  const navigate = useNavigate();

  // if (!sessionToken) {
  //   return <Navigate to="/" />;
  // }

  useEffect(() => {
    if(transparentWhiteMenu.includes(history.pathname) || transparentBlackMenu.includes(history.pathname)){
        if(transparentWhiteMenu.includes(history.pathname)){
            setBarStyle("transparent-white");
            setPageAlign("black-main");
        } else {
            setBarStyle("transparent-black");
            setPageAlign("white-main");
        }
    } else {
        setBarStyle("bgfilled");
        setPageAlign("main"); 
    }
  } , [history])

  const renderHideMenu = (item) => {
    return (
      <a key={item.target+"gide2"} onClick={() => navigate(item?.target)}>
        <SvgIcon name={item.icon} className="icon" /> {item?.label}
      </a>
    );
  }

  const renderMenu = (item) => {
    if(authentication?.permission?.includes(item?.auth) || item?.auth == "all"){
      return (
        <li key={item.target}>
          <a className="nav-menu" onClick={() => navigate(item?.target)}>
          <SvgIcon name={item.icon} className="icon" /> {item?.label}
          </a>
        </li>
      );
    }
    return <></>;
  }

  return (
    <div>
      <AppBar
        barStyle={barStyle}
        pages={mainMenu}
        authentication={authentication}
      />
      <div role={pageAlign}>
      <two-column-container className="no-padding content-block">
        <sidebar-block>
            <ul>
            {menuList.map((item) => {
                if(item.type === 'menu'){
                    return renderMenu(item)
                } else if (item.type === 'header'){
                    return <li key={item.label+"932f"} className="menu-header">{item.label}</li>
                }
            })}
            </ul>
        </sidebar-block>
        <layout-block>
        <hide-sidebar-block>
            {menuList.map((item) => {
                if(item.type === 'menu'){
                    return renderHideMenu(item)
                }
                return false;
            })}
        </hide-sidebar-block>
            {outlet}
        </layout-block>
      </two-column-container>
      </div>
    </div>
  );
};

SidebarLayout.propTypes = {
  authentication: PropTypes.object,
  menuList: PropTypes.array
};

const mapStateToProps = (state) => ({
  authentication: state.authentication
});

const mapDispatchToProps = {
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarLayout);