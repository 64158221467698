import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
// import { UI_ICON } from '../../assets/uiicon';
import avatar_placeholder from '../../assets/icon/ui/avatar.svg';
import SvgIcon from '../../components/svgicon';

const MobileUserMenu = ({ authentication }) => {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [ avatar, setAvatar ] = useState(avatar_placeholder);
    const [ showName, setName ] = useState(authentication.objectID);

    useEffect(() => {
      if(!authentication.nameObject?.firstname){
        setName(authentication.objectID);
      } else {
        setName(authentication.nameObject?.firstname+" "+authentication.nameObject?.lastname);
      }
      setAvatar(authentication?.avatar);
      // setName(authentication.objectID);
    } , [authentication])
    
    return (
        <ul className="mobilenav-border">
        <li>
          <a
            onClick={() => navigate('/setting')}
            className="mobilenav-link-style" 
            key="mobile-setting-49" 
            data-toggle="collapse" 
            data-target="#mobileHeaderMenu" 
            aria-controls="mobileHeaderMenu" 
            aria-expanded="false" aria-label="Toggle navigation"
            >
            <SvgIcon name="icon-setting" className="mobilenav-main-icon" />
            Setting
          </a>
        </li>
        <li>
          <a 
            onClick={logout}
            className="mobilenav-link-style red" 
            key="mobile-close-a1" 
            // data-toggle="collapse" 
            // data-target="#mobileHeaderMenu" 
            // aria-controls="mobileHeaderMenu" 
            // aria-expanded="false" aria-label="Toggle navigation"
            >
            <SvgIcon name="icon-lock" className="mobilenav-main-icon-red" />
            Logout
          </a>
        </li>
        </ul>
    );
};

const mapStateToProps = (state) => ({
  authentication: state?.authentication
});

export default connect(mapStateToProps)(MobileUserMenu);