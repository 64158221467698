import React, { useState, useEffect } from "react"; 
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { COINLIST } from "../../../utils/crypto";

const ProductList = ({ data, trigger, currentId, handleFunction }) => {
  const [ productList, setProductList ] = useState([]);
  const [ search, setSearch ] = useState('');

  useEffect(() => {
    orderProductList(data);
  } , [data])

  const onClickRow = (id) => {
    if(id === currentId){ return null; }
    handleFunction(id);
  }

  const sortFunction = (a, b) => {
      if (a.id === b.id) { return 0; }
      else { return (a.id < b.id) ? -1 : 1;}
  }

  const orderProductList = (data) => {
    data.sort(sortFunction);
    setProductList(data);
  }

  const handleKeyDown = (e) => {
    if(e.target.value === ''){ orderProductList(data); return true; }
    let t = productList.filter((o) =>
      o.id.includes(e.target.value)
    );
    orderProductList(t);
  }

  const renderRow = (item) => {
    let classShow = "red";
    let pc = 100 - ((item.ticker.price/item.ticker.open24h) * 100);
    const activeClass = (item.id === currentId) ? "active" : "";
    if(isNaN(pc)){ pc = 0;}
    if(pc < 0){
      classShow = "green";
      pc = "+"+parseFloat(pc).toFixed(2)+"%";
    } else {
      pc = "-"+parseFloat(pc).toFixed(2)+"%";
    }
    return (
      <tr key={"rowifudg-"+item.id} className={"cur-pointer " + activeClass} onClick={() => onClickRow(item.id)}>
        <td><img className="currency-icon" src={COINLIST[item.baseCurrency.toLowerCase()].icon} alt="" />{item.baseCurrency}/{item.quoteCurrency}</td>
        <td className="t-r">{parseFloat(item.ticker.price).toFixed(2)}</td>
        <td className={"t-r "+classShow}>{pc}</td>
      </tr>
    )
  }

  const renderAllRow = () => {
    if(isEmpty(data)){
      return <div className="no-data-content"><span className="no-data"><i className="icon ion-md-document"></i>No data</span></div>;
    }
    return (
      <div className="content">
      <table className="trade-table">
        <thead>
          <tr>
            <th>pairs</th>
            <th className="t-r">last price</th>
            <th className="t-r">change</th>
          </tr>
        </thead>
        <tbody>
          {productList.map((item) => {
            return renderRow(item)
          })}
        </tbody>
      </table>
      </div>
    );
  }

  return (
    <div className="trade-block-content">
      <div className="search-header">
      <input
        className="zone-header-input"
        placeholder="Search Quote eg. ETH"
        type="text"
        onKeyDown={handleKeyDown} />
      </div>
      {renderAllRow()}
    </div>
  );
};

ProductList.propTypes = {
  currentId: PropTypes.string,
  data: PropTypes.array,
  handleFunction: PropTypes.func,
  trigger: PropTypes.number
};

const mapStateToProps = (state) => ({
	// authentication: state.authentication
});

export default connect(
mapStateToProps,
null
)(ProductList);