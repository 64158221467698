import React, { useState, useEffect } from "react"; 
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getFiatInvoice } from '../../../redux/actions/fiat';
import InvoiceList from '../../wallet/components/InvoiceList'

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

const ExternalFiatInvoiceList = ({ authentication, getFiatInvoiceFn }) => {
    const params = useParams();
    const navigate = useNavigate();
    const [dataObj, setDataObj] = useState({});
    const [dataLoading, setDataLoading] = useState(true);

    dayjs.extend(utc)
    dayjs.extend(tz)

    useEffect(() => {
        if(dataLoading) {
            const tempSearch = `${params.key}:${params.keyword}`;
            getFiatInvoiceFn('all', {keyword: tempSearch})
            .then(dataList => {
                if(dataList.ok){
                    if(dataList.payload.length == 1){
                        navigate(`/external/invoice/${dataList.payload[0].id}`);
                        return false;
                    }
                    setDataObj(groupDate(dataList.payload));
                }
            })
            setDataLoading(false);
        }
    } , [dataLoading])

    const groupDate = (data) => {
        var groups = _.groupBy(data, function (intv) {
            return dayjs(intv.createdAt.date).tz(authentication?.settingObject.dateZone).local().format('DD/MM/YYYY');
        });
        return groups;
    }

    return (
        <center-container>
            <InvoiceList 
                dataList={dataObj} 
            />
        </center-container>
    );

};

const mapStateToProps = (state) => ({
	authentication: state.authentication
});

const mapDispatchToProps = {
	getFiatInvoiceFn: getFiatInvoice
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(ExternalFiatInvoiceList);