const initialState = {
  objectID: "",
  sessiontoken: "",
  username: "",
  email: "",
  avatar: "",
  dataObject: {
    tel: ''
  },
  settingObject: {
    language: {
      system: 'en'
    },
    currency: 'thb',
    dateZone: 'Asia/Bangkok'
  },
  nameObject: {
    firstname: '',
    lastname: ''
  },
  exchangeSetting: {
    theme: 'dark-exchange'
  },
  permission: [],
  role: {
    name: 'Guest',
    id: ''
  },
  notiSetting: {},
  twoFA: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_CURRENT_USER':
      let newState = action?.payload;
      if(!newState.nameObject){
        newState.nameObject = initialState.nameObject;
      }
      if(!newState.settingObject){
        newState.settingObject = initialState.settingObject;
      }
      if(!newState.dataObject){
        newState.dataObject = initialState.dataObject;
      }
      if(!newState.permission){
        newState.permission = initialState.permission;
      }
      if(!newState.exchangeSetting){
        newState.exchangeSetting = initialState.exchangeSetting;
      }
      return newState;
    case 'SET_EXCHANGE_CONFIG': {
      const newState = { ...state };
      newState.exchangeSetting[action?.json?.target] = action?.json?.value;
      return newState;
    }
    case 'AUTHENTICATION_LOGUOT_SUCCESS':
    case 'AUTHENTICATION_FAILURE':  
    case 'CLEAR_CURRENT_USER':
      return { ...initialState }; 
    case 'TWOFA_ADDED_CONFIG': {
      const newState = { ...state };
      newState.twoFA = true;
      return newState;
    }
    case 'TWOFA_REMOVE_CONFIG': {
      const newState = { ...state };
      newState.twoFA = false;
      return newState;
    }   
    default:
      return state;
  }
}
