import React from "react"; 
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Lottie from "lottie-react";
import completeAnimation from "../assets/animation/error.json";
// import '../styles/styles.css';

const NoAccess = () => {
    const navigate = useNavigate();
      return (
        <center-container>
          <div className="max-width-600 content-block">
            <screen-style>
                <Lottie animationData={completeAnimation} style={{height: 300}}/>
                <div className="centerTitleHeader mt-20">No Access</div>
                <div className="centerSubTitleHeader mb-20">You doesn't have access to this page</div>
                {/* <div className="impotant-data-zone"></div> */}
                <button-block>
                    <circle-button onClick={() => navigate('/')}>Next</circle-button>
                </button-block>
            </screen-style>
            <div style={{height: "20px"}} />
          </div>
        </center-container>
      )

};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(NoAccess);