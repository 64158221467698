import { useState } from 'react';
import PropTypes from 'prop-types';
import { checkExistingUsers } from '../../../api/authentication';
import { sendOTP } from '../../../utils/OneTimePassword';

export const SignUpBox = ({ username, goNext, setSignUpForm }) => {
    const [ signUpData, setSignUpData ] = useState({
        username: username,
        password: null,
        rePassword: null
    });

    const [errorCode, setErrorCode] = useState();
	const [errorText, setErrorText] = useState('This email doesn’t register, just put below form and you will ready');

    const _createAccount = async () => {	
		if (signUpData?.username && (signUpData?.password === signUpData?.rePassword)) {
            const usernameCheck = await checkExistingUsers(signUpData?.username);
            if (!usernameCheck?.ok) {
				setErrorText('Username is Error');
				setErrorCode({color: 'red'});
                return;
            }
            if (usernameCheck?.payload) {
				setErrorText('Username is already used');
				setErrorCode({color: 'red'});
                return;
            }
            if (signUpData?.username.length < 8) {
				setErrorText('Username need more than 8 characters');
				setErrorCode({color: 'red'});
                return;
            }
            if (signUpData?.password.length < 8) {
				setErrorText('Password need more than 8 characters');
				setErrorCode({color: 'red'});
                return;
            }
            if (signUpData?.password !== signUpData?.rePassword) {
				setErrorText('Password doesn\'t match');
				setErrorCode({color: 'red'});
                return;
            }
            sendOTP(signUpData?.username,signUpData?.password);
            setSignUpForm({username: signUpData?.username, password: signUpData?.password});
            goNext('signUpOtp');
            return null;
		} else {
            setErrorText('Password doesn\'t match');
			setErrorCode({color: 'red'});
            return null;
        }
	};


    const handleChange = (event) => {
        let payload = signUpData;
        payload[event.target.id] = event.target.value;
        setSignUpData(payload);
    }
     
    return (
      <>
        <text-title>Sign UP</text-title>
        <text-subtitle style={errorCode}>{errorText}</text-subtitle>
        <box-input>
            <input
                id="username"
                type="text"
                placeholder="Username"
                value={signUpData.username}
                onChange={handleChange}
            />
            <input
                id="password"
                type="password"
                placeholder="Password"
                onChange={handleChange}
            />
            <input
                id="rePassword"
                type="password"
                placeholder="Repeat Password"
                onChange={handleChange}
            />
        </box-input>
        <button-block>
            <circle-button onClick={_createAccount}>Next</circle-button>
        </button-block>
      </>
    );
  
  };
  
  SignUpBox.propTypes = {
    username: PropTypes.string,
    goNext: PropTypes.func,
    setSignUpForm: PropTypes.func,
  };
  