import { SERVER_HOST, API_KEY } from '../utils/constants';

export const getNotification = async () => {
  try {
    const sessionToken = window.localStorage.getItem('sessiontoken');
    const url = `${SERVER_HOST}/notify/list`;
    const options = {
      headers: {
        "Content-Type": "application/json",
        'X-Api-Key': API_KEY,
        Authorization: sessionToken,
      },
    };
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error('request failure');
    }
    const json = await response.json();
    return json;
  } catch (error) {
    return { ok: false, payload: null };
  }
};

// export const getBadge = async (installationId) => {
//   try {
//     const url = `${SERVER_HOST}/notify/badge/get`;
//     const options = {
//       method: 'POST',
//       headers: {
//         "Content-Type": "application/json",
//         'X-Api-Key': API_KEY,
//       },
//       body: JSON.stringify({installationId: installationId}),
//     };
//     const response = await fetch(url, options);
//     if (!response.ok) {
//       throw new Error('request failure');
//     }
//     const json = await response.json();
//     return json;
//   } catch (error) {
//     return { ok: false, payload: error };
//   }
// };

// export const clearBadge = async (installationId) => {
//   try {
//     const formData = new FormData();
//     formData.append('installationId', installationId);
//     const url = `${SERVER_HOST}/notify/badge/clear`;
//     const options = {
//       method: 'PATCH',
//       headers: {
//         "Content-Type": "application/json",
//         'X-Api-Key': API_KEY,
//       },
//       body: JSON.stringify({installationId: installationId}),
//     };
//     const response = await fetch(url, options);
//     if (!response.ok) {
//       throw new Error('request failure');
//     }
//     const json = await response.json();
//     return json;
//   } catch (error) {
//     return { ok: false, payload: error };
//   }
// };
