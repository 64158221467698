import React from 'react';
import { connect } from 'react-redux';
import loadingIcon from '../../assets/spinner.svg';

const CircularFullScreen = ({ loading }) => {
  if (!loading) return null;
  return (
    <div className="indicator-container">
      <img src={loadingIcon} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state?.loading
});

export default connect(mapStateToProps)(CircularFullScreen);
