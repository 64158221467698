import React, { useState, useEffect, useRef } from "react"; 
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiConnect } from "../../utils/fetch";
import Switch from "react-switch";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

const WalletSettingPage = ({apiConnectFn}) => {
    const navigate = useNavigate();
    const [dataObj, setDataObj] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);

    dayjs.extend(utc)
    dayjs.extend(tz)

    const toggleSwitch = async (xid) => {
      // const res = await apiConnectFn('/wallet/toggle',{id: id});
      // if(res.ok){
      //   setDataLoading(true);
      // }
      // return false;
      apiConnect('/wallet/toggle', JSON.stringify({id: xid}))
          .then(dataList => {
            // console.log(dataList);
            setDataLoading(true);
        })
	  }

    const dataPrepared = (data) => {
      // console.log(data);
      let walletData = [];
      data?.crypto?.map((item) => {
        walletData.push({
          type: "crypto",
          typeid: item?.typeid,
          amount: item?.amount,
          address: item?.address,
          symbol: item?.symbol,
          id: item?.id,
          enable: item?.enable
        });
      });
      data?.fiat?.map((item) => {
        walletData.push({
          type: "fiat",
          typeid: item?.typeid,
          amount: item?.amount,
          address: item?.address,
          symbol: item?.symbol,
          id: item?.id,
          enable: item?.enable
        });
      });
      setDataObj(walletData);
    }

    useEffect(() => {
      if(dataLoading == true) {
        apiConnect('/wallet/fetch', JSON.stringify({displayall: true}))
          .then(dataList => {
            if(dataList.ok){
              dataPrepared(dataList.payload);
              setDataLoading(false);
            }
        })
      }
    },[dataLoading])
    
    const renderData = (item) => {
      // console.log(item);
      return (
        <tr key={item?.id}>
          <td>{item?.symbol}</td>
          <td className="d-none d-md-table-cell">{item?.address}</td>
          <td>{item?.symbol} {item?.amount}</td>
          <td>
            <Switch 
                onChange={() => toggleSwitch(item?.id)} 
                checked={item?.enable}
                offColor={"#FF5959"} 
                onColor={"#8BCA65"}
                uncheckedIcon={false}
                checkedIcon={false}
              />
          </td>
        </tr>
      );
    }

    return (
      <center-container>
        <div className="max-width-800 content-block-full">
          <div className="table-control">
            <div className="left"></div>
            <div className="right">
              <div className="round-button low-pink" onClick={() => navigate('/wallet/create')}>New Wallet</div>
            </div>
          </div>
          <div className="table-defined">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Symbol</th>
                <th className="d-none d-md-table-cell" scope="col">Address</th>
                <th scope="col">Total</th>
                <th scope="col">Enable</th>
              </tr>
            </thead>
            <tbody>
            {dataObj.length == 0 ? (
              <tr><td>No Key</td></tr>
            ) : (
              dataObj.map((item) => {
                return renderData(item)
              })
            )}
            </tbody>
          </table>
          </div>
        </div>
      </center-container>
    )
}

const mapStateToProps = (state) => ({
	authentication: state.authentication
});

const mapDispatchToProps = {
  apiConnectFn: apiConnect
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(WalletSettingPage);