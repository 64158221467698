export const mainMenu = [
    { label: 'Home', id: "home", path: '/home', icon: 'icon-home', auth: "all" },
    { label: 'Wallet', id: "wallet", path: '/wallet', icon: 'icon-wallet', auth: "wallet" },
    { label: 'Exchange', id: "exchange", path: '/exchange', icon: 'icon-wallet', auth: "exchange" },
    { label: 'Admin', id: "admin", path: '/admin', icon: 'icon-setting', auth: "admin" }
];

export const fillBlackMenu = [
    "/", "/home", "/exchange"
];
  
export const transparentWhiteMenu = [
    // "/", "/home"
];
  
export const transparentBlackMenu = [
    // "/", "/home"
];