import React, { useState, useEffect, useCallback } from "react"; 
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { CRYPTO, CURRENCY } from '../../../utils/crypto'
import '../styles/styles.css';
import SelectInput from '../../../components/selectinput';
import TagInput from "../../../components/taginput";
import SvgIcon from '../../../components/svgicon';
import WalletRadioInput from "../../../components/walletRadioinput";

import { fetchUserWallet } from '../../../redux/actions/wallet';
import { getFiatPaymentChannelApi, createFiatWithdrawal } from "../../../api/fiat";

const FiatWithdrawnCreate = ({ fetchUserWalletFn, authentication, wallet }) => {
    const params = useParams();
    const [newAmount, setNewAmountInput] = useState();
    const [bankCode, setBankCode] = useState(null);
    const [bankName, setBankName] = useState(null);
    const [bankAccount, setBankAccount] = useState(null);
    const [newAmountUnit, setNewAmountUnitInput] = useState("THB");
    const [accountSetting, setAccountSetting] = useState({});
    const [dataLoad, setDataLoad] = useState(true);
    const [settingLoad, setSettingLoad] = useState();
    const [settingEnable, setSettingEnable] = useState([]);
    const navigate = useNavigate();

    const [walletList, setWalletList] = useState({});
    const [recieverAddress, setRecieverAddress] = useState({});

    const handleNavigate = (path) => {
        navigate(path);
    };
    const recieverHandle = (data) => {
        setRecieverAddress(data);
    };

    const handleBankSelect = (data) => {
        setBankCode(data?.target.value);
        setBankName(data?.target?.options[data?.target?.selectedIndex].text);
    }
    const handleBankAccount = (data) => {
        setBankAccount(data?.target.value);
    }

    const createNewInvoice = async () => {
        if(newAmount==="" || newAmountUnit==="" || _.isEmpty(recieverAddress)){
            return null;
        }
        const payload = {
            amount: newAmount,
            unit: newAmountUnit,
            walletObj: recieverAddress.id,
            bankCode: bankCode,
            bankName: bankName,
            bankAccount: bankAccount
        };
        const res = await createFiatWithdrawal(authentication.sessiontoken, payload);
        if(res.ok){
            handleNavigate('/wallet/list');
        }
    }

    useEffect(() => {
        async function fetchData() {
            let channel = await getFiatPaymentChannelApi();
            if(channel.ok){
                setSettingEnable(channel.payload);
                let temp = {};
                channel.payload.map(v => { temp[v.id] = true});
                setAccountSetting(temp);
                setDataLoad(false);
            }
        }
        if(dataLoad) fetchData();
      }, [dataLoad]);

    useEffect(() => {
        let walletTemp = {fiat: [], wallet: []};
        wallet?.fiat?.map((item) => {
          let key = CURRENCY[item?.typeid];
          walletTemp.fiat.push({
            address: item?.address,
            title: key?.name,
            symbol: key?.symbol,
            id: item?.id,
            icon: key?.icon
          });
          if(item?.id === params?.id){
            setRecieverAddress({
                address: item?.address,
                title: key?.name,
                symbol: key?.symbol,
                id: item?.id,
                icon: key?.icon
            });
          }
        })
        // if(_.isEmpty(recieverAddress)){
        //     setRecieverAddress(walletTemp.fiat[0]);
        // }
        setWalletList(walletTemp);
    } , [wallet])
    
    useEffect(() => {
        fetchUserWalletFn();
    } , [])

    const renderPaymentMethodModal = () => {
        return (
        <div className="modal-font modal fade" id="paymentModalBox" data-backdrop="static" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title font-b" id="modalBoxLabel">Payment Method</h5>
                <div className="close" data-dismiss="modal">
                  <SvgIcon name="icon-xmark-solid" className="table-icon"/>
                </div>
              </div>
              <div className="modal-body">
                <WalletRadioInput 
                    inputData={walletList} 
                    inputHandle={recieverHandle}
                    inputDefault={recieverAddress?.id}
                />
              </div>
            </div>
          </div>
        </div>
        )
    }

    return (
        <center-container>
            {renderPaymentMethodModal()}
            <div className="max-width-600 content-block pb">
            <screen-style>
                <SvgIcon name="icon-wallet" className="iconImg red-filter"/>
                <div className="titleHeader">Create New Withdrawal</div>
                <div className="titleSubHeader">You can create a new withdrawal invoice for fiat assets below, you can add some option tag for ease of access in table below</div>
            </screen-style>
            <div className="pt-20 pb-10">
                <SelectInput 
                    inputHandle={setNewAmountInput} 
                    label={"Total"} 
                    inputData={newAmount} 
                />
            </div>
            <div className="mt-20">Withdrawn Address</div>
            <div className="list-style-container">
            {recieverAddress ? (
            <div className="item" key={recieverAddress?.id+"-Bgvf12s"}>
                <div className="iconContainer">
                    <img src={recieverAddress?.icon} className="iconBox"/>
                </div>
                <div className="leftContainer">
                    <div className="titleText">{recieverAddress?.title}</div>
                    <div className="subTitleText">{recieverAddress?.address}</div>
                </div>
                <div className="rightContainer">
                    <div className="round-button white" data-toggle="modal" data-target="#paymentModalBox">Change</div>  
                </div>
            </div>) : (<div className="font-b p-15" data-toggle="modal" data-target="#paymentModalBox">Please Select Reciever Address</div>)}
            </div>
            <div className="mt-20">Withdrawn To</div>
            <div className="line-style-container p-20">
            <select id="bank" className="custom-select" onChange={handleBankSelect}>
                <option value="">========= Select =======</option>
                <option value="BKKBTHBK">ธนาคารกรุงเทพ</option>
                <option value="KRTHTHBK">ธนาคารกรุงไทย</option> 
                <option value="AYUDTHBK">ธนาคารกรุงศรีอยุธยา</option>
                <option value="KASITHBK">ธนาคารกสิกรไทย</option> 
                <option value="TMBKTHBK">ธนาคารทหารไทยธนชาต</option>  
                <option value="UBOBTHBK">ธนาคารซีไอเอ็มบี ไทย</option> 
                <option value="SICOTHBK">ธนาคารไทยพาณิชย์</option> 
                <option value="SCBLTHBX">ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)</option> 
                <option value="BKASTHBK">ธนาคารยูโอบี</option>
                <option value="TFPCTHB1">ธนาคารทิสโก้</option>   
                <option value="KKPBTHBK">ธนาคารเกียรตินาคินภัทร</option>
                <option value="LAHRTHB2">ธนาคารแลนด์ แอนด์ เฮ้าส์</option> 
                <option value="ICBKTHBK">ธนาคารไอซีบีซี (ไทย)</option>
            </select>
            <input
                id="accoutno" 
                type="text" 
                className="data-form-control mt-15"
                onChange={handleBankAccount}
                placeholder="Bank Account No.: XXX-XXXX-XXXX" />
            </div>
            <button-block>
                <circle-button onClick={createNewInvoice}>Add</circle-button>
            </button-block>
            </div>
        </center-container>
    );

};

const mapStateToProps = (state) => ({
	authentication: state.authentication,
    wallet: state.wallet
});

const mapDispatchToProps = {
    fetchUserWalletFn: fetchUserWallet
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(FiatWithdrawnCreate);