import React, { useRef, useState } from "react"; 
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FILE_HOST } from '../../../utils/constants';
import SvgIcon from '../../../components/svgicon';
import _ from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

import { fileUpload } from "../../../redux/actions/app";
import { formatNumber } from "../../../utils/function";

const InvoiceList = ({ authentication, dataList, setSearchText, handleConfirm, fileUploadFn }) => {
  const searchTextRef = useRef(null);
  const [shareId, setShareId] = useState();
  const [addressText, setAddressText] = useState('');
  const [fileObj, setFileObj] = useState({});
  dayjs.extend(utc)
  dayjs.extend(tz)
  
  const navigate = useNavigate();
  const handleNavigate = (path) => {
    navigate(path);
  };

  const preparedList = (data) => {
    return (
      Object.keys(data).map((item) =>  {
        return renderTransaction(data[item])
      })
    );
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setSearchText(event.target.value);
    }
  }

  const confirmId = (id, option) => {
    // setFileObj({});
    setAddressText(option?.bankName + " " + option?.bankAccount);
    setShareId(id);
  }

  const actionCtrl = () => {
    if(_.isEmpty(fileObj)){ alert("Picture is empty"); return true;}
    handleConfirm('confirm', {wdObjId: shareId, image: fileObj})
  }

  const handleAction = (action, id) => {
    handleConfirm(action, id);
    // console.log(action, id);
  }

  const uploadPicture = async (e) => {
    const files = Array.from(e.target.files)
    const datafile = await fileUploadFn(files[0]);
    setFileObj(datafile?.payload);
    // console.log(datafile?.payload+"/");
    return false;
  }

  const renderShareModal = () => {
    return (
      <div className="modal-font modal fade" id="paymentShareModalBox" data-backdrop="static" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title font-b" id="modalBoxLabel">Withdrawn Confirmation</h5>
              <div className="close" data-dismiss="modal">
                <SvgIcon name="icon-xmark-solid" className="table-icon"/>
              </div>
            </div>
            <div className="modal-body">
              <div className="text-box">{addressText}</div>
              <input type="file" onChange={uploadPicture} id="avatar" />
            </div>
            <div className="modal-footer">
              <div className="round-button pink" data-dismiss="modal" onClick={() => actionCtrl()}>Confirm</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderTransaction = (item) => {
    let paytype = "";
    let paytypeclass = "currency-tranaction-deposit";
    let iconbg = "icon-40-bg-orange";
    let icons = "icon-next";
    let paystatus = "Waiting";
    let payaction = true;

    if(item?.type == "withdraw"){
      paytypeclass = "currency-tranaction-withdraw";
      paytype = "Withdrawal";
      paystatus = "Waiting";
      payaction = true;
      if(item.status=="confirm"){
        iconbg = "icon-40-bg-green";
        icons = "icon-down";
        paystatus = "Finish";
        payaction = false;
      } else if (item.status=="reject"){
        iconbg = "icon-40-bg-red";
        icons = "icon-up";
        paystatus = "Reject";
        payaction = false;
      }
    }

    return (
      <tr key={item.id+"coin9372b"} className="cur-pointer">
        <td style={{width: "25%"}}>
          <div className="table-currency">
            <div className={iconbg}>
              <SvgIcon name={icons} className="svg" />
            </div>
            <div className="ml-10">
              <div className="currency-name">{paystatus}</div>
              <div className="currency-full">
                <div className={paytypeclass}>{paytype}</div>{' '}
                {dayjs(item.createdAt.date).tz(authentication?.settingObject.dateZone).local().format('HH:mm')}
              </div>
            </div>
          </div>
        </td>
        <td style={{width: "25%"}} className="d-none d-md-table-cell">
          <div className="render-allocation">
            <div className="render-invoiceid">{item.invoiceId}</div>
          </div>
        </td>
        <td style={{width: "25%"}}>
          <div className="table-common">
            <div className="render-num">{item?.total?.unit+" "+formatNumber(item.total.amount, {maxFrac: 2, crypMaxFrac: 8, type: item?.currencyType?.type})}</div>
          </div>
        </td>
        <td style={{width: "25%"}}>
          {payaction && (
            <>
              <div 
                className="round-button green wrap-button"
                onClick={() => confirmId(item.id, item.option)}
                data-toggle="modal" data-target="#paymentShareModalBox">Confirm
              </div>
              {' '}
              <div 
                className="round-button pink wrap-button" 
                onClick={() => handleAction('reject', {wdObjId: item.id})}
              >
                  Reject
              </div>
            </>
          )}
          {item.option?.url &&(
              <>
                <div onClick={() => window.open(FILE_HOST+item.option.url)}>
                  <SvgIcon name="icon-image" className="table-icon low-grey"/>
                </div>
              </>
            )}
        </td>
      </tr>
    );
  }

  return (
    <div className="max-width-900 data-block">
      {renderShareModal()}
        <div className="zone-header">
          <div className="left">
            <input
              className="zone-header-input"
              placeholder="Keyword eg. name:susan"
              ref={searchTextRef}
              type="text"
              onKeyDown={handleKeyDown} />
          </div>
        </div>
      <div className="table-defined">
        <table className="clean-table">
          <tbody>
            {_.isEmpty(dataList) ? (
              <tr key={"nodata"} className="divide">
                <td colSpan="4">No Invoice</td>
              </tr> 
            ) : (
              Object.keys(dataList).map((key) =>  {
                  return (
                    <React.Fragment key={key+"as842nsb"}>
                    <tr key={key+"as842nb"} className="divide">
                      <td colSpan="4">{key}</td>
                    </tr>
                    {preparedList(dataList[key])}
                    </React.Fragment >
                  )
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

InvoiceList.propTypes = {
  header: PropTypes.string,
  dataList: PropTypes.object.isRequired,
  handleConfirm: PropTypes.func
};

const mapDispatchToProps = {
  fileUploadFn: fileUpload
};

const mapStateToProps = (state) => ({
	authentication: state.authentication
});

export default connect(
mapStateToProps,
mapDispatchToProps
)(InvoiceList);