import React from "react"; 
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { makeid } from "../../../utils/function";

const TradeList = ({ authentication, data, product, trigger }) => {
  dayjs.extend(utc)
  dayjs.extend(tz)

  const renderRow = (item) => {
    const dateString = dayjs(item.time).tz(authentication?.settingObject.dateZone).local().format('HH:mm');
    const sideColor = (item.side == "sell") ? "red" : "green";
    return (
      <tr key={makeid(5)}>
        <td>{dateString}</td>
        <td className={sideColor}>{parseFloat(item.price).toFixed(2)}</td>
        <td>{parseFloat(item.size).toFixed(2)}</td>
      </tr>
    )
  }

  const renderAllRow = () => {
    if(isEmpty(data)){
      return <div className="no-data-content"><span className="no-data"><i className="icon ion-md-document"></i>No data</span></div>;
    }
    return (
      <div className="content">
      <table className="trade-table">
        <thead>
          <tr>
            <th>time</th>
            <th>price ({product?.quoteCurrency})</th>
            <th>amount ({product?.baseCurrency})</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => {
            return renderRow(item)
          })}
        </tbody>
      </table>
      </div>
    );
  }

  return (
    <div className="trade-block-content">
      <div className="header">Trade History</div>
      {renderAllRow()}
    </div>
  );
};

TradeList.propTypes = {
  data: PropTypes.array,
  trigger: PropTypes.number,
  product: PropTypes.object
};

const mapStateToProps = (state) => ({
	authentication: state.authentication
});

export default connect(
mapStateToProps,
null
)(TradeList);