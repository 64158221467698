import React, { useState, useEffect, useCallback } from "react"; 
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { CRYPTO, CURRENCY } from '../../../utils/crypto'
import '../styles/styles.css';
import SvgIcon from '../../../components/svgicon';
import Lottie from "lottie-react";
import completeAnimation from "../../../assets/animation/complete.json";

import { fetchSwapableValueApi } from "../../../api/wallet"
import { confirmSwap } from '../../../redux/actions/wallet';
import { formatNumber, hiddenSomeText } from '../../../utils/function';

const WalletSwapConfirm = ({ authentication, wallet, confirmSwapFn }) => {
    const { state } = useLocation();
    const navigate = useNavigate();

    const [ showData, setShowData] = useState(false);
    const [serviceTotal, setServiceTotal] = useState(0);
    const [serviceFee, setServiceFee] = useState({});
    const [fromAmount, setFromAmount] = useState(0);
    const [toAmount, setToAmount] = useState(0);
    const [fromAddress, setFromAddress] = useState({});
    const [toAddress, setToAddress] = useState({});

    const [completeData, setCompleteData] = useState({});

    const createNewSwap = async () => {
        const res = await confirmSwapFn(state);
        if(res.ok){
            // console.log("createNewSwap", res);
            setCompleteData(res.payload);
            setShowData(true);
        }
        return null;
    }

    const showTotal = (sys, from) => {
        const total = parseFloat(sys?.swapFee) + parseFloat(sys?.serviceCharge || 0) + parseFloat(from);
        if(!total){
          return 0;
        }
        return total;
    }

    useEffect(() => {
        function getData() {
            fetchSwapableValueApi(authentication.sessiontoken, state)
            .then(dataList => {
                if(dataList.ok){
                    setServiceTotal(showTotal(dataList?.payload?.system, dataList?.payload?.fromAmount));
                    setServiceFee(dataList?.payload?.system);
                    setFromAmount(dataList?.payload?.fromAmount);
                    setToAmount(dataList?.payload?.toAmount);
                }
             })
        }
        setFromAddress({
            address: state?.fromAddress,
            title: (CRYPTO[state?.fromType]?.name || CURRENCY[state?.fromType]?.name),
            symbol: (CRYPTO[state?.fromType]?.symbol || CURRENCY[state?.fromType]?.symbol),
            id: state?.fromTyp,
            icon: (CRYPTO[state?.fromType]?.icon || CURRENCY[state?.fromType]?.icon)
        });
        setToAddress({
            address: state?.toAddress,
            title: (CRYPTO[state?.toType]?.name || CURRENCY[state?.toType]?.name),
            symbol: (CRYPTO[state?.toType]?.symbol || CURRENCY[state?.toType]?.symbol),
            id: state?.toType,
            icon: (CRYPTO[state?.toType]?.icon || CURRENCY[state?.toType]?.icon)
        });

        getData()
        const interval = setInterval(() => getData(), 5000)
        return () => {
          clearInterval(interval);
        }
    }, [])

    const renderResponseData = () => {
        return (
            <>
            {Object.keys(completeData).map((key) =>  {
                return <div key={key}><span className="title">{key}: </span>{completeData[key]}</div>;
            })}
            </>
        );
      }

    const renderCreated = () => {
        return (
          <div className="max-width-600 content-block">
            <screen-style>
                <Lottie animationData={completeAnimation} style={{height: 300}}/>
                <div className="centerTitleHeader mt-20">Swap Complete</div>
                <div className="centerSubTitleHeader mb-20">You have swap your asset, please touch button below</div>
                <div className="impotant-data-zone">{renderResponseData()}</div>
                <button-block>
                    <circle-button onClick={() => navigate('/wallet/list')}>Next</circle-button>
                </button-block>
            </screen-style>
            <div style={{height: "20px"}} />
          </div>
        )
      }

      const renderForm = () => {
        return (
            <>
                <div className="max-width-600 content-block pb">
                <screen-style>
                    <SvgIcon name="icon-wallet" className="iconImg green-filter"/>
                    <div className="titleHeader">Swap Confirmation</div>
                    <div className="titleSubHeader">As our crypto price updated in real-time, you need to confirm as soon as possible, if you need below quote price</div>
                </screen-style>
    
                <div className="swap-header-text mt-20">From</div>
                    <div className="list-style-container">
                        <div className="item" key={fromAddress?.id+"-Bgvf12s"}>
                            <div className="iconContainer"><img src={fromAddress?.icon} className="iconBox"/></div>
                            <div className="leftContainer">
                            <div className="titleText">{fromAddress?.title}</div>
                            <div className="subTitleText">{hiddenSomeText(fromAddress?.address)}</div>
                            </div>
                            <div className="rightContainer">
                                <span className="text"><span className="textBold">{fromAddress?.symbol}</span>{formatNumber(fromAmount, {maxFrac: 2, crypMaxFrac: 8, type: fromAddress?.type})}</span>
                            </div>
                        </div>
                    </div>
    
                <div className="swap-header-text mt-20">To</div>
                    <div className="list-style-container">
                        <div className="item" key={toAddress?.id+"-Bgvf12s"}>
                            <div className="iconContainer"><img src={toAddress?.icon} className="iconBox"/></div>
                            <div className="leftContainer">
                            <div className="titleText">{toAddress?.title}</div>
                            <div className="subTitleText">{hiddenSomeText(toAddress?.address)}</div>
                            </div>
                            <div className="rightContainer">
                                <span className="text"><span className="textBold">{toAddress?.symbol}</span>{formatNumber(toAmount, {maxFrac: 2, crypMaxFrac: 8, type: toAddress?.type})}</span>
                            </div>
                        </div>
                    </div>
    
                <div className="additional-detail">
                <div className="item">
                    <span className="font-b">Swap Fee:</span> {formatNumber(serviceFee?.swapFee || 0, {maxFrac: 2, crypMaxFrac: 8, type: fromAddress?.type})} <span className="font-b">{fromAddress?.symbol}</span> 
                </div>
                <div className="item">
                    <span className="font-b">Service Charge:</span> {formatNumber(serviceFee?.serviceCharge || 0, {maxFrac: 2, crypMaxFrac: 8, type: fromAddress?.type})} <span className="font-b">{fromAddress?.symbol}</span> 
                </div>
                <div className="item">
                    <span className="font-b">Total Amount:</span> {formatNumber(serviceTotal, {maxFrac: 2, crypMaxFrac: 8, type: fromAddress?.type})} <span className="font-b">{fromAddress?.symbol}</span> 
                </div>
                </div>
    
                <button-block>
                    <circle-button onClick={createNewSwap}>Confirm</circle-button>
                </button-block>
                </div>
            </>
        );
      }

      return (
        <center-container>
            {!showData && renderForm()}
            {showData && renderCreated()}
        </center-container>
      )

};

const mapStateToProps = (state) => ({
  authentication: state.authentication,
  wallet: state.wallet
});

const mapDispatchToProps = {
    confirmSwapFn: confirmSwap
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(WalletSwapConfirm);