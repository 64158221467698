
import PropTypes from 'prop-types';

export const PreLoginBox = ({ setUsername, checkUser }) => {
     
    return (
      <>
        <text-title>Sign in</text-title>
        <text-subtitle>Welcome to Wallet025</text-subtitle>
        <box-input>
            <input
            id="username"
            type="text"
            placeholder="Email"
            onChange={text => setUsername(text.target.value)}
            />
        </box-input>
        <button-block>
            <circle-button onClick={checkUser}>Next</circle-button>
        </button-block>
      </>
    );
  
  };
  
  PreLoginBox.propTypes = {
    setUsername: PropTypes.func,
    checkUser: PropTypes.func,
  };
  