import { loading } from './loading';
import {
    fetchUserWalletApi,
    fetchSwapableApi,
    confirmSwapApi
} from "../../api/wallet";
import { apiConnect, wsApiFetch } from '../../utils/fetch';

const fetchExchangeWalletFn = async () => {
  try {
    const url = "/api/accounts?currency=BTC&currency=ETH&currency=THB&currency=USDT&currency=USD";
    const response = await wsApiFetch(url, "GET", null);
    const data = response?.payload;
    let payload = [];
    const dataPayload = await data.json();
    dataPayload.forEach(v => {
      payload.push({
        "address": v.id || "exchange-wallet",
        "amount": v.available,
        "hold": v.hold,
        "enable": true,
        "id": "xx",
        "symbol": v.currency,
        "type": "exchange",
        "typeid": v.currency.toLowerCase()
      });
    });
    return payload;
  } catch (error) {
    return "error";
  }
}

const fetchUserWalletFn = async () => {
  try {
    const sessiontoken = window.localStorage.getItem('sessiontoken');
    const response = await fetchUserWalletApi(sessiontoken);
    if (!response?.ok) {
      throw response?.message;
    }
    let json = response?.payload;
    return json;
  } catch (error) {
    return "error";
  }
} 

export const fetchUserWallet = () => {
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      // fetchExchangeWalletFn();
      const userWalletJson = await fetchUserWalletFn();
      const exWalletJson = await fetchExchangeWalletFn();
      dispatch(loading(false));
      if(userWalletJson != "error"){
        dispatch({
          type: 'WALLET_FETCH_SUCCESS',
          payload: userWalletJson
        });
      }
      if(exWalletJson != "error"){
        dispatch({
          type: 'EX_WALLET_FETCH_SUCCESS',
          payload: exWalletJson
        });
      }
      return { ok: true };
    } catch (error) {
      dispatch(loading(false));
      dispatch({ type: 'CLEAR_WALLET' });
      return { ok: false };
    }
  };
};

// export const fetchUserWallet = () => {
//   return async (dispatch) => {
//     dispatch(loading(true));
//     try {
//       fetchExchangeWallet();
//       const sessiontoken = window.localStorage.getItem('sessiontoken');
//       const response = await fetchUserWalletApi(sessiontoken);
//       if (!response?.ok) {
//         throw response?.message;
//       }
//       let json = response?.payload;
//       dispatch(loading(false));
//       dispatch({
//         type: 'WALLET_FETCH_SUCCESS',
//         payload: json
//       });
//       return { ok: true };
//     } catch (error) {
//       dispatch(loading(false));
//       dispatch({ type: 'CLEAR_WALLET' });
//       return { ok: false };
//     }
//   };
// };

export const fetchExchangeWallet = () => {
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      // const sessiontoken = window.localStorage.getItem('sessiontoken');
      // const response = await fetchUserWalletApi(sessiontoken);
      const url = "/api/accounts?currency=BTC&currency=ETH&currency=THB&currency=USDT&currency=USD";
      const response = await wsApiFetch(url, "GET", null);
      const data = response?.payload;
      let payload = [];
      const dataPayload = await data.json();
      dataPayload.forEach(v => {
        payload.push({
          "address": v.id || "exchange-wallet",
          "amount": v.available,
          "hold": v.hold,
          "enable": true,
          "id": "xx",
          "symbol": v.currency,
          "type": "exchange",
          "typeid": v.currency.toLowerCase()
        });
      });
      dispatch(loading(false));
      dispatch({
        type: 'EX_WALLET_FETCH_SUCCESS',
        payload: payload
      });
      return { ok: true };
    } catch (error) {
      dispatch(loading(false));
      dispatch({ type: 'CLEAR_WALLET' });
      return { ok: false };
    }
  };
};

export const fetchSwapable = (from) => {
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      const sessiontoken = window.localStorage.getItem('sessiontoken');
      const response = await fetchSwapableApi(sessiontoken, {fromWallet: from});
      if (!response?.ok) {
        throw response?.message;
      }
      // console.log(response);
      let json = response?.payload;
      dispatch(loading(false));
      return { ok: true, payload: json };
    } catch (error) {
      dispatch(loading(false));
      return { ok: false };
    }
  };
};

export const confirmSwap = (payload) => {
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      const sessiontoken = window.localStorage.getItem('sessiontoken');
      const response = await confirmSwapApi(sessiontoken, payload);
      if (!response?.ok) {
        throw response?.message;
      }
      // console.log(response);
      let json = response?.payload;
      dispatch(loading(false));
      return { ok: true, payload: json };
    } catch (error) {
      dispatch(loading(false));
      return { ok: false };
    }
  };
};

export const fetchAllSwapTranaction = (payload) => {
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      const response = await apiConnect('/wallet/swap/transaction/all');
      // console.log(response);
      if (!response?.ok) {
        throw response?.message;
      }
      let json = response?.payload;
      dispatch(loading(false));
      return { ok: true, payload: json };
    } catch (error) {
      dispatch(loading(false));
      return { ok: false };
    }
  };
};