import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { getFiatInvoiceById, createFiatTransaction } from '../../../redux/actions/fiat';
import SvgIcon from '../../../components/svgicon';
import TagInput from "../../../components/taginput";
import RadioInput from "../../../components/radioinput";

import { BANK_ICON } from '../../../assets/cryptoicon';

import _ from 'lodash';
import '../styles/styles.css';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

const FiatInvoiceView = ({ authentication, getFiatInvoiceByIdFn, createFiatTransactionFn }) => {
    const params = useParams();
    const location = useLocation();
    const [dataLoading, setDataLoading] = useState(true);
    const [dataObj, setDataObj] = useState({});
    const [paymentMethod, setPaymentMethod] = useState({});
    const [serviceCharge, setServiceCharge] = useState(0);
    const [parityBit, setParityBit] = useState(0);
    const [total, setTotal] = useState(0);

    const navigate = useNavigate();
    const handleNavigate = (path) => {
        navigate(path);
    };

    dayjs.extend(utc)
    dayjs.extend(tz)

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: authentication?.settingObject?.currency || 'thb',
    });

    useEffect(() => {
        if(dataLoading) {
            getFiatInvoiceByIdFn(params.id)
            .then(dataList => {
                if(dataList.ok){
                  if(dataList?.payload?.type != "deposit"){
                    alert("Not a deposit invoice");
                    return null;
                  }
                  setDataObj(dataList.payload);
                  if(dataList?.payload?.paymentChannel[0]){
                    let paymentTemp = dataList?.payload?.paymentChannel[0];
                    let temp = dataList?.payload?.total?.amount+(dataList?.payload?.total?.amount*paymentTemp?.config?.serviceCharge);
                    let parity = (paymentTemp?.config?.parityBit ? Math.floor(Math.random() * 99) + 1 : 0)/100;
                    setParityBit(parity);
                    setPaymentMethod(dataList?.payload?.paymentChannel[0]);
                    setServiceCharge(dataList?.payload?.total?.amount*paymentTemp?.config?.serviceCharge);
                    setTotal(temp+parity);
                  }
                }
            })
            setDataLoading(false);
        }
    } , [dataLoading])

    const paymentSelector = (item) => {
        setPaymentMethod(item);
        let temp = dataObj?.total?.amount+(dataObj?.total?.amount*item?.config?.serviceCharge);
        let parity = (item?.config?.parityBit ? Math.floor(Math.random() * 99) + 1 : 0)/100;
        setParityBit(parity);
        setServiceCharge(dataObj?.total?.amount*item?.config?.serviceCharge);
        setTotal(temp+parity);
    }

    const handlePayment = async () => {
        const tempPayload = {
            id: dataObj.id,
            paymentMethod: paymentMethod.id,
            total: total,
            serviceCharge: serviceCharge,
            parityBit: parityBit
        };
        const response = await createFiatTransactionFn(tempPayload);
        if(response.ok){
            if(response?.payload?.redirect === "directtransaction"){
                if (location.pathname.search("wallet") == 1) {
                    handleNavigate('/wallet/fiat/directtx/'+response?.payload?.transactionId);
                } else {
                    handleNavigate('/external/directtx/'+response?.payload?.transactionId);
                }
            } else {
                window.location.href = response?.payload?.redirect;
            }
        }
    }

    const renderPaymentMethodModal = () => {
        return (
        <div className="modal-font modal fade" id="paymentModalBox" data-backdrop="static" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title font-b" id="modalBoxLabel">Payment Method</h5>
                <div className="close" data-dismiss="modal">
                  <SvgIcon name="icon-xmark-solid" className="table-icon"/>
                </div>
              </div>
              <div className="modal-body">
                <RadioInput 
                    inputData={dataObj?.paymentChannel} 
                    inputHandle={paymentSelector}
                    inputDefault={paymentMethod?.id}
                />
              </div>
            </div>
          </div>
        </div>
        )
    }

    return (
        <center-container>
        {renderPaymentMethodModal()}
        <div className="max-width-600 data-block">
            <div className="pt-20 pl-20 pr-20">
                <screen-style>
                    <div className="titleHeader">Payment</div>
                    <div className="titleSubHeader"><span className="font-b">ID: </span>{dataObj?.invoiceId}</div>
                    <div className="titleSubHeader">
                        <span className="font-b">Created Date: </span>
                        {dayjs(dataObj?.createdAt?.date).tz(authentication?.settingObject.dateZone).local().format('DD/MM/YYYY HH:mm')}
                    </div>
                </screen-style>
                <div className="pt-15">
                    <TagInput
                        tagData={dataObj?.option}
                        className="show-tag"
                    />
                </div>
            </div>
            <div className="table-defined mt-20">
                <table className="clean-table">
                    <thead className="line-head">
                        <tr>
                            <th scope="col">ITEMS</th>
                            <th scope="col">AMOUNT</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Bill Payment</td>
                            <td>{formatter.format(dataObj?.total?.amount)}</td>
                        </tr>
                        <tr>
                            <td>Service Charge</td>
                            <td>{formatter.format(serviceCharge)}</td>
                        </tr>
                        <tr>
                            <td>Parity Charge</td>
                            <td>{formatter.format(parityBit)}</td>
                        </tr>
                        <tr>
                            <td className="font-b">Total</td>
                            <td className="font-b">{formatter.format(total)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>    
            <div className="pt-20 pl-20 pr-20">
                <div className="list-style-container">
                    {(paymentMethod?.id != null && dataObj?.status === "progress") ? (
                        <div className="item" key={paymentMethod?.id+"-Bgvf12s"}>
                            <div className="iconContainer">
                                <img src={BANK_ICON[paymentMethod?.titleObject?.code]} className="iconBox"/>
                            </div>
                            <div className="leftContainer">
                                <div className="titleText">{paymentMethod?.titleObject[authentication?.settingObject?.language?.system]}</div>
                                <div className="subTitleText">{paymentMethod?.titleObject.detail && paymentMethod?.titleObject.detail[authentication?.settingObject?.language?.system]}</div>
                            </div>
                            <div className="rightContainer">
                                <div className="round-button white" data-toggle="modal" data-target="#paymentModalBox">Change</div>  
                            </div>
                        </div>
                    ): (<></>)}
                    {dataObj?.status === "confirm" ? <div className="font-b p-15">Invoice has been paid</div> : <></>}
                </div>     
            </div>
            <div className="mt-20">
                <div className="payment-card">
                    <div className="mute">Total</div>
                    <div className="title">{formatter.format(total)}</div>
                </div>
                {dataObj?.status === "confirm" ? <div className="pl-20 pr-20 pb-20"></div> : <div className="pl-20 pr-20 pb-20">
                    <circle-button onClick={handlePayment}>Pay</circle-button>
                </div>}
            </div>
        </div>
        </center-container>
    );

};

const mapStateToProps = (state) => ({
	authentication: state.authentication
});

const mapDispatchToProps = {
    getFiatInvoiceByIdFn: getFiatInvoiceById,
    createFiatTransactionFn: createFiatTransaction
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(FiatInvoiceView);