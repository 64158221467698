import { getNotification } from '../../api/notification';

export function getAllNotification() {
  return async (dispatch) => {
    try {
      const response = await getNotification();
      if (!response.ok) {
        throw new Error('request failure');
      }
      const json = response?.payload;
      dispatch({ type: 'NOTIFICATION_FETCH_SUCCESS', json });
    } catch (error) {
      dispatch({ type: 'NOTIFICATION_FETCH_FAILURE', error });
    }
  };
}

export function clearAllNotification() {
  return async (dispatch) => {
    dispatch({ type: 'NOTIFICATION_CLEAR_ALL' });
  };
}

// export function getNotificationBadge() {
//   return async (dispatch) => {
//     const installationId = await AsyncStorage.getItem("@INSTALLATION_ID");
//     if (installationId) {
//       try {
//         const response = await getBadge(installationId);
//         if (!response?.ok) {
//           throw new Error('request failure');
//         }
//         return dispatch({
//           type: 'NOTIFICATION_SET_BADGE',
//           badge: response?.payload,
//         });
//       } catch (error) {
//         return dispatch({ type: 'NOTIFICATION_CLEAR_BADGE' });
//       }
//     }
//   };
// }

// export function setNotificationBadge(num) {
//   return async (dispatch) => {
//     try {
//       return dispatch({
//         type: 'NOTIFICATION_SET_BADGE',
//         badge: num,
//       });
//     } catch (error) {
//       return dispatch({ type: 'NOTIFICATION_CLEAR_BADGE' });
//     }
//   };
// }

// export function clearNotificationBadge() {
//   return async (dispatch, getState) => {
//     if(getState().notification.badge == 0){
//       if(Platform.OS === 'ios'){
//         Notifications.ios.setBadgeCount(0);
//       }
//       return dispatch({ type: 'NOTIFICATION_CLEAR_BADGE' });
//     }
//     const installationId = await AsyncStorage.getItem("@INSTALLATION_ID");
//     // const installationId = getState().installation.deviceToken;
//     if (installationId) {
//       try {
//         if(Platform.OS === 'ios'){
//           Notifications.ios.setBadgeCount(0);
//         }
//         const response = await clearBadge(installationId);
//         if (!response?.ok) {
//           throw new Error('request failure');
//         }  
//         return dispatch({ type: 'NOTIFICATION_CLEAR_BADGE' });
//       } catch (error) {
//         return dispatch({ type: 'NOTIFICATION_CLEAR_BADGE' });
//       }
//     }
//   };
// }