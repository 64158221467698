import { useState } from 'react';
import PropTypes from 'prop-types';
import { verifyCodeOTP } from "../../../utils/OneTimePassword";

export const ResetOtpBox = ({ goNext, setOtp }) => {
    const [ resetData, setOtpCode ] = useState({otp: null});
    const [ errorCode, setErrorCode ] = useState();
    const [ errorText, setErrorText ] = useState('To verify, please put an OTP we send to your email, in below form.');
    const verifyOtp = async () => {
        if (await verifyCodeOTP(resetData, 'resetPass')) {
            setOtp(resetData.otp);
            goNext('resetPass');
        } else {
            setErrorText('OTP is INVALID');
            setErrorCode({color: 'red'});
            return false;
        }
    }

    const handleOtpField = (event) => {
        const payload = resetData;
        payload.otp = event.target.value;
        setOtpCode(payload);
    }

  return (
    <>
        <text-title>Verify</text-title>
        <text-subtitle style={errorCode}>{errorText}</text-subtitle>
        <box-input>
            <input
                type="number"
                placeholder="otp"
                onChange={handleOtpField}
            />
        </box-input>
        <button-block>
            <circle-button onClick={verifyOtp}>Next</circle-button>
        </button-block>
    </>
  );
};
  
ResetOtpBox.propTypes = {
    setOtp: PropTypes.func,
    goNext: PropTypes.func
};