import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../../../hooks/useAuth';

export const LoginBox = ({ username, otpEnable }) => {
    const [ loginErrorText, setLoginErrorText ] = useState(null);
    const [ loginFormData, setLoginFormData ] = useState({
        username: username,
        password: null,
        otp: null
    });
    const { login } = useAuth();
    let navigate = useNavigate();

    const handleChange = (event) => {
        let payload = loginFormData;
        payload[event.target.id] = event.target.value;
        setLoginFormData(payload);
    }
    const logIn = async () => {
        try {
          const response = await login({
            username: loginFormData.username,
            password: loginFormData.password,
            otp: loginFormData.otp
          });
          if (!response.ok) { throw response; }
          setLoginErrorText(null);
          navigate(-1);
        } catch (error) {
          setLoginErrorText(error.message);
        }
    };

    const renderOTPBox = () => {
        if(!otpEnable){
          return null;
        }
        return (
          <input
            id="otp"
            type="number"
            placeholder="2FA"
            onChange={handleChange}
          />
        )
    }

    return (
      <>
        <text-title>Login</text-title>
        <text-subtitle>Welcome to Wallet025</text-subtitle>
        <text-subtitle className="error">{loginErrorText}</text-subtitle>
        <box-input>
          <input
            id="username"
            type="text"
            placeholder="Username"
            value={loginFormData.username}
            onChange={handleChange}
          />
          <input
            id="password"
            type="password"
            placeholder="Password"
            onChange={handleChange}
          />
          {renderOTPBox()}
        </box-input>
        <a onClick={()=>{navigate('/ResetPassword')}} className="pageLink">Forgot Password? Click Here.</a>
        <button-block>
            <circle-button onClick={logIn}>Login</circle-button>
        </button-block>
      </>
    );
  
  };
  
  LoginBox.propTypes = {
    username: PropTypes.string,
    otpEnable: PropTypes.bool
  };
  