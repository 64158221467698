import React, { useRef } from "react"; 
import PropTypes from 'prop-types';

const SelectInput = ({ styles, inputHandle, inputData, label, symbol }) => {
    const inputRef = useRef(null);
    const showClass = (styles === "small" ? "select-sm-form-control" : "select-form-control");
    const showLabel = (styles === "small" ? false : true);
    
    return (
        <div className={showClass}>
            <div className="input-box">
                {showLabel ? <label>{label}</label>: <></>}
                <input
                    placeholder="0.00"
                    value={inputData}
                    ref={inputRef}
                    type="text"
                    onChange={(e) => inputHandle(e.target.value)} 
                />
            </div>
             <div className="select-box">{symbol}</div>
        </div>
    );
};

SelectInput.defaultProps = {
    symbol: "THB",
    styles: ""
}

SelectInput.propTypes = {
    label: PropTypes.string,
    inputHandle: PropTypes.func,
    symbol: PropTypes.string,
    styles: PropTypes.string
};

export default SelectInput;