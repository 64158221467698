
import PropTypes from 'prop-types';

export const EmailBox = ({ setUsername, checkUser }) => {
     
    return (
      <>
        <text-title>Reset</text-title>
        <text-subtitle>For resetting your password, put your email address below</text-subtitle>
        <box-input>
            <input
            id="username"
            type="text"
            placeholder="Email"
            onChange={text => setUsername(text.target.value)}
            />
        </box-input>
        <button-block>
            <circle-button onClick={checkUser}>Next</circle-button>
        </button-block>
      </>
    );
  
  };
  
  EmailBox.propTypes = {
    setUsername: PropTypes.func,
    checkUser: PropTypes.func,
  };
  