import { loading } from './loading';
import { apiConnect, wsApiFetch } from '../../utils/fetch';
import { SERVER_HOST, API_KEY } from "../../utils/constants";

export const fetchAPIConnect = (route, param) => {
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      const response = await apiConnect(route, JSON.stringify(param));
      if (!response?.ok) {
        throw response?.message;
      }
      dispatch(loading(false));
      return { ok: true, payload: response?.payload };
    } catch (error) {
      dispatch(loading(false));
      return { ok: false, message: error };
    }
  };
};

export const fetchWSAPIConnect = (route, type, param) => {
  return async (dispatch) => {
    // dispatch(loading(true));
    try {
      const response = await wsApiFetch(route, type, param);
      if (!response?.ok) {
        throw response?.message;
      }
      const res = await response?.payload.json();
      // dispatch(loading(false));
      return { ok: true, payload: res };
    } catch (error) {
      // dispatch(loading(false));
      return { ok: false, message: error };
    }
  };
};

export const fileUpload = (payload) => {
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      const sessiontoken = window.localStorage.getItem('sessiontoken');
      const url = `${SERVER_HOST}/app/upload`;
      const formData = new FormData()
      formData.append('image', payload);

      const options = {
        method: 'POST',
        headers: {
          "x-api-key": API_KEY,
          "authorization": sessiontoken
        },
        body: formData,
      };
      const response = await fetch(url, options);

      if (!response?.ok) {
        throw response?.message;
      }
      const res = await response.json();

      dispatch(loading(false));
      return { ok: true, payload: res?.payload };
    } catch (error) {
      dispatch(loading(false));
      return { ok: false, message: error };
    }
  };
};

export function changeExchangeConfig(target, value){
  return async (dispatch) => {
    try {
      const payload = {
        target: target,
        value: value
      };
      if(target == "theme"){window.localStorage.setItem('exchange-theme', value)};
      dispatch({ type: "SET_EXCHANGE_CONFIG", json: payload });
    } catch (error) {
      dispatch({ type: "SET_ERROR" });
    }
  };
}