import React from "react"; 
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TradingViewRenderer from "../../../components/tradingview/renderer";

const MarketPriceGraph = ({ theme, product }) => {
  return (
    <div className="trade-block-content trade-lb trade-rb no-scroll">
      <TradingViewRenderer theme={theme} productId={product.id} />
    </div>
  );
};

MarketPriceGraph.propTypes = {
  theme: PropTypes.string,
  product: PropTypes.object
};

const mapStateToProps = (state) => ({
	authentication: state.authentication
});

export default connect(
mapStateToProps,
null
)(MarketPriceGraph);