// import { Navigate, useOutlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useOutlet, useLocation } from 'react-router-dom';
// import { useAuth } from '../../hooks/useAuth';
import { mainMenu, transparentWhiteMenu, transparentBlackMenu, fillBlackMenu } from '../../components/layout/config/AppBarSetting';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AppBar } from './AppBar';

const DefaultLayout = ({ authentication }) => {
  // const { sessiontoken } = useAuth();
  let history = useLocation();
  const [ pageAlign, setPageAlign ] = useState("main");
  const [ barStyle, setBarStyle ] = useState("bgfilled");
  const outlet = useOutlet();
  // if (!sessionToken) {
  //   return <Navigate to="/" />;
  // }

  useEffect(() => {
    if(history.pathname.includes("exchange")){
      if(authentication?.exchangeSetting?.theme == "dark-exchange"){
        setBarStyle("bgfilledblack");
      } else {
        setBarStyle("bgfilled");
      }
    }
  } , [authentication])

  useEffect(() => {
    if(history.pathname.includes("exchange")){
      if(authentication?.exchangeSetting?.theme == "dark-exchange"){
        setBarStyle("bgfilledblack");
      } else {
        setBarStyle("bgfilled");
      }
    } else {
      if(transparentWhiteMenu.includes(history.pathname) || transparentBlackMenu.includes(history.pathname)){
        if(transparentWhiteMenu.includes(history.pathname)){
            setBarStyle("transparent-white");
            setPageAlign("black-main");
        } else {
            setBarStyle("transparent-black");
            setPageAlign("white-main");
        }
    } else {
      if(fillBlackMenu.includes(history.pathname)){
        setBarStyle("bgfilledblack");
        setPageAlign("main"); 
      } else {
        setBarStyle("bgfilled");
        setPageAlign("main"); 
      }
    }
    }
  } , [history])

  return (
    <div>
      <AppBar
        barStyle={barStyle}
        pages={mainMenu}
        authentication={authentication}
      />
      <div role={pageAlign}>
        {outlet}
      </div>
    </div>
  );
};

DefaultLayout.propTypes = {
  authentication: PropTypes.object
};

const mapStateToProps = (state) => ({
  authentication: state.authentication,
  exchangeSetting: state.authentication.exchangeSetting
});

const mapDispatchToProps = {
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultLayout);

// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';

// DefaultLayout.propTypes = {
//   authentication: PropTypes.object
// };

// const mapStateToProps = (state) => ({
//   authentication: state.authentication,
// });

// const mapDispatchToProps = {
// };

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(DefaultLayout);

