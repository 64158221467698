import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
// import { UI_ICON } from '../../assets/uiicon';
import avatar_placeholder from '../../assets/icon/ui/avatar.svg';
import SvgIcon from '../../components/svgicon';

const MobileUserProfileMenu = ({ authentication }) => {
    // const { logout } = useAuth();
    const [ avatar, setAvatar ] = useState(avatar_placeholder);
    const [ showName, setName ] = useState(authentication.objectID);
    const navigate = useNavigate();

    const handleNavigate = (path) => {
        if (!path) {
        return null;
        }
        navigate(path?.path);
    };

    useEffect(() => {
      if(!authentication.nameObject?.firstname){
        setName(authentication.objectID);
      } else {
        setName(authentication.nameObject?.firstname+" "+authentication.nameObject?.lastname);
      }
      setAvatar(authentication?.avatar);
    } , [authentication])

    return (
        <>
        {authentication?.objectID ? (
        <mobile-user-profile>
            <avatar-block>
                <img className="mobile-avatar" src={avatar} alt="" />
            </avatar-block>
            <content-block>
                <div className="title">{showName}</div>
                <div className={"rank-title rank-color"+authentication?.role?.badge?.color}>
                    {authentication?.role?.badge?.name}
                </div>
            </content-block>
        </mobile-user-profile>
        ) : (
            <div className="login-block">
                <div 
                    className="round-button more-round-button pink"
                    onClick={() => handleNavigate({path: '/login'})}>
                    Login
                </div>
            </div>
        )}
        </>
    );
};

const mapStateToProps = (state) => ({
    authentication: state?.authentication
});

export default connect(mapStateToProps)(MobileUserProfileMenu);