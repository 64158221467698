import React, { useRef, useState } from "react"; 
import PropTypes from 'prop-types';
import SvgIcon from './svgicon';
import _ from 'lodash';

const TagInput = ({ tagDataHandler, label, tagData, addEnable, className }) => {
    const labelInputRef = useRef(null);
    const valueInputRef = useRef(null);
    const [labelInput, setLabelInput] = useState();
    const [valueInput, setValueInput] = useState();
    const [dataShow, setDataShow] = useState();

    let classShow = "tag-form-control";
    if(className) {
      classShow = "show-tag";
    }

    const saveData = () => {
        let temp = tagData;
        temp[labelInput] = valueInput;
        setDataShow(Math.random());
        tagDataHandler(temp);
    }

    const deleteData = (target) => {
        if(!addEnable){
          return false;
        }
        let temp = tagData; 
        delete temp[target];
        setDataShow(Math.random());
        tagDataHandler(temp);
    }

    const addModal = () => {
      return (
        <div className="modal-font modal fade" id="tagInputBox" data-backdrop="static" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title font-b">Add New Tag</h5>
                <div className="close" data-dismiss="modal">
                  <SvgIcon name="icon-xmark-solid" className="table-icon"/>
                </div>
              </div>
              <div className="modal-body">
                <label>Key</label>
                <input
                  ref={labelInputRef}
                  type="text"
                  className="data-form-control"
                  onChange={(e) => setLabelInput(e.target.value)} />
                <label className="mt-15">Value</label>
                <input
                  ref={valueInputRef}
                  type="text"
                  className="data-form-control"
                  onChange={(e) => setValueInput(e.target.value)} />
              </div>
              <div className="modal-footer">
                <div className="round-button pink" data-dismiss="modal" onClick={saveData}>Save</div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
        <>
        {addEnable && addModal()}
        <div className={classShow}>
          <div className="label">{label}</div>
            {_.isEmpty(tagData) ? (
                null
            ) : (
                Object.keys(tagData).map((key) =>  {
                    return (
                        <div className="chip" key={key+'846hds'} onClick={() => deleteData(key)}>
                            <span className="font-b">{key} </span>
                            {tagData[key]}
                            {addEnable && (<SvgIcon name="icon-xmark-solid" className="icon"/>)}
                        </div>
                    )
                })
            )}
            {addEnable && (
              <div className="chip" data-toggle="modal" data-target="#tagInputBox">Add New Tag</div>
            )}
        </div>
        </>
    );
};

TagInput.propTypes = {
    label: PropTypes.string,
    tagDataHandler: PropTypes.func,
    tagData: PropTypes.object,
    addEnable: PropTypes.bool,
    className: PropTypes.string
};

export default TagInput;