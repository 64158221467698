// import { useNavigate } from 'react-router-dom';
// import SvgIcon from '../../components/svgicon';
// import AssetGraph from './components/AssetGraph';
import { CRYPTO } from '../../utils/crypto'
import { FILE_HOST } from '../../utils/constants'
import './styles/styles.css';

const WalletOverview = () => {
  // const navigate = useNavigate();
  // src={"../../assets/coin/"+item.typeid+".png"}

  const renderData = (item) => {
    return (
      <tr key={item.typeid+"coin9372b"}>
        <td style={{width: "35%"}}>
          <div className="table-currency">
            <img className="currency-icon" src={`/icon/coin/${item.typeid}.png`} alt="" />
            <div className="ml-10">
              <div className="currency-name">{item.symbol}</div>
              <div className="currency-full">{item.fullName}</div>
            </div>
          </div>
        </td>
        <td style={{width: "25%"}}>
          <div className="table-common">
            <div className="render-num">0.0719</div>
            <div className="render-valuation">₮127.70</div>
          </div>
        </td>
        <td style={{width: "25%"}}>
          <div className="render-allocation">
            <div className="render-num">50%</div>
            <div className="render-loader">
              <div className="loader-line">
                <div className="loader-mark" style={{width: "50%"}}></div>
              </div>
            </div>
          </div>
        </td>
        <td style={{width: "15%"}}>...</td>
      </tr>
    );
  }

  return (
      <center-container>
        <div className="max-width-900 content-block-full">
          {/* <AssetGraph /> */}
          <div className="table-defined">
            <table className="clean-table ml-15 mr-15">
              <thead>
                <tr>
                  <th scope="col">Crypto</th>
                  <th scope="col">Equity</th>
                  <th scope="col">% of Portfolio</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
              </tbody>
            </table>
          </div>
        </div>
      </center-container>
  );
};

export default WalletOverview;