const initialState = {
    badge: 0,
    pending: true,
    items: [],
    isError: false,
    error: null,
  };
  
  export default function reducer(state = initialState, action) {
    switch (action.type) {
      case 'NOTIFICATION_FETCH_SUCCESS': {
        const newState = { ...state };
        newState.pending = false;
        newState.items = action.json;
        return newState;
      }
      case 'NOTIFICATION_FETCH_FAILURE': {
        const newState = { ...state };
        newState.pending = false;
        newState.isError = true;
        newState.error = action.error;
        return newState;
      }
      case 'NOTIFICATION_CLEAR_ALL' : {
        return { ...initialState }; 
      }
      // case 'NOTIFICATION_SET_BADGE': {
      //   const newState = { ...state };
      //   newState.badge = action.badge;
      //   return newState;
      // }
      // case 'NOTIFICATION_CLEAR_BADGE': {
      //   const newState = { ...state };
      //   newState.badge = 0;
      //   return newState;
      // }
      default: {
        return state;
      }
    }
  }
  