import { SERVER_HOST, API_KEY } from "../utils/constants";

export const getAllApiKey = async (sessiontoken) => {
  try {
    const url = `${SERVER_HOST}/dev/apikey/getall`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "authorization": sessiontoken
      },
    //   body: JSON.stringify({}),
    };
    const response = await fetch(url, options);
    if (response.ok) {
      return await response.json();
    }
    return { ok: false };
  } catch (error) {
    return { ok: false };
  }
};

export const toggleKey = async (sessiontoken, target) => {
    try {
      const url = `${SERVER_HOST}/dev/apikey/toggle`;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "authorization": sessiontoken
        },
        body: JSON.stringify({id: target}),
      };
      const response = await fetch(url, options);
      if (response.ok) {
        return await response.json();
      }
      return { ok: false };
    } catch (error) {
      return { ok: false };
    }
  };

export const deleteKey = async (sessiontoken, target) => {
    try {
      const url = `${SERVER_HOST}/dev/apikey/delete`;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "authorization": sessiontoken
        },
        body: JSON.stringify({id: target}),
      };
      const response = await fetch(url, options);
      if (response.ok) {
        return await response.json();
      }
      return { ok: false };
    } catch (error) {
      return { ok: false };
    }
};

export const newKey = async (sessiontoken, name) => {
  try {
    const url = `${SERVER_HOST}/dev/apikey/gennew`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "authorization": sessiontoken
      },
      body: JSON.stringify({keyName: name}),
    };
    const response = await fetch(url, options);
    if (response.ok) {
      return await response.json();
    }
    return { ok: false };
  } catch (error) {
    return { ok: false };
  }
};