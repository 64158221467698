const initialState = {
    crypto: [],
    fiat: [],
    exchange: []
};
  
export default function reducer(state = initialState, action) {
    switch (action.type) {
      case 'WALLET_FETCH_SUCCESS': {
        const newState = { ...state };
        newState.crypto = action?.payload?.crypto;
        newState.fiat = action?.payload?.fiat;
        return newState;
      }
      case 'EX_WALLET_FETCH_SUCCESS': {
        const newState = { ...state };
        newState.exchange = action?.payload;
        return newState;
      }
      case 'EX_WALLET_UPDATE': {
        const newState = { ...state };
        // newState.exchange = action?.payload;
        newState.exchange.map((v, i) => {
          if(action?.payload?.currencyCode === v.symbol){
            newState.exchange[i].amount = action?.payload?.available;
            newState.exchange[i].hold = action?.payload?.hold;
          }
        })
        return newState;
      }
      case 'CLEAR_WALLET': {
        return { ...initialState }; 
      }
      default: {
        return state;
      }
    }
}
  