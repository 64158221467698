import { loading } from './loading';
import {
  checkLoginMethod,
  changePasswordApi,
  getUserDetail,
  sendResetOTP,
  enable2FA,
  disable2FA,
  changeNotiSetting,
  sessionFetch,
  sessionDelete,
  setUserProfile,
  setSelfAvatar,
  wsLogin
} from "../../api/authentication";
import Cookies from "js-cookie";

export const sendResetOTPMethod = (email) => {
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      const response = await sendResetOTP(email);
      if (!response?.ok) {
        throw response?.message;
      }
      dispatch(loading(false));
      return { ok: true };
    } catch (error) {
      dispatch(loading(false));
      dispatch({ type: 'CLEAR_CURRENT_USER' });
      return { ok: false };
    }
  };
};

export function checkUserLoginMethod(username){
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      const response = await checkLoginMethod(username);
      dispatch(loading(false));
      return response;
    } catch (error) {
      dispatch(loading(false));
    }
  };
}

export const listSession = () => {
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      const sessiontoken = window.localStorage.getItem('sessiontoken');
      const response = await sessionFetch(sessiontoken);
      if (!response?.ok) {
        throw response?.message;
      }
      const json = response;
      dispatch(loading(false));
      return json;
    } catch (error) {
      dispatch(loading(false));
      const payload = {ok: false};
      return payload;
    }
  };
};

export const removeSession = (id) => {
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      const sessiontoken = window.localStorage.getItem('sessiontoken');
      const response = await sessionDelete(sessiontoken, id);
      if (!response?.ok) {
        throw response?.message;
      }
      const json = response?.payload;
      dispatch(loading(false));
      return json;
    } catch (error) {
      dispatch(loading(false));
      const payload = {ok: false};
      return payload;
    }
  };
};

export const setCurrentUser = (sessiontoken) => {
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      const response = await getUserDetail(sessiontoken);
      if (!response?.ok) {
        throw response?.message;
      }
      // console.log("console.log(response);",response);
      if(response?.payload?.exchangeUser?.exgMode == true){
        const wsRes = await wsLogin({email: response?.payload?.username, password: response?.payload?.exchangeUser?.key, otp: "123456"});
        // console.log("wsRes: ", wsRes);
        Cookies.set('accessToken', wsRes?.token, { path: '/', domain: "wallet025.com" });
        const auth = wsRes?.token.split(":");
        localStorage.setItem('jsession-id', auth[1]);
        localStorage.setItem('access-token', wsRes?.token);
        const excPay = { target: "theme", value: localStorage.getItem('exchange-theme')};
        dispatch({ type: "SET_EXCHANGE_CONFIG", json: excPay });
      }

      let json = response?.payload;
      json.sessiontoken = sessiontoken;
      dispatch(loading(false));
      return dispatch({
        type: 'SET_CURRENT_USER',
        payload: json
      });
    } catch (error) {
      dispatch(loading(false));
      window.localStorage.removeItem('sessiontoken');
      return dispatch({ type: 'CLEAR_CURRENT_USER' });
    }
  };
};

export const setUserProfileAction = (payload) => {
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      const sessiontoken = window.localStorage.getItem('sessiontoken');
      let datapayload = Object.fromEntries(Object.entries(payload).filter(([_, v]) => v != null));
      // console.log(datapayload);
      const response = await setUserProfile(sessiontoken, datapayload);
      if (!response?.ok) {
        throw response?.message;
      }
      const json = response?.payload;
      dispatch(loading(false));
      return dispatch({
        type: 'SET_CURRENT_USER',
        payload: json
      });
    } catch (error) {
      dispatch(loading(false));
      return false;
    }
  };
};

export const setUserAvatar = (payload) => {
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      const sessiontoken = window.localStorage.getItem('sessiontoken');
      // let datapayload = Object.fromEntries(Object.entries(payload).filter(([_, v]) => v != null));
      const response = await setSelfAvatar(sessiontoken, payload);
      if (!response.payload) {
        throw "File Upload Failed";
      }

      let datapayload = {avatar: response.payload};
      const avatarResponse = await setUserProfile(sessiontoken, datapayload);

      if (!avatarResponse?.ok) {
        throw "Data Update Failed";
      }
      const json = avatarResponse?.payload;
      dispatch(loading(false));
      return dispatch({
        type: 'SET_CURRENT_USER',
        payload: json
      });
    } catch (error) {
      dispatch(loading(false));
      return false;
    }
  };
};

export const clearCurrentUser = () => {
  return (dispatch) => {
    Cookies.remove('accessToken', { path: '/', domain: ".wallet025.com" }) ;
    localStorage.setItem('jsession-id', null);
    localStorage.setItem('access-token', null);
    dispatch({ type: 'CLEAR_CURRENT_USER' });
  };
};

export function changePassword(old, current){
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      const sessiontoken = window.localStorage.getItem('sessiontoken');
      const response = await changePasswordApi(old, current, sessiontoken);
      if(response.code === 200){
        dispatch(loading(false));
        window.localStorage.setItem('sessiontoken', JSON.stringify(null));
        dispatch({ type: "AUTHENTICATION_LOGUOT_SUCCESS" });
        return response;
      } else {
        dispatch(loading(false));
        return response;
      }
    } catch (error) {
      dispatch(loading(false));
      return {twofa: current, payload: error};
    }
  };
}

export function changeNotiConfig(target, value){
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      const sessionToken = window.localStorage.getItem('sessiontoken');
      const response = await changeNotiSetting(target, value, sessionToken);
      const payload = {
        target: target,
        value: value
      };
      dispatch(loading(false));
      dispatch({ type: "SET_NOTI_SETTING", json: payload });
      if(response.code === 200){
        return response;
      }
    } catch (error) {
      dispatch(loading(false));
      const payload = {
        message: "Can't change noti",
        code: 404,
      };
      dispatch({ type: "SET_ERROR", json: payload });
    }
  };
}

// SET_EXCHANGE_THEME'

export function changeTwoFAConfig(current, code){
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      // const sessiontoken = useLocalStorage('sessiontoken', null);
      const sessiontoken = window.localStorage.getItem('sessiontoken');
      // const sessiontoken = null;
      if(current === false){
        const response = await enable2FA(sessiontoken);
        dispatch(loading(false));
        if(response.code === 200){
          dispatch({type: "TWOFA_ADDED_CONFIG"});
          return {code: response.code, twofa: true, payload: response};
        } else {
          dispatch({type: "TWOFA_DOSENT_CHANGE"});
          return {code: response.code, twofa: current, payload: response};
        }
      } else {
        const response = await disable2FA(sessiontoken, code);
        dispatch(loading(false));
        if(response.code === 200){
          dispatch({type: "TWOFA_REMOVE_CONFIG"});
          return {code: response.code, twofa: false, payload: response};
        } else {
          dispatch({type: "TWOFA_DOSENT_CHANGE"});
          return {code: response.code, twofa: current, payload: response};
        }
      }
    } catch (error) {
      dispatch(loading(false));
      return {twofa: current, payload: error};
    }
  };
}
