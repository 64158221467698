import React, { useState, useEffect, useCallback } from "react"; 
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { CRYPTO, CURRENCY } from '../../../utils/crypto'
import { BANK_ICON } from '../../../assets/cryptoicon'
import '../styles/styles.css';
import { createFiatInvoice } from '../../../redux/actions/fiat';
import SelectInput from '../../../components/selectinput';
import TagInput from "../../../components/taginput";
import SvgIcon from '../../../components/svgicon';
import WalletRadioInput from "../../../components/walletRadioinput";
import Switch from "react-switch";

import { getFiatPaymentChannelApi } from "../../../api/fiat";
import { fetchUserWallet } from '../../../redux/actions/wallet';

const FiatInvoiceCreate = ({ createFiatInvoiceFn, fetchUserWalletFn, authentication, wallet }) => {

    const params = useParams();

    const [newAmount, setNewAmountInput] = useState();
    const [newAmountUnit, setNewAmountUnitInput] = useState("THB");
    const [tagData, setTagData] = useState({});
    const [accountSetting, setAccountSetting] = useState({});
    const [dataLoad, setDataLoad] = useState(true);
    const [settingLoad, setSettingLoad] = useState();
    const [settingEnable, setSettingEnable] = useState([]);

    const [walletList, setWalletList] = useState({});
    const [recieverAddress, setRecieverAddress] = useState({});

    const navigate = useNavigate();

    const handleNavigate = (path) => {
        navigate(path);
    };

    const tagDataHandler = (data) => {
        setTagData(data);
    };

    const recieverHandle = (data) => {
        setRecieverAddress(data);
    };

    const createNewInvoice = async () => {
        if(newAmount=="" || newAmountUnit==""){
            return null;
        }
        const payload = {
            amount: newAmount,
            unit: newAmountUnit,
            walletObj: recieverAddress.id
        };
        const responsePayload = {
            acceptCallBack: '',
            rejectCallBack: '',
            acceptRedirect: 'internal',
            rejectRedirect: 'internal'
        }
        const optionPayload = tagData;
        let channelPayload = [];
        for (var key in accountSetting) {
            if(accountSetting[key]){
                channelPayload.push(key);
            }
        }
        const res = await createFiatInvoiceFn(payload, optionPayload, channelPayload, responsePayload);
        if(res.ok){
            handleNavigate('/wallet/fiat/invoice/list');
        }
    }

    const toggleSwitch = useCallback(async (type) => {
        const payload = accountSetting;
        payload[type] = payload[type] == true ? false : true;
        setAccountSetting(payload);
        setSettingLoad(Math.random());
        return false;
	}, [ accountSetting ])

    useEffect(() => {
        async function fetchData() {
            let channel = await getFiatPaymentChannelApi();
            if(channel.ok){
                setSettingEnable(channel.payload);
                let temp = {};
                channel.payload.map(v => { temp[v.id] = true});
                setAccountSetting(temp);
                setDataLoad(false);
            }
        }
        if(dataLoad){
            fetchData();
        }
      }, [dataLoad]);

    useEffect(() => {
        // console.log(wallet);
        let walletTemp = {fiat: [], wallet: []};
        wallet?.fiat?.map((item) => {
          let key = CURRENCY[item?.typeid];
        //   let usdValue = item?.amount * key?.usdRatio;
          walletTemp.fiat.push({
            // value: item?.amount,
            // usdValue: usdValue,
            address: item?.address,
            title: key?.name,
            symbol: key?.symbol,
            id: item?.id,
            icon: key?.icon
          });
          if(item?.id === params?.id){
            setRecieverAddress({
                address: item?.address,
                title: key?.name,
                symbol: key?.symbol,
                id: item?.id,
                icon: key?.icon
            });
          }
        })
        if(_.isEmpty(recieverAddress)){
            setRecieverAddress(walletTemp.fiat[0]);
        }
        setWalletList(walletTemp);
    } , [wallet])
    
    useEffect(() => {
        fetchUserWalletFn();
    } , [])

    const renderPaymentMethodModal = () => {
        return (
        <div className="modal-font modal fade" id="paymentModalBox" data-backdrop="static" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title font-b" id="modalBoxLabel">Payment Method</h5>
                <div className="close" data-dismiss="modal">
                  <SvgIcon name="icon-xmark-solid" className="table-icon"/>
                </div>
              </div>
              <div className="modal-body">
                <WalletRadioInput 
                    inputData={walletList} 
                    inputHandle={recieverHandle}
                    inputDefault={recieverAddress?.id}
                />
              </div>
            </div>
          </div>
        </div>
        )
    }
    
    return (
        <center-container>
            {renderPaymentMethodModal()}
            <div className="max-width-600 content-block pb">
            <screen-style>
                <SvgIcon name="icon-wallet" className="iconImg red-filter"/>
                <div className="titleHeader">Create New Invoice</div>
                <div className="titleSubHeader">You can create a new invoice for recieved fiat assets below, you can add some option tag for ease of access in table below</div>
            </screen-style>
            <div className="pt-20 pb-10">
                <SelectInput 
                    inputHandle={setNewAmountInput} 
                    label={"Total"} 
                    inputData={newAmount} 
                />
            </div>
            <div className="pt-10 pb-10">
                <TagInput
                    label={"Invoice Tag"}
                    tagDataHandler={tagDataHandler}
                    tagData={tagData}
                    addEnable={true}
                />
            </div>
            
            <div className="mt-20">Reciever Address</div>
            <div className="list-style-container">
            {recieverAddress ? (
            <div className="item" key={recieverAddress?.id+"-Bgvf12s"}>
                <div className="iconContainer">
                    <img src={recieverAddress?.icon} className="iconBox"/>
                </div>
                <div className="leftContainer">
                    <div className="titleText">{recieverAddress?.title}</div>
                    <div className="subTitleText">{recieverAddress?.address}</div>
                </div>
                <div className="rightContainer">
                    <div className="round-button white" data-toggle="modal" data-target="#paymentModalBox">Change</div>  
                </div>
            </div>) : (<div className="font-b p-15" data-toggle="modal" data-target="#paymentModalBox">Please Select Reciever Address</div>)}
            </div>

            <div className="mt-20">Payment Channel</div>
            <div className="list-style-container">
                {settingEnable.map(v => (
                <div key={v.id+'847de'} className="item">
                    <div className="iconContainer">
                        <img src={BANK_ICON[v.titleObject.code]} className="iconBox"/>
                    </div>
                    <div className="leftContainer">
                        <div className="titleText">
                            {v.titleObject[authentication.settingObject.language.system]}
                        </div>
                        <div className="subTitleText">
                            {v.titleObject.detail && v.titleObject.detail[authentication.settingObject.language.system]}
                        </div>
                    </div>
                    <div className="rightContainer">
                        <Switch 
                            onChange={() => toggleSwitch(v.id)}
                            checked={accountSetting[v.id]} 
                            offColor={"#FF5959"} 
                            onColor={"#8BCA65"}
                            uncheckedIcon={false}
                            checkedIcon={false}
                        />
                    </div>
                </div>
                ))}
            </div>
            <button-block>
                <circle-button onClick={createNewInvoice}>Add</circle-button>
            </button-block>
            </div>
        </center-container>
    );

};

const mapStateToProps = (state) => ({
	authentication: state.authentication,
    wallet: state.wallet
});

const mapDispatchToProps = {
    createFiatInvoiceFn: createFiatInvoice,
    fetchUserWalletFn: fetchUserWallet
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(FiatInvoiceCreate);