import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { hiddenSomeText, formatNumber } from '../../../utils/function';
import _ from 'lodash';

const AssetList = ({ authentication, dataList, header, barButton }) => {
  const navigate = useNavigate();
  const handleNavigate = (mode, data) => {
    if(data?.type == "crypto"){
      if(mode == "deposit"){
        navigate('/wallet/crypto/deposit/'+data.id+'/create');
      } else if (mode == "withdrawn") {
        navigate('/wallet/crypto/withdrawn/'+data.id+'/create');
      } else if (mode == "newwallet") {
        navigate('/wallet/create');
      }
    } else {
      if(mode == "deposit"){
        navigate('/wallet/fiat/deposit/'+data.id+'/create');
      } else if (mode == "withdrawn") {
        navigate('/wallet/fiat/withdrawn/'+data.id+'/create');
      } else if (mode == "newwallet") {
        navigate('/wallet/create');
      }
    }
    // if(mode == "deposit"){
    //   navigate('/wallet/fiat/deposit/'+data.id+'/create');
    // } else if (mode == "withdrawn") {
    //   navigate('/wallet/fiat/withdrawn/'+data.id+'/create');
    // } else if (mode == "newwallet") {
    //   navigate('/wallet/create');
    // }
  };

  const renderRow = (data) => {
    const amountShow = data?.symbol + " " + (formatNumber(data?.value, {maxFrac: 2, crypMaxFrac: 8, type: data?.type}));
    const usdValueShow = "~ USD  " + (formatNumber(data?.usdValue, {maxFrac: 2}));
    let assetRatio = (data?.usdValue / dataList?.total) * 100;
    if(_.isNaN(assetRatio)) { assetRatio = 0 }
    const assetRatioShow = formatNumber(assetRatio, {maxFrac: 2});
    const uid = "id" + Math.random().toString(16).slice(2);

    return (
      <tr key={data.typeid+"coin"+uid}>
        <td style={{width: "25%"}}>
        <div className="table-currency">
          <img className="currency-icon" src={data.icon} alt="" />
          <div className="ml-10">
            <div className="currency-name">{data.title}</div>
            <div className="currency-full">{hiddenSomeText(data?.address)}</div>
          </div>
        </div>
        </td>
        <td style={{width: "25%"}}>
          <div className="table-common">
            <div className="render-num">{amountShow}</div>
            <div className="render-valuation">{usdValueShow}</div>
          </div>
        </td>
        <td style={{width: "25%"}}>
          <div className="render-allocation">
            <div className="render-num">{assetRatioShow}%</div>
            <div className="render-loader">
              <div className="loader-line">
                <div className="loader-mark" style={{width: assetRatio + "%"}}></div>
              </div>
            </div>
          </div>
        </td>
        <td style={{width: "25%"}}>
          <div className="round-button pink wrap-button"  onClick={() => handleNavigate('deposit', data)}>Deposit</div>{' '}
          <div className="round-button low-pink wrap-button"  onClick={() => handleNavigate('withdrawn', data)}>Withdraw</div>
        </td>
      </tr>
    )
  }

  return (
    <div className="max-width-900 data-block">
      {barButton ? (
        <div className="zone-header">
          <div className="left">
            <span className="left-header">{header}</span>
          </div>
          <div className="right">
              <div 
                className="round-button low-pink" 
                onClick={() => handleNavigate('newwallet')}>
                  New Wallet
              </div>
          </div>
        </div>
      ) : <div className="zone-header">{header}</div>}
      <div className="table-defined">
        <table className="clean-table">
          <thead className="line-head">
            <tr>
              <th scope="col">Fiat</th>
              <th scope="col">Equity</th>
              <th scope="col">% of Portfolio</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {dataList?.fiat ? dataList?.fiat.map((data) => {
              return renderRow(data)
            }): <></>}
            {dataList?.crypto ? dataList?.crypto.map((data) => {
              return renderRow(data)
            }): <></>}
          </tbody>
        </table>
      </div>
    </div>
  );
};

AssetList.propTypes = {
    header: PropTypes.string,
    barButton: PropTypes.bool,
    dataList: PropTypes.object
};

export default AssetList;