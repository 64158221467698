import { SERVER_HOST, API_KEY } from "../utils/constants";

export const fetchUserWalletApi = async (sessionToken) => {
  try {
    const url = `${SERVER_HOST}/wallet/fetch`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
        "authorization": sessionToken
      }
    };
    const response = await fetch(url, options);
    if (response.ok) {
        return await response.json();
    }
    return { ok: false };
  } catch (error) {
    return { ok: false };
  }
};

export const fetchSwapableApi = async (sessionToken, payload) => {
  try {
    const url = `${SERVER_HOST}/wallet/swap/fetch`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
        "authorization": sessionToken
      },
      body: JSON.stringify(payload)
    };
    const response = await fetch(url, options);
    if (response.ok) {
        return await response.json();
    }
    return { ok: false };
  } catch (error) {
    return { ok: false };
  }
};

export const fetchSwapableValueApi = async (sessionToken, payload) => {
  try {
    const url = `${SERVER_HOST}/wallet/swap/value`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
        "authorization": sessionToken
      },
      body: JSON.stringify(payload)
    };
    const response = await fetch(url, options);
    if (response.ok) {
        return await response.json();
    }
    return { ok: false };
  } catch (error) {
    return { ok: false };
  }
};

export const confirmSwapApi= async (sessionToken, payload) => {
  try {
    const url = `${SERVER_HOST}/wallet/swap/confirm`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
        "authorization": sessionToken
      },
      body: JSON.stringify(payload)
    };
    const response = await fetch(url, options);
    // const json = await response.json();
    // console.log("confirmSwapApi",json);
    if (response.ok) {
        return await response.json();
        // return json;
    }
    return { ok: false };
  } catch (error) {
    return { ok: false };
  }
};