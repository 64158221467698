import React from "react";
import Icons from "../assets/icons.svg";
import PropTypes from 'prop-types';


// const SvgIcon = ({ className, name, size }) => (
//   <svg className={className} width={size} height={size}>
//     <use xlinkHref={`${Icons}#${name}`} />
//   </svg>
// );

const SvgIcon = ({ className, name }) => (
    <svg className={className}>
        <use xlinkHref={`${Icons}#${name}`}></use>
    </svg>
);

SvgIcon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired
};

export default SvgIcon;