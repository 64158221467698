import React, { useRef, useState } from "react"; 
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { APP_HOST } from "../../../utils/constants";
import PropTypes from 'prop-types';
import SvgIcon from '../../../components/svgicon';
import _ from 'lodash';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

const TxList = ({ authentication, dataList, setSearchText, handleConfirm }) => {
  const searchTextRef = useRef(null);
  const [shareId, setShareId] = useState();
  dayjs.extend(utc)
  dayjs.extend(tz)

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: authentication.settingObject.currency,
  });

  const handleAction = (id) => {
    handleConfirm(id);
  }

  const preparedList = (data) => {
    return (
      Object.keys(data).map((item) =>  {
        return renderTransaction(data[item])
      })
    );
  }

  const copyText = (data) => {
    navigator.clipboard.writeText(data);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setSearchText(event.target.value);
    }
  }

  const renderShareModal = () => {
    return (
      <div className="modal-font modal fade" id="paymentShareModalBox" data-backdrop="static" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title font-b" id="modalBoxLabel">Share</h5>
              <div className="close" data-dismiss="modal">
                <SvgIcon name="icon-xmark-solid" className="table-icon"/>
              </div>
            </div>
            <div className="modal-body">
              <input
                type="text"
                className="data-form-control"
                value={`${APP_HOST}/external/invoice/${shareId}`} />
              <div className="round-button invert-pink mt-10" onClick={() => copyText(`${APP_HOST}/external/invoice/${shareId}`)}>Copy</div>
            </div>
            <div className="modal-footer">
              <div className="round-button pink" data-dismiss="modal">Close</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderTransaction = (item) => {
    let paytype = "";
    let paytypeclass = "currency-tranaction-deposit";
    let iconbg = "icon-40-bg-orange";
    let icons = "icon-next";
    let paystatus = "Waiting";
    let payaction = true;

    if(item?.status == "confirm-transaction"){
      paytype = "Deposit";
      iconbg = "icon-40-bg-green";
      paystatus = "Confirm";
      payaction = false;
    } else {
      paytype = "Deposit";
      iconbg = "icon-40-bg-orange";
      icons = "icon-next";
      paystatus = "Waiting";
      payaction = true;
      // if(item.status=="confirm"){
      //   iconbg = "icon-40-bg-green";
      //   icons = "icon-up";
      //   paystatus = "Pay";
      //   payaction = false;
      // } 
    }

    return (
      <tr key={item.id+"coin9372b"} className="cur-pointer">
        <td style={{width: "25%"}}>
          <div className="table-currency">
            <div className={iconbg}>
              <SvgIcon name={icons} className="svg" />
            </div>
            <div className="ml-10">
              <div className="currency-name">{paystatus}</div>
              <div className="currency-full">
                <div className={paytypeclass}>{paytype}</div>{' '}
                {dayjs(item.createdAt.date).tz(authentication?.settingObject.dateZone).local().format('HH:mm')}
              </div>
            </div>
          </div>
        </td>
        <td style={{width: "25%"}} className="d-none d-md-table-cell">
          <div className="render-allocation">
            <div className="render-invoiceid">{item.invoiceId}</div>
            <div className="currency-full">{item.total?.account?.accountNo}</div>
          </div>
        </td>
        <td style={{width: "25%"}}>
          <div className="table-common">
            <div className="render-num">{formatter.format(item.total.total)}</div>
          </div>
        </td>
        <td style={{width: "25%"}}>
          {payaction && (
            <>
              <div 
                className="round-button pink wrap-button" 
                onClick={() => handleAction(item.id)}>Confirm
              </div>
            </>
          )}
        </td>
      </tr>
    );
  }

  return (
    <div className="max-width-900 data-block">
      {renderShareModal()}
        <div className="zone-header">
          <div className="left">
            <input
              className="zone-header-input"
              placeholder="Keyword eg. invoiceId:I09839123"
              ref={searchTextRef}
              type="text"
              onKeyDown={handleKeyDown} />
          </div>
        </div>
      <div className="table-defined">
        <table className="clean-table">
          <tbody>
            {_.isEmpty(dataList) ? (
              <tr key={"nodata"} className="divide">
                <td colSpan="4">No Invoice</td>
              </tr> 
            ) : (
              Object.keys(dataList).map((key) =>  {
                  return (
                    <React.Fragment key={key+"as842nsb"}>
                    <tr key={key+"as842nb"} className="divide">
                      <td colSpan="4">{key}</td>
                    </tr>
                    {preparedList(dataList[key])}
                    </React.Fragment >
                  )
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

TxList.propTypes = {
  header: PropTypes.string,
  dataList: PropTypes.object.isRequired,
  handleConfirm: PropTypes.func
};

const mapStateToProps = (state) => ({
	authentication: state.authentication
});

export default connect(
mapStateToProps,
null
)(TxList);