import { useNavigate } from 'react-router-dom';
import SvgIcon from '../../components/svgicon';
import { FooterBar } from '../../components/layout/FooterBar';
import './styles/styles.css';

const HomePage = () => {
  const navigate = useNavigate();
  const handleNavigate = (path) => {
    navigate(path?.path);
  };
  return (
    <>
      <hero-block>
        <center-text-content>
          <h1>Gateway to the freedom.</h1>
          <h4>Create your own passport to crypto freedom</h4>
          <div className="round-button more-round-button fit-content-button pink mobile-center" onClick={() => handleNavigate({path: '/login'})}>
            <SvgIcon name="icon-lock" className="topbar-main-icon-active"/>
            Sign Up
          </div>
        </center-text-content>
      </hero-block>
      <FooterBar />
    </>
  );
};

export default HomePage;