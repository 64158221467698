import React, { useState, useEffect } from "react"; 
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SelectInput from '../../../components/selectinput';
import { makeid, filterIt } from '../../../utils/function';

const OrderControl = ({ authentication, wallet, product, side, inputHandler, preSetInput }) => {
  const [ price, setPrice ] = useState('');
  const [ amount, setAmount ] = useState('');
  let amountx = 0;
  if(side == "buy"){ amountx = filterIt(wallet, product?.quoteCurrency, 'symbol').amount; }
  else if(side == "sell"){ amountx = filterIt(wallet, product?.baseCurrency, 'symbol').amount; }

  const dataSender = () => {
    const data = { productId: product?.id, side: side, price: price, size: amount, type: "limit", funds: price*amount };
    inputHandler(data);
    setPrice('');
    setAmount('');
  }

  const calculatePrice = (ratio) => {
    setPrice(product?.ticker?.price);
    if(side == "buy"){ setAmount(parseFloat(amountx * ratio)/parseFloat(product?.ticker?.price)); } 
    else { setAmount(amountx * ratio); }
  }

  useEffect(() => {
    setPrice(preSetInput);
  } , [preSetInput])

  return (
    <div className={"market-trade-"+side}>
      <div className="input-group">
        <SelectInput styles="small" inputHandle={setPrice} inputData={price} symbol={product?.quoteCurrency} label={'Price'}/>
      </div>
      <div className="input-group">
        <SelectInput styles="small" inputHandle={setAmount} inputData={amount} symbol={product?.baseCurrency} label={'Amount'}/>
      </div>
      <ul className="market-trade-list">
        <li><a onClick={() => calculatePrice(0.25)}>25%</a></li>
        <li><a onClick={() => calculatePrice(0.5)}>50%</a></li>
        <li><a onClick={() => calculatePrice(0.75)}>75%</a></li>
        <li><a onClick={() => calculatePrice(1)}>100%</a></li>
      </ul>
      <p>Available: <span>{parseFloat(amountx).toFixed(2)} {(side =="sell" ? product?.baseCurrency : product?.quoteCurrency)}</span></p>
      {/* <p>Volume: <span>0 {product?.baseCurrency} = 0 {product?.quoteCurrency}</span></p>
      <p>Margin: <span>0 {product?.baseCurrency} = 0 {product?.quoteCurrency}</span></p> */}
      <p>Fee: <span>0 {(side =="sell" ? product?.baseCurrency : product?.quoteCurrency)} = 0 {(side =="sell" ? product?.baseCurrency : product?.quoteCurrency)}</span></p>
      <div className={"round-button "+side} onClick={dataSender}>{side.toUpperCase()}</div>
    </div>
  );
};

OrderControl.propTypes = {
  type: PropTypes.string,
  inputHandler: PropTypes.func,
  product: PropTypes.object,
  preSetInput: PropTypes.any
};

const mapStateToProps = (state) => ({
	authentication: state.authentication,
  wallet: state.wallet.exchange
});

export default connect(
mapStateToProps,
null
)(OrderControl);