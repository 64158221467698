import React, { useRef } from "react"; 
import PropTypes from 'prop-types';

const NumberInput = ({ inputHandle, inputData, symbol, label }) => {
    const inputRef = useRef(null);

    // <img src={CURRENCY_ICON['thb']} />
    // return (
    //     <div className="select-form-control">
    //         <div className="input-box">
    //             <label>{label}</label>
    //             <input
    //                 placeholder="0.00"
    //                 value={inputData}
    //                 ref={inputRef}
    //                 type="text"
    //                 onChange={(e) => inputHandle(e.target.value)} 
    //             />
    //         </div>
    //          <div className="select-box">
    //             THB
    //         </div>
    //     </div>
    // );

    return (
        <div className="select-form-control">
            <div className="input-box">
            <label>{label}</label>
                <input
                    placeholder="0.00"
                    value={inputData}
                    ref={inputRef}
                    type="text"
                    onChange={(e) => inputHandle(e.target.value)} 
                />
            </div>
            <div className="select-box">{symbol}</div>
        </div>
    )
};

NumberInput.propTypes = {
    label: PropTypes.string,
    symbol: PropTypes.string,
    inputHandle: PropTypes.func
};

export default NumberInput;