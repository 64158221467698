import { useState } from 'react';
import PropTypes from 'prop-types';
import { verifyCodeOTP } from "../../../utils/OneTimePassword";
import { useAuth } from '../../../hooks/useAuth';

export const SignUpOtpBox = ({ signUpForm, goNext }) => {
  const { signup } = useAuth();
  const [ signUpData, setSignUpData ] = useState(signUpForm);
  const [ errorCode, setErrorCode ] = useState();
	const [ errorText, setErrorText ] = useState('To verify, please put an OTP we send to your email, in below form.');
  const signUpFn = async () => {
    if(!signUpData.otp){
      setErrorText('OTP is INVALID');
      setErrorCode({color: 'red'});
      return null;
    }
    if (await verifyCodeOTP(signUpData, 'signup')) {
      await signup(signUpData);
      goNext('completeScreen');
		} else {
			setErrorText('OTP is INVALID');
			setErrorCode({color: 'red'});
			return false;
		}
  }

  const handleOtpField = (event) => {
    const payload = signUpData;
    payload.otp = event.target.value;
    setSignUpData(payload);
  }

  return (
    <>
    <text-title>Verify</text-title>
    <text-subtitle style={errorCode}>{errorText}</text-subtitle>
    <box-input>
      <input
        type="number"
        placeholder="otp"
        onChange={handleOtpField}
      />
    </box-input>
    <button-block>
      <circle-button onClick={signUpFn}>Next</circle-button>
    </button-block>
    </>
  );
};
  
  SignUpOtpBox.propTypes = {
    goNext: PropTypes.func,
    signUpForm: PropTypes.object
  };
  