export const UI_ICON = {
  logo_white: require("./icon/ui/logo-white.png"),
  logo_purple: require("./icon/ui/logo-purple.png"),

  recv: require("./icon/ui/recv-icon.png"),
  send: require("./icon/ui/send-icon.png"),
  setting: require("./icon/ui/setting-icon.png"),
  swap: require("./icon/ui/swap-icon.png"),
  wallet: require("./icon/ui/wallet-icon.png"),

  arrow_grey: require("./icon/ui/arrow-grey-icon.png"),
  home_grey: require("./icon/ui/home-grey-icon.png"),
  trans_grey: require("./icon/ui/trans-grey-icon.png"),
  swap_grey: require("./icon/ui/swap-grey-icon.png"),
  wallet_grey: require("./icon/ui/wallet-grey-icon.png"),
  setting_grey: require("./icon/ui/setting-grey-icon.png"),
  noti_grey: require("./icon/ui/noti-grey-icon.png"),
  lock_grey: require("./icon/ui/lock-grey-icon.png"),
  recv_grey: require("./icon/ui/recv-grey-icon.png"),
  send_grey: require("./icon/ui/send-grey-icon.png"),

  home_white: require("./icon/ui/home-white-icon.png"),
  trans_white: require("./icon/ui/trans-white-icon.png"),
  swap_white: require("./icon/ui/swap-white-icon.png"),
  wallet_white: require("./icon/ui/wallet-white-icon.png"),
  setting_white: require("./icon/ui/setting-white-icon.png"),
  noti_white: require("./icon/ui/noti-white-icon.png"),
  lock_white: require("./icon/ui/lock-white-icon.png"),

  home_green: require("./icon/ui/home-green-icon.png"),
  trans_green: require("./icon/ui/trans-green-icon.png"),
  swap_green: require("./icon/ui/swap-green-icon.png"),
  wallet_green: require("./icon/ui/wallet-green-icon.png"),
  setting_green: require("./icon/ui/setting-green-icon.png"),
  noti_green: require("./icon/ui/noti-green-icon.png"),
  noti_green_256: require("./icon/ui/noti-green-icon-256.png"),

  recv_color: require("./icon/ui/recv-color-icon.png"),
  send_color: require("./icon/ui/send-color-icon.png"),
  love_color: require("./icon/ui/love-color-icon.png"),
  home_color: require("./icon/ui/home-color-icon.png"),
  swap_color: require("./icon/ui/swap-color-icon.png"),
  wallet_color: require("./icon/ui/wallet-color-icon.png"),
  setting_color: require("./icon/ui/setting-color-icon.png"),
  noti_color: require("./icon/ui/noti-color-icon.png"),
  recv_color: require("./icon/ui/recv-color-icon.png"),
  guard_color: require("./icon/ui/guard-color-icon.png"),
  lock_color: require("./icon/ui/lock-color-icon.png"),
  lock_color_256: require("./icon/ui/lock-color-icon-256.png"),
  twofa_color: require("./icon/ui/twofa-color-icon.png"),
  twofa_color_256: require("./icon/ui/twofa-color-icon-256.png"),
  passcode_color: require("./icon/ui/passcode-color-icon.png"),
  passcode_color_256: require("./icon/ui/passcode-color-icon-256.png"),
  session_android_color: require("./icon/ui/session-android-color.png"),
  session_ios_color: require("./icon/ui/session-ios-color.png"),
  session_pc_color: require("./icon/ui/session-pc-color.png"),
};
