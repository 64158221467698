// import { Navigate, useOutlet } from 'react-router-dom';
import { useState } from "react";
import { useOutlet } from 'react-router-dom';
// import { useAuth } from '../../hooks/useAuth';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const MobileProtectedLayout = () => {
  const outlet = useOutlet();

  return <div role="mobile-main">{outlet}</div>;
};

MobileProtectedLayout.propTypes = {
  // authentication: PropTypes.object
};

const mapStateToProps = (state) => ({
  // authentication: state.authentication,
});

const mapDispatchToProps = {
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileProtectedLayout);

// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';

// MobileProtectedLayout.propTypes = {
//   authentication: PropTypes.object
// };

// const mapStateToProps = (state) => ({
//   authentication: state.authentication,
// });

// const mapDispatchToProps = {
// };

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(MobileProtectedLayout);

