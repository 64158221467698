import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import App from './App';
import store from './redux/store';
import { AuthProvider } from './hooks/useAuth';
import CircularFullScreen from './components/indicators/CircularIndication';
// import { initWebSocket, wsSubscribe } from './redux/actions/socket';
import { initWebSocket } from './redux/actions/socket';

store.dispatch(initWebSocket());
// store.dispatch(wsSubscribe(
//   {
//     'type': 'subscribe',
//     'currency_ids': ['BTC', 'USDT', 'ETH', 'THB'],
//     'channels': ['funds']
//   }
// ))

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ReduxProvider store={store}>
      <AuthProvider>
        <>
          <App />
          <CircularFullScreen />
          <ToastContainer />
        </>
      </AuthProvider>
    </ReduxProvider>
  </BrowserRouter>
);
