// import React, { useRef, useState, useEffect } from "react"; 
import React from "react"; 
import { connect } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { APP_HOST } from "../../../utils/constants";
// import { formatNumber } from "../../../utils/function";
import PropTypes from 'prop-types';

import { makeid } from '../../../utils/function';
// import SvgIcon from '../../../components/svgicon';
// import _ from 'lodash';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

const OrderBook = ({ authentication, ask, bid, trigger, product, setBuy, setSell }) => {
  dayjs.extend(utc)
  dayjs.extend(tz)

  // useEffect(() => {
  //   console.log("useEffect Update OrderList");
  // }, [data])

  const handleClick = (num, color) => {
    if(color === "red") { setSell(num); }
    else if(color === "green") { setBuy(num); }
  }

  const renderRow = (item, classShow) => {
    if(item[1]==0){ return false; }
    // return (
    //   <div key={makeid(5)}>{item[0]} {item[1]}</div>
    // )
    let guageWidth = parseFloat(item[1])/parseFloat(item[2]) * 100;
    guageWidth = guageWidth > 100 ? 100 : guageWidth;
    return (
      <tr key={makeid(5)} className="orderbook-tr" onClick={() => handleClick(parseFloat(item[0]).toFixed(2), classShow)}>
        <td className={classShow}>{parseFloat(item[0]).toFixed(2)}</td>
        <td>{parseFloat(item[1]).toFixed(2)}</td>
        <td className="guageRunway"><div className={"tradeGuage "+classShow} style={{width: guageWidth + "%"}}></div></td>
      </tr>
    )
  }

  return (
    <div className="full-height">
      <div className="trade-block-content half-height">
        <div className="header">Order Book</div>
        <div className="content">
        <table className="trade-table">
          <thead>
            <tr>
              <th>price ({product?.quoteCurrency})</th>
              <th>amount ({product?.baseCurrency})</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {ask.map((item) => {
              return renderRow(item, 'red')
            })}
          </tbody>
        </table>
        </div>
      </div>
      <div className="trade-block-content half-height trade-tb">
        <div className="content">
        <table className="trade-table">
          <tbody>
            {bid.map((item) => {
              return renderRow(item, 'green')
            })}
          </tbody>
        </table>
        </div>
      </div>
    </div>
  )
};

OrderBook.propTypes = {
  ask: PropTypes.array,
  bid: PropTypes.array,
  product: PropTypes.object,
  trigger: PropTypes.number, 
  setBuy: PropTypes.func, 
  setSell: PropTypes.func
};

const mapStateToProps = (state) => ({
	authentication: state.authentication
});

export default connect(
mapStateToProps,
null
)(OrderBook);