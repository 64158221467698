// import React, { useRef, useState, useEffect } from "react"; 
import React from "react"; 
import { connect } from 'react-redux';
import { fetchWSAPIConnect } from '../../../redux/actions/app';
// import { useNavigate } from 'react-router-dom';
// import { APP_HOST } from "../../../utils/constants";
import { formatNumber, makeid } from "../../../utils/function";
import PropTypes from 'prop-types';
import SvgIcon from '../../../components/svgicon';
import { isEmpty } from 'lodash';
// import _ from 'lodash';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

const OrderListLarge = ({ authentication, data, trigger, product, fetchWSAPIConnectFn }) => {
  dayjs.extend(utc)
  dayjs.extend(tz)

  const deleteOrder = (orderId) => {
    fetchWSAPIConnectFn(`/api/orders/${orderId}`, 'DELETE', null);
  }

  const renderRow = (item) => {
    // if(item.status != "open"){ return true; }
    // const dateString = dayjs(item.createdAt).tz(authentication?.settingObject.dateZone).local().format('DD/MM/YYYY HH:mm');
    const dateString = dayjs(item.createdAt).tz(authentication?.settingObject.dateZone).local().format('HH:mm');
    const sideColor = (item.side == "sell") ? "red" : "green";
    const statusColor = (item.status == "open") ? "open" : "closed";

    return (
      <tr key={makeid(5)} className={statusColor}>
        <td>{dateString}</td>
        <td className={sideColor}>{parseFloat(item.price).toFixed(2)}</td>
        <td>{parseFloat(item?.size).toFixed(2)}/{parseFloat(item?.filledSize).toFixed(2)}</td>
        <td>{parseFloat(item?.funds).toFixed(2)}/{parseFloat(item?.executedValue).toFixed(2)}</td>
        <td className="f-r">{item.status} {(item.status === "open") && <div className="cur-pointer wrap-button" onClick={() => deleteOrder(item.id)}><SvgIcon name="icon-delete" className="small-svg-icon svg-icon" /></div>}</td>
      </tr>
    )
  }

  const renderAllRow = () => {
    if(isEmpty(data)){
      return <div className="no-data-content"><span className="no-data"><i className="icon ion-md-document"></i>No data</span></div>;
    }
    return (
      <div className="content">
      <table className="trade-table">
        <thead>
          <tr>
            <th>time</th>
            <th>price ({product?.quoteCurrency})</th>
            <th>filled ({product?.baseCurrency})</th>
            <th>executed ({product?.quoteCurrency})</th>
            <th><div className="f-r">status</div></th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => {
            return renderRow(item)
          })}
        </tbody>
      </table>
      </div>
    );
  }

  return (
    <div className="trade-block-content">
      {renderAllRow()}
    </div>
  );
};

OrderListLarge.propTypes = {
  data: PropTypes.array,
  trigger: PropTypes.number,
  product: PropTypes.object
};

const mapStateToProps = (state) => ({
	authentication: state.authentication
});

const mapDispatchToProps = {
  fetchWSAPIConnectFn: fetchWSAPIConnect
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(OrderListLarge);