import React, { useState, useEffect } from "react"; 
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import Lottie from "lottie-react";

import { API_KEY, SERVER_HOST } from '../../../utils/constants';
import { CRYPTO, CURRENCY } from '../../../utils/crypto';
import completeAnimation from "../../../assets/animation/complete.json";

import './styles/create.css';

const WalletCreateType = ({authentication, wallet}) => {
  const maxCount = 10;
  const [ count, setCount ] = useState(0);
  const [ processed, setProcessed ] = useState(false);
  const [ newWallet, setNewWallet ] = useState({});
  const [ showData, setShowData] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  let walletData = {};
  if(params.maintype === "fiat"){
    walletData = CURRENCY[params.type];
  } else {
    walletData = CRYPTO[params.type];
  }

  const createApi = async (urlx) => {
    setCount(0);
    setProcessed(true);
    try {
      const url = SERVER_HOST+urlx;
      // console.log(url);
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          "authorization": authentication?.sessiontoken
        }
      };
      const response = await fetch(url, options);
      const json = await response.json();
      if (json.ok) {
        return json;
      }
      setProcessed(false);
      return {ok: false};
    } catch (error) {
      setProcessed(true);
      return {ok: false};
    }
  };
  
  const handleCountClick = async () => {
    if(processed == true){
      // alert("Create Processing!");
      return null;
    }
    if(count >= maxCount){
        alert("Exceed click count!!");
        return null;
    }
    if(count == (maxCount - 1)){
        const res = await createApi(walletData?.generateApi);
        if(res.ok == false){
            alert("Generated Error");
            return null;
        }
        // console.log(res.payload);
        setNewWallet(res.payload);
        setShowData(true);
        return null;
    }    
    setCount(count + 1);
  }

  const renderResponseData = () => {
    return (
        <>
        {Object.keys(newWallet).map((key) =>  {
            return <><span className="title">{key}: </span>{newWallet[key]}<br /></>;
        })}
        </>
    );
  }

  const renderCreated = () => {
    return (
      <div className="max-width-600 content-block">
        <screen-style>
            <Lottie animationData={completeAnimation} style={{height: 300}}/>
            <div className="centerTitleHeader mt-20">Wallet Created</div>
            <div className="centerSubTitleHeader mb-20">You have create new {walletData?.symbol} wallet, an important information list below please keep it safest you can</div>
            <div className="impotant-data-zone">{renderResponseData()}</div>
            <button-block>
                <circle-button onClick={() => navigate('/wallet/list')}>Next</circle-button>
            </button-block>
        </screen-style>
        <div style={{height: "20px"}} />
      </div>
    )
  }

  const renderPreCreated = () => {
    return (
      <>
        <div className="max-width-600 content-block">
        <screen-style>
        <img src={walletData?.icon} alt="" className="iconImg" />
        <div className="titleHeader">{walletData?.name}</div>
        <div className="titleSubHeader">You can create new wallet for {walletData?.symbol}, please activated this class of wallet by touch below box for {maxCount} times.</div>
        </screen-style>
          {!showData && <div className="mt-30" onClick={() => handleCountClick()}>
            <div className="touch-zone">Touch / Click here to activated! {count}</div>
          </div>}
          {showData && 
          <div className="mt-30">
            <div className="touch-zone">Wallet generating, please wait</div>
          </div>}
        </div>
      </>
    )
  }

  return (
      <center-container>
        {!showData && renderPreCreated()}
        {showData && renderCreated()}
      </center-container>
  );
};

const mapStateToProps = (state) => ({
	authentication: state.authentication,
    wallet: state.wallet
});

const mapDispatchToProps = {
	// fetchUserWalletFn: fetchUserWallet,
	// checkSecureFunction: checkSecure
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(WalletCreateType);