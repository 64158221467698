import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { getFiatTransactionById } from '../../../redux/actions/fiat';
import rocketAnimation from "../../../assets/animation/rocket.json";
import completeAnimation from "../../../assets/animation/complete.json";
import Lottie from "lottie-react";

import { BANK_ICON } from '../../../assets/cryptoicon';

import _ from 'lodash';
import '../styles/styles.css';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

const FiatDirectTxView = ({ authentication, getFiatTransactionByIdFn }) => {
    const params = useParams();
    // const [dataLoading, setDataLoading] = useState(true);
    const txId = params.id;

    const [dataObj, setDataObj] = useState({});
    const [status, setStatus] = useState("progress");
    const location = useLocation();

    const navigate = useNavigate();
    const handleNavigate = (mode) => {
        let act = dataObj?.response?.rejectRedirect;
        if(mode == "accept"){
            act = dataObj?.response?.acceptRedirect;
        }
        if(act == 'internal'){
            if (location.pathname.search("wallet") == 1) {
                navigate('/wallet/fiat/invoice/list');
            } else {
                alert("Transaction is complete, Please close this page.");
            }
        } else {
            window.location.href = act;
        }
    };

    const copyText = (data) => {
        navigator.clipboard.writeText(data);
    }

    dayjs.extend(utc)
    dayjs.extend(tz)

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: authentication.settingObject.currency,
    });

    useEffect(() => {
        function getData() {
            getFiatTransactionByIdFn(params.id, "status-query")
            .then(dataList => {
                if(dataList.ok){
                    setStatus(dataList?.payload?.status);
                }
             })
        }
        getData()
        const interval = setInterval(() => getData(), 5000)
        return () => {
          clearInterval(interval);
        }
    }, [])

    useEffect(() => {
        getFiatTransactionByIdFn(params.id, "full-detail")
            .then(dataList => {
                if(dataList.ok){
                    setDataObj(dataList?.payload);
                }
             })
    } , [])

    const renderProgress = () => {
        return (
            <screen-style>
                <Lottie animationData={rocketAnimation} style={{height: 300}}/>
                <div className="centerTitleHeader mt-20">Waiting for your Payment..</div>
                <div className="centerSubTitleHeader">Please pay {formatter.format(dataObj?.total)} to account below [Payment ID: {txId}]</div>
                <div className="list-style-container mt-20">
                    {dataObj?.channel ? (
                        <div className="item">
                            <div className="iconContainer">
                                <img src={BANK_ICON[dataObj?.channel?.code]} className="iconBox"/>
                            </div>
                            <div className="leftContainer">
                                <div className="titleText">{dataObj?.accountNo}</div>
                                <div className="noteSubTitleText"><span className="font-b">Bank: </span>{dataObj?.channel[authentication?.settingObject?.language?.system]}</div>
                                <div className="noteSubTitleText"><span className="font-b">Account Name: </span>{dataObj?.accountName}</div>
                            </div>
                            <div className="rightContainer">
                                <div className="round-button white" onClick={() => copyText(dataObj?.accountNo)}>Copy Acc No.</div>  
                            </div>
                        </div>
                    ): (
                        <div className="font-b">Please Select Payment Method</div>
                    )} 
                </div>
                <div className="mt-20">
                    <div className="payment-card-notop">
                        <div className="mute">Total</div>
                        <div className="title">{formatter.format(dataObj?.total)}</div>
                    </div>
                </div>
            </screen-style>
        );
    }

    const renderConfirm = () => {
        return (
            <screen-style>
                <Lottie animationData={completeAnimation} style={{height: 300}}/>
                <div className="centerTitleHeader mt-20">Payment Complete</div>
                <div className="centerSubTitleHeader mb-20">You have pay {formatter.format(dataObj?.total)} [Payment ID: {txId}] to proceed touch button below</div>
                <button-block>
                    <circle-button onClick={() => handleNavigate('accept')}>Next</circle-button>
                </button-block>
            </screen-style>
        );
    }

    return (
        <center-container>
            <div className="max-width-600 data-block">
            <div className="pt-20 pl-20 pr-20 pb-20">
                {status === 'confirm' ? renderConfirm() : renderProgress()}
            </div>
            </div>
        </center-container>
    );

};

const mapStateToProps = (state) => ({
	authentication: state.authentication
});

const mapDispatchToProps = {
    getFiatTransactionByIdFn: getFiatTransactionById
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(FiatDirectTxView);