const initialState = {
    latestMsg: {}, 
    subscribe: {
      fund: null,
      trade: null,
      ticker: null 
    },
    status: false
};
  
export default function reducer(state = initialState, action) {
    switch (action.type) {
      case 'NEW_WS_MESSAGE': {
        const newState = { ...state };
        newState.latestMsg = action?.payload;
        // newState.crypto = action?.payload?.crypto;
        // newState.fiat = action?.payload?.fiat;
        return newState;
      }
      case 'ADD_WS_SUBSCRIBE': {
        const newState = { ...state };
        newState.subscribe[action.subtype] = action?.payload;
        return newState;
      }
      case 'REMOVE_WS_SUBSCRIBE': {
        const newState = { ...state };
        newState.subscribe[action.subtype] = null;
        return newState;
      }
      case 'CHANGE_WS_STATUS': {
        const newState = { ...state };
        newState.status = action?.payload;
        return newState;
      }
      case 'RESET_SOCKET': {
        return { ...initialState }; 
      }
      default: {
        return state;
      }
    }
}
  