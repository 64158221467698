// import { useState } from 'react';
import { connect } from 'react-redux';
import SvgIcon from '../../components/svgicon';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'

const NotiMenu = ({ notification, authentication }) => {

  const renderItem = (item) => {
    // <i className="icon ion-md-alert"></i>
    // <i className="icon ion-md-lock"></i>
    // <i className="icon ion-logo-android"></i>
    // <i className="icon ion-logo-bitcoin"></i>
    // <i className="icon ion-logo-usd"></i>
    let bg = "icon-pink";
    let icons = "ion-md-lock";
    if (item.data.type === "login"){
      bg = "icon-pink";
      icons = "ion-md-lock";
    } else if (item.data.type === "setting"){
      bg = "icon-purple";
      icons = "ion-md-alert";
    }

    dayjs.extend(utc)
    dayjs.extend(tz)
    const dateString = dayjs(item.pushTime).tz(authentication?.settingObject.dateZone).local().toDate().toLocaleString();
    // dayjs(item.pushTime, "MM-DD-YYYY")
    return (
      <a key={(item.objectId)+"-9842"} href="#!" className="dropdown-item">
        <div className={bg}>
          <i className={icons}></i>
        </div>
        <div className="content">
          <p>{item.data.alert.body}</p>
          <p className="sub-text text-muted">{dateString}</p>
        </div>
      </a>
    )
  }

    return (
      <div className="nav-item dropdown header-custom-icon">
        <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <SvgIcon name="icon-noti" className="topbar-icon" />
          <span className="circle-pulse"></span>
        </a>
        <div className="dropdown-menu">
          <div className="dropdown-header d-flex align-items-center justify-content-between">
            <p className="mb-0 font-weight-medium">Notifications</p>
            {/* <a href="#!" className="text-muted">Clear all</a> */}
          </div>
          <div className="dropdown-body">
           {notification.items.map((item) => {
                return renderItem(item)
            })}
          </div>
          {/* <div className="dropdown-footer d-flex align-items-center justify-content-center">
            <a href="#!">View all</a>
          </div> */}
        </div>
      </div>
    );
};

const mapStateToProps = (state) => ({
  authentication: state?.authentication,
  notification: state?.notification
});

export default connect(mapStateToProps)(NotiMenu);
