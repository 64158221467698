import { loading } from './loading';
import { WS_HOST } from "../../utils/constants";
import { WebSocketService } from "../../utils/socket.tsx";

const websocket_1 = new WebSocketService();

export const initWebSocket = () => {
    return async (dispatch) => {
        const onOpenSocket = () => {
            dispatch({ type: 'CHANGE_WS_STATUS', payload: true });
        }
        const onMessageSocket = (msg) => {
            // console.log(msg.type);
            if(msg?.type == "funds"){
                dispatch({ type: 'EX_WALLET_UPDATE', payload: msg });
            }
            if(msg?.type !== "pong"){
                dispatch({ type: 'NEW_WS_MESSAGE', payload: msg });
            }
        }
        websocket_1.connect(WS_HOST, onOpenSocket, onMessageSocket);
    };
};

export const wsSubscribe = (mainType, subTemp) => {
    return async (dispatch) => {
        dispatch(loading(true));
        try {
            websocket_1.token = localStorage.getItem('access-token');
            // {
            //     'type': 'subscribe',
            //     'currency_ids': ['BTC', 'USDT'],
            //     'channels': ['funds']
            // }
            // {
            //     'type': 'subscribe',
            //     'product_ids': 'BTC-USDT',
            //     'channels': ['candles', 'level2', 'snapshot', 'ticker', 'order', 'match']
            //   }
            websocket_1.subscribe(subTemp);
            // console.log("ADD_WS_SUBSCRIBE", subTemp.channels);
            dispatch({ type: 'ADD_WS_SUBSCRIBE', subtype: mainType, payload: subTemp });
            dispatch(loading(false));
            return { ok: true };
        } catch (error) {
            console.log(error);
            dispatch(loading(false));
            // dispatch({ type: 'CLEAR_CURRENT_USER' });
            return { ok: false };
        }
    };
}

export const wsUnSubscribe = (mainType, subTemp) => {
    return async (dispatch) => {
        dispatch(loading(true));
        try {
            if(subTemp){
                websocket_1.subscribe(subTemp);
            }
            dispatch({ type: 'REMOVE_WS_SUBSCRIBE', subtype: mainType });
            dispatch(loading(false));
            return { ok: true };
        } catch (error) {
            dispatch(loading(false));
            // dispatch({ type: 'CLEAR_CURRENT_USER' });
            return { ok: false };
        }
    };
}