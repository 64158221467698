import React, { useState, useEffect, useCallback } from "react"; 
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { fetchAPIConnect } from '../../../redux/actions/app';
import { createFiatInvoice } from '../../../redux/actions/fiat';
import { fetchUserWallet } from '../../../redux/actions/wallet';
import { CRYPTO_ICON } from "../../../assets/cryptoicon";
import { CRYPTO } from "../../../utils/crypto";

import Lottie from "lottie-react";
import completeAnimation from "../../../assets/animation/birdwait.json";

import SvgIcon from '../../../components/svgicon';
import WalletRadioInput from "../../../components/walletRadioinput";

import '../styles/styles.css';

const CryptoDepositCreate = ({ fetchAPIConnectFn, createFiatInvoiceFn, fetchUserWalletFn, authentication, wallet }) => {
    const params = useParams();
    const navigate = useNavigate();

    const [showData, setShowData] = useState(false);
    const [completeData, setCompleteData] = useState({});
    const [walletList, setWalletList] = useState({});
    const [recieverAddress, setRecieverAddress] = useState({});
    const [iconx, setIconx] = useState(null);

    const createNewInvoice = async () => {
      const res = await fetchAPIConnectFn(
        '/crypto/'+recieverAddress.typeid+'/deposit/create', 
        {payeeId: authentication.id, walletObj: recieverAddress.id});

      if(res.ok){
        setCompleteData(res.payload);
        setShowData(true);
      }
    }

    useEffect(() => {
        fetchUserWalletFn();
    } , [])

    useEffect(() => {
        let walletTemp = {fiat: [], crypto: []};
        wallet?.crypto?.map((item) => {
          let key = CRYPTO[item?.typeid];
          walletTemp.crypto.push({
            address: item?.address,
            title: key?.name,
            symbol: key?.symbol,
            typeid: key?.typeid,
            id: item?.id,
            icon: key?.icon
          });
          if(item?.id === params?.id){
            setIconx(CRYPTO_ICON[key?.typeid]);
            setRecieverAddress({
                address: item?.address,
                title: key?.name,
                symbol: key?.symbol,
                typeid: key?.typeid,
                id: item?.id,
                icon: key?.icon
            });
          }
        })
        // if(_.isEmpty(recieverAddress)){
        //     setRecieverAddress(walletTemp.fiat[0]);
        // }
        setWalletList(walletTemp);
    } , [wallet])

    const recieverHandle = (data) => {
      setIconx(CRYPTO_ICON[data?.typeid]);
      setRecieverAddress(data);
    };

    const renderPaymentMethodModal = () => {
        return (
        <div className="modal-font modal fade" id="paymentModalBox" data-backdrop="static" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title font-b" id="modalBoxLabel">Payment Method</h5>
                <div className="close" data-dismiss="modal">
                  <SvgIcon name="icon-xmark-solid" className="table-icon"/>
                </div>
              </div>
              <div className="modal-body">
                <WalletRadioInput 
                    inputData={walletList} 
                    inputHandle={recieverHandle}
                    inputDefault={recieverAddress?.id}
                />
              </div>
            </div>
          </div>
        </div>
        )
    }

    const renderResponseData = () => {
      return (
          <>
          {Object.keys(completeData).map((key) =>  {
              return <div key={key}><span className="title">{key}: </span>{completeData[key]}</div>;
          })}
          </>
      );
    }

    const copyText = (data) => {
      // console.log(data);  
      navigator.clipboard.writeText(data);
  }

    const renderCreated = () => {
      return (
        <div className="max-width-600 content-block">
          <screen-style>
              <Lottie animationData={completeAnimation} style={{height: 300}}/>
              <div className="centerTitleHeader mt-20">Deposit Process</div>
              <div className="centerSubTitleHeader mb-20">Please send your {recieverAddress?.typeid} to below address</div>
              {/* <div className="impotant-data-zone">{renderResponseData()}</div> */}
              {/* <div className="impotant-data-zone big center cur-pointer" onClick={() => copyText(completeData?.address)}>{completeData?.address}</div> */}

              <div className="impotant-data-zone big center cur-pointer" onClick={() => copyText(completeData?.address)}>
                <div className="top">{completeData?.address}</div>
              </div>
              <div className="impotant-data-zone-bottom">{completeData?.depositDetail}</div>
              <button-block>
                  <circle-button onClick={() => navigate('/wallet/fiat/invoice/list')}>Next</circle-button>
              </button-block>
          </screen-style>
          <div style={{height: "20px"}} />
        </div>
      )
    }

    const renderForm = () => {
      return (
        <>
            {renderPaymentMethodModal()}
            <div className="max-width-600 content-block pb">
            <screen-style>
                <img src={iconx} className="iconImg" />
                <div className="titleHeader">Create New Deposit</div>
                <div className="titleSubHeader">You can create a new deposit invoice for fiat assets below, you can add some option tag for ease of access in table below</div>
            </screen-style>
            <div className="mt-20">Deposit Address</div>
            <div className="list-style-container">
            {recieverAddress ? (
            <div className="item" key={recieverAddress?.id+"-Bgvf12s"}>
                <div className="iconContainer">
                    <img src={recieverAddress?.icon} className="iconBox"/>
                </div>
                <div className="leftContainer">
                    <div className="titleText">{recieverAddress?.title}</div>
                    <div className="subTitleText">{recieverAddress?.address}</div>
                </div>
                <div className="rightContainer">
                    <div className="round-button white" data-toggle="modal" data-target="#paymentModalBox">Change</div>  
                </div>
            </div>) : (<div className="font-b p-15" data-toggle="modal" data-target="#paymentModalBox">Please Select Reciever Address</div>)}
            </div>
            <button-block>
                <circle-button onClick={createNewInvoice}>Add</circle-button>
            </button-block>
            </div>
        </>
    );
  }

  return (
    <center-container>
      {!showData && renderForm()}
      {showData && renderCreated()}
    </center-container>
  )

};

const mapStateToProps = (state) => ({
	authentication: state.authentication,
  wallet: state.wallet
});

const mapDispatchToProps = {
  fetchAPIConnectFn: fetchAPIConnect,
  createFiatInvoiceFn: createFiatInvoice,
  fetchUserWalletFn: fetchUserWallet
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(CryptoDepositCreate);