import React, { useState, useCallback } from "react"; 
import { connect } from 'react-redux';
import { changePassword } from '../../redux/actions/authentication';
import SvgIcon from '../../components/svgicon';
// import { checkSecure } from '../../redux/actions/secure';

const ChangePassword = ({ changePasswordFunction }) => {
	const [oldPassword, setOldPassword] = useState();
	const [password, setPassword] = useState();
    const [rePassword, setRePassword] = useState();
	const [errorText, setErrorText] = useState();
	// const [baseBtnColor, setBaseBtnColor] = useState("disable");

	const changePassword = useCallback(async (oldPassword, password, rePassword) => {
		if (password === rePassword) {
			if (password.length < 8) {
				setErrorText('Password need more than 8 characters');
				return null;
			}
		} else {
			setErrorText('Password doesn\'t match');
			return null;
		}
		const response = await changePasswordFunction(oldPassword, password);
        if(response.ok==true){
            window.location.href = window.location.href;
            return null;
        } else {
            return null;
        }
	}, [errorText])

	// useEffect(() => {
	// 	if((oldPassword) && (password) && (rePassword)){
	// 		setBaseBtnColor("enable");
	// 	} else {
	// 		setBaseBtnColor("disable");
	// 	}
	// })

	return (
    <center-container>
		<div className="max-width-600 content-block pb">
		<screen-style>
		<SvgIcon name="icon-lock" className="iconImg red-filter"/>
		<div className="titleHeader">Change Password</div>
		<div className="titleSubHeader">To change your password, put your new password in form below. After change you need to login again</div>
		</screen-style>
        <box-input>
            <div className="configDesc red">{errorText}</div>
            <input
                type="password"
				className="data-form-control"
                placeholder="Please Enter Current Password"
                onChange={(event) => setOldPassword(event.target.value)}
            />
            <input
                type="password"
				className="data-form-control"
                placeholder="Please Enter New Password"
                onChange={(event) => setPassword(event.target.value)}
            />
            <input
                type="password"
				className="data-form-control"
                placeholder="Please Confirm Password"
                onChange={(event) => setRePassword(event.target.value)}
            />
        </box-input>
        <button-block>
            <circle-button onClick={() => changePassword(oldPassword, password, rePassword)}>Next</circle-button>
        </button-block>
		</div>
	</center-container>
	);	
}

const mapStateToProps = (state) => ({
	authentication: state.authentication
});

const mapDispatchToProps = {
	changePasswordFunction: changePassword,
	// checkSecureFunction: checkSecure
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(ChangePassword);